
import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { logout } from '../../../actions/AppActions';
import { connect } from 'react-redux';

// users
import { URL } from '../../../variables';
import { maskNumberBr, maskPrice, moneyMask, moneyMask2 } from '../../../Auxiliar/Masks';

import { MdReply } from 'react-icons/md';
import { NumericFormat } from 'react-number-format';
import { renderToast, ToastContent } from '../../../Auxiliar/Alerts';
import FileInput from '../../../Auxiliar/FileInput';
import Label from '../../../Auxiliar/Label';
import StatusStepper from '../../../Auxiliar/StatusStepper';
import { StatusColeta } from '../../../Configs/Status';
import GerarRRModal from '../../Modais/GerarRRModal';

class ReceberColeta extends Component {

  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      path: '/kanban',
      loading_screen: false,
      ResiduosRejeitos: [],
      Estoques: [],
      EstoqueSelecionado: [],
      Solicitacoes: [],
      solicitacoes_selecionadas: [],
      MateriaisSolicitacao: [],
      Destinacoes: [],
      Tratamentos: [],
      MateriaisInseridos: [],
      Transportadoras: [],
      Veiculos: [],
      Motoristas: [],
      AllMateriais: [],
      Materiais: [],
      VeiculosMotoristas: [],
      MotoristasVeiculos: [],

      Materialselecionado: {},
      SolicitacaoSelecionada: {},
      PesoTotalVeiculo: '0',
      VolumeTotalVeiculo: '0',
      TransportadoraNome: '',
      Estoque: '',
      Motorista: '',
      Veiculo: '',
      TransportadoraId: '',
      DestinacaoId: '',
      Situacao: 'Rascunho',
      Descricao: '',
      Tratamento: '',
      ValorFinanceiro: '',
      Movimentacao: '',
      Peso: '',
      Volume: '',
      MaterialSolicitacao: '',
      Solicitacao: '',
      DataAtual: '',
      DataSolicitacao: '',
      DataAgendamento: '',
      DataInicio: '',
      DataFinalizado: '',
      DataCriacao: '',
      PesoTotal: 0,
      VolumeTotal: 0,
      ReceitaTotal: '0,00',
      DespesaTotal: '0,00',
      Saldo: '0,00',
      MotoristaId: '',
      VeiculoId: '',
      loading_save: false,
      msgErro: '',
      show_warning: false,
      veiculos_motoristas: [],
      despacho: {},
      loading_rota_despacho: false,
      materiais_coletados: [],
      pesagens: [],

      ChaveNota: '',
      NumeroNota: '',
      SerieNota: '',
      Justificativa: '',
      showGerarRRModal: false,
    };
  }

  gerar_rota() {
    this.setState({ loading_rota_despacho: true });
    fetch(`${URL}api/gerar_rota_despacho/${this.props.match.params.id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Authorization': `Bearer ${this.props.token}`,
        'Content-Type': 'application/json',
      },
    }).then(async (responseLog) => {
      try {
        let resp = await responseLog.json();
        if (resp.errors != null || resp.error != null) {
          let errors = Object.values(resp.errors);
          let erro = '';
          for (let i = 0; i < errors.length; i++) {
            if (i != errors.length - 1)
              erro += errors[i] + '\n';
            else
              erro += errors[i];
          }
          this.timeout = setTimeout(() => {
            this.gerar_rota();
          }, 5000);
        }
        else {

          this.setState({ loading_rota_despacho: false, despacho: resp.despacho });

        }

      } catch (err) {
        console.log(err);
        this.timeout = setTimeout(() => {
          this.gerar_rota();
        }, 5000);
      }

    })
      .catch((err) => {
        console.log(err);
        this.timeout = setTimeout(() => {
          this.gerar_rota();
        }, 5000);
      });

  }

  get_Materiais() {
    fetch(`${URL}api/get_dados_destinacao_interna`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${this.props.token}`,
        // 'Content-Type': 'application/json',
      },
    })
      .then(async (responseLog) => {
        try {
          let resp = await responseLog.json();
          if (resp.message == "Unauthenticated.") {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            // this.setState({redirect:true,path:'/'});
            this.props.logout();
            return;
          }
          if (resp.errors != null || resp.error != null) {
            let errors = Object.values(resp.errors);
            let erro = "";
            for (let i = 0; i < errors.length; i++) {
              if (i != errors.length - 1) erro += errors[i] + "\n";
              else erro += errors[i];
            }
            console.log(erro);
            this.setState({ redirect: true });
          } else {
            this.setState({
              Materiais: resp.materiais,
              VeiculosMotoristas: resp.veiculos,
              MotoristasVeiculos: resp.motoristas
            });
          }
        } catch (err) {
          this.setState({ redirect: true });
          console.log(err);
        }
      })
      .catch((err) => {
        this.setState({ redirect: true });

        console.log(err);
      });
  }



  get_data(id) {
    if (this.state.loading_screen == false) {
      this.setState({ loading_screen: true });
    }
    fetch(`${URL}api/get_editar_despacho/${id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Authorization': `Bearer ${this.props.token}`,
        // 'Content-Type': 'application/json',
      }
    }).then(async (responseLog) => {
      try {
        let resp = await responseLog.json();
        console.log(resp);
        if (resp.message == "Unauthenticated.") {
          localStorage.removeItem('token'); localStorage.removeItem('user');
          // this.setState({redirect:true,path:'/'});
          this.props.logout();
          return;
        }
        if (resp.errors != null || resp.error != null) {
          let errors = Object.values(resp.errors);
          let erro = '';
          for (let i = 0; i < errors.length; i++) {
            if (i != errors.length - 1)
              erro += errors[i] + '\n';
            else
              erro += errors[i];
          }
          console.log(erro);
          // this.setState({ redirect: true });
          this.props.history.goBack();

        }
        else {

          let PesoTotalVeiculo = 0;
          let VolumeTotalVeiculo = 0;

          for (let i = 0; i < resp.veiculos_despacho.length; i++) {
            let veiculo = resp.veiculos_despacho[i];
            PesoTotalVeiculo = parseFloat(PesoTotalVeiculo) + (parseFloat(veiculo.capacidade_maxima_peso) * parseFloat(veiculo.unidade_peso.equivalencia));
            VolumeTotalVeiculo = parseFloat(VolumeTotalVeiculo) + (parseFloat(veiculo.capacidade_maxima_volume) * parseFloat(veiculo.unidade_volume.equivalencia));

          }
          let pesagens = [];

          resp.despacho.materiais_coletados = resp.despacho.materiais.map((item, id) => {
            let material = resp.despacho.materiais_coletados.filter((item2, id2) => item2.residuo_rejeito_id == item.residuo_rejeito_id && item.solicitacao_id == item2.solicitacao_id);
            let obj = {}
            if (material.length > 0) {
              obj = { ...material[0] };
            }
            else {
              obj = { ...item };
            }
            obj.status = material.length == 0 ? 'Proveniente da solicitação' : (material[0].peso == item.peso ? 'Confirmado pelo motorista' : 'Alterado pelo motorista');
            let hasRegister = pesagens.filter(item2 => item2 === item.residuo_rejeito_id)[0]
            if (!hasRegister) pesagens = [...pesagens, item.residuo_rejeito_id]

            return obj;
          })
          let PesoTotal = resp.despacho.materiais_coletados.reduce((total, item2) => total + parseFloat(item2.peso_kg), 0) || 0;
          let VolumeTotal = resp.despacho.materiais_coletados.reduce((total, item2) => total + parseFloat(item2.volume_lt), 0) || 0;
          let ReceitaTotal = resp.despacho.materiais_coletados.reduce((total, item2) => {
            if (item2.movimentacao === 'Receita') {
              return total + parseFloat(item2.residuo_rejeito.valor_saldo_financeiro) * (item2.residuo_rejeito.unidade_principal.tipo == 'Peso' ? parseFloat(item2.peso) : parseFloat(item2.volume))
            } else {
              return total
            }
          }, 0)

          let DespesaTotal = resp.despacho.materiais_coletados.reduce((total, item2) => {
            if (item2.movimentacao === 'Despesa') {
              return total + parseFloat(item2.residuo_rejeito.valor_saldo_financeiro) * (item2.residuo_rejeito.unidade_principal.tipo == 'Peso' ? parseFloat(item2.peso) : parseFloat(item2.volume))
            } else {
              return total
            }
          }, 0)

          const mappedMaterials = resp.despacho.materiais_coletados.map(item => {
            const material = item.residuo_rejeito

            return {
              ...item,
              "equivalencia_peso": material.unidade_principal.tipo === 'Peso'
                ? material.equivalencia
                : material.unidade_secundaria.equivalencia,
              "equivalencia_volume": material.unidade_principal.tipo !== 'Peso'
                ? material.equivalencia
                : material.unidade_secundaria.equivalencia,
              "peso_solicitado": item.peso,
              "volume_solicitado": item.volume,
              "peso_kg_solicitado": item.peso_kg,
              "volume_lt_solicitado": item.volume_lt,
            }
          })
          resp.despacho.materiais_coletados = mappedMaterials

          this.setState({
            loading_screen: false,
            Estoques: resp.estoques,
            Solicitacoes: resp.solicitacoes,
            Destinacoes: resp.destinacoes,
            ResiduosRejeitos: resp.materiais,
            Tratamentos: resp.tratamentos,
            Transportadoras: resp.transportadoras,
            Veiculos: resp.veiculos,
            Motoristas: resp.motoristas,
            PesoTotalVeiculo,
            VolumeTotalVeiculo,
            // TransportadoraId: resp.despacho.transportadora_id,
            // TransportadoraNome: resp.despacho.transportadora.nome_fantasia,
            pesagens,
            TransportadoraId: resp.despacho.transportadora == null ? '' : resp.despacho.transportadora_id,
            TransportadoraNome: resp.despacho.transportadora == null ? 'Sem transportadora' : resp.despacho.transportadora.nome_fantasia,
            Situacao: resp.despacho.situacao,
            Descricao: resp.despacho.descricao != null ? resp.despacho.descricao : '',
            Solicitacao: resp.despacho.solicitacao_id,
            Estoque: resp.despacho.estoque,
            EstoqueNome: resp.despacho.estoque.nome_fantasia,
            DataSolicitacao: resp.despacho.data_solicitacao,
            DataAgendamento: resp.despacho.data_agendamento != null ? resp.despacho.data_agendamento : '',
            DataInicio: resp.despacho.data_inicio != null ? resp.despacho.data_inicio : '',
            // PesoTotal: resp.despacho.peso_total,
            // VolumeTotal: resp.despacho.volume_total,
            // ReceitaTotal: resp.despacho.receita_total,
            // DespesaTotal: resp.despacho.despesa_total,
            // Saldo: resp.despacho.saldo,
            PesoTotal, VolumeTotal, ReceitaTotal, DespesaTotal, Saldo: ReceitaTotal - DespesaTotal,
            // MotoristaNome: resp.despacho.motorista.nome,
            // VeiculoNome: resp.despacho.veiculo.modelo.nome,
            veiculos_motoristas: resp.despacho.veiculos_motoristas,
            MateriaisInseridos: resp.despacho.materiais,
            // SolicitacaoSelecionada: [resp.despacho.solicitacao_coleta],
            solicitacoes_selecionadas: resp.solicitacoes_selecionadas,
            EstoqueSelecionado: resp.despacho.estoque,
            DataCriacao: resp.despacho.data_criacao,
            despacho: resp.despacho,
            materiais_coletados: mappedMaterials,
            mtr: resp.mtr
          });
        }
      } catch (err) {
        // this.setState({ redirect: true });
        this.props.history.goBack();

        console.log(err);
      }
    }).catch((err) => {
      // this.setState({ redirect: true });
      this.props.history.goBack();


      console.log(err);
    });
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  getUpdatedVeiculos = () => {
    return this.state.veiculos_motoristas.map(item => ({
      id: item.veiculo.id,
      nome: item.veiculo.modelo.nome
    }));
  }

  getUpdatedMotoristas = () => {
    return this.state.veiculos_motoristas.map(item => ({
      id: item.motorista.id,
      nome: item.motorista.nome
    }));
  }


  finalizar(situacao = 'Finalizada', manNumero = null) {
    const isValid = this.state.materiais_coletados.every(item => this.state.pesagens.some(residuo_rejeito_id => residuo_rejeito_id === item.residuo_rejeito_id))

    if (isValid) {
      this.setState({ loading_save: true, msgErro: '' });
      if (manNumero != null) {
        this.setState({ mtr: { codigo_manifesto_numero_nacional: manNumero } });

      }

      // Chama as funções motoristas e veículos atualizados
      const updatedMotoristas = this.getUpdatedMotoristas();
      const updatedVeiculos = this.getUpdatedVeiculos();

      fetch(`${URL}api/finalizar_despacho`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Authorization': `Bearer ${this.props.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          despacho_id: this.props.match.params.id,
          data_finalizado: this.state.DataFinalizado,
          materiais_coletados: this.state.despacho.materiais_coletados,

          peso_total: this.state.PesoTotal,
          volume_total: this.state.VolumeTotal,
          receita_total: this.state.ReceitaTotal,
          despesa_total: this.state.DespesaTotal,
          saldo: this.state.Saldo,
          situacao,
          pesagens: this.state.pesagens,
          key: this.state.ChaveNota,
          number: this.state.NumeroNota,
          serie: this.state.SerieNota,
          document: this.state.AnexoNota ?? null,
          motoristas: updatedMotoristas, //lista de motoristas 
          veiculos: updatedVeiculos, //lista de veiculos
          justificativa: this.state.despacho.justificativa,
          manNumero,
        })
      }).then(async (responseLog) => {
        try {
          let resp = await responseLog.json();
          if (resp.errors != null || resp.error != null) {
            let errors = Object.values(resp.errors);
            let erro = '';
            for (let i = 0; i < errors.length; i++) {
              if (i != errors.length - 1)
                erro += errors[i] + '\n';
              else
                erro += errors[i];
            }
            console.log(erro); 
              renderToast({ type: 'success', success: 'Manifesto recebido com sucesso!' })
              await new Promise(r => setTimeout(r, 1000));
            window.scrollTo(500, 0);
            this.setState({ loading_save: false, msgErro: erro });
          }
          else {
 
            if (manNumero != null) {

              renderToast({ type: 'success', success: 'Manifesto recebido com sucesso!' })
              await new Promise(r => setTimeout(r, 1000));
            }
            this.props.history.goBack();

            // this.setState({ loading_save: false, redirect: true });

          }

        } catch (err) {
          console.log(err);
          this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

        }

      })
        .catch((err) => {
          console.log(err);
          this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
        });
    } else {
      renderToast({ type: 'error', error: 'Confirme a pesagem dos resíduos/rejeitos!' })
    }
  }



  componentDidMount() {

    let data = new Date();
    let dia = String(data.getDate()).padStart(2, '0');
    let mes = String(data.getMonth() + 1).padStart(2, '0');
    let ano = data.getFullYear();
    this.setState({
      DataAtual: ano + '-' + mes + '-' + dia,
      DataSolicitacao: ano + '-' + mes + '-' + dia
    })
    this.get_data(this.props.match.params.id);
    this.get_Materiais();
  }


  handleVeiculoChange = (index, event) => {
    const newVeiculosMotoristas = [...this.state.veiculos_motoristas];
    newVeiculosMotoristas[index].veiculo.modelo.nome = event.target.value;
    this.setState({ veiculos_motoristas: newVeiculosMotoristas });
  };

  render() {

    return (
      <div className="row">
        {this.state.redirect == true && <Redirect to={this.state.path} />}

        <div className="col-12">
          <div className="card">
            <div className="card-body" id="card">


              <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Receber coleta</h4>
              <p style={{ fontSize: '1rem', color: 'black' }}>Dados do despacho</p>
              <br />

              {this.state.loading_screen == false && <div>
                {this.state.msgErro != '' && <div>
                  <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>
                    <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                  </div>
                  <br />

                </div>}
                <div className="row">
                  <div className="col-12">
                    <Label title='Status' text={this.state.Situacao} />
                    <div className="my-3" style={{ overflowY: 'scroll' }}>
                      <StatusStepper statusIndex={StatusColeta.getKeyByValue(this.state.Situacao)} />
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">Estoque destino: <span style={{ fontWeight: 'normal' }}>{this.state.EstoqueNome}</span></label>
                    </div>

                    <hr />
                    {<div className="form-group row">
                      <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Dados das solicitações em aberto:</label>

                      <table id="tech-companies-1" className="table table-striped table-hover" >
                        <thead>
                          <tr>
                            <th>Data da solicitação</th>
                            <th>Ponto de coleta</th>
                            <th>Responsável</th>
                            <th>Código da solicitação</th>

                          </tr>
                        </thead>
                        <tbody>
                          {this.state.solicitacoes_selecionadas.length == 0 && <tr>
                            <td colSpan="5" style={{ textAlign: 'center' }}>Nenhuma solicitação selecionada</td>
                          </tr>}
                          {this.state.solicitacoes_selecionadas.map((item, id) => (
                            <tr key={id}>
                              <td>{item.data.replace(/(\d*)-(\d*)-(\d*).*/, '$3/$2/$1')}</td>
                              <td>{item.ponto_coleta.nome_fantasia}</td>

                              <td>{item.responsavel.name}</td>
                              <td>{item.codigo_solicitacao}</td>

                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>}

                    <hr />

                    {this.state.MateriaisInseridos.length > 0 &&
                      <div className="form-group row">
                        <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Materiais selecionados:</label>

                        <table id="tech-companies-1" className="table table-striped table-hover" >
                          <thead>
                            <tr>
                              <th>Material</th>
                              <th>Peso</th>
                              <th>Volume</th>
                              <th>Ponto Coleta</th>

                            </tr>
                          </thead>
                          <tbody>
                            {this.state.MateriaisInseridos.map((item) => (
                              <tr key={item.residuo_rejeito_id + '_' + item.solicitacao_id}>
                                <td>{item.nome}</td>
                                <td>{maskNumberBr(item.peso + '')} {item.tipo_unidade_principal == undefined ?
                                  item.residuo_rejeito.unidade_principal.tipo === 'Peso' ? item.residuo_rejeito.unidade_principal.sigla : item.residuo_rejeito.unidade_secundaria.sigla :
                                  item.tipo_unidade_principal === 'Peso' ? item.unidade_principal : item.unidade_secundaria}</td>
                                <td>{maskNumberBr(item.volume + '')} {item.tipo_unidade_principal == undefined ?
                                  item.residuo_rejeito.unidade_principal.tipo !== 'Peso' ? item.residuo_rejeito.unidade_principal.sigla : item.residuo_rejeito.unidade_secundaria.sigla :
                                  item.tipo_unidade_principal !== 'Peso' ? item.unidade_principal : item.unidade_secundaria}</td>
                                <td>{item.ponto_name}</td>

                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>}

                    {this.state.despacho?.materiais_coletados?.length > 0 &&
                      <div className="form-group row">
                        <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Materiais recebidos:</label>

                        <table id="tech-companies-1" className="table table-striped table-hover" >
                          <thead>
                            <tr>
                              <th>Material</th>
                              <th>Peso</th>
                              <th>Volume</th>
                              <th>Ponto Coleta</th>
                              <th>Status</th>


                            </tr>
                          </thead>
                          <tbody>
                            {this.state.despacho.materiais_coletados.map((item) => (
                              <tr key={item.residuo_rejeito_id + '_' + item.solicitacao_id}>
                                <td style={{ verticalAlign: 'middle' }} >{item.nome}</td>
                                <td>
                                  <div style={{ display: 'flex', alignItems: 'center' }}>

                                    <NumericFormat className="form-control " id="peso"
                                      valueIsNumericString
                                      thousandsGroupStyle='thousand'
                                      thousandSeparator="."
                                      decimalScale={2}
                                      decimalSeparator=","
                                      placeholder="Informe o peso"
                                      type="text"
                                      style={{ marginRight: 10, maxWidth: 100 }}
                                      value={item.peso + ''}
                                      onValueChange={(values, e) => {
                                        //Defining variables
                                        let pesagens = [...this.state.pesagens];
                                        let despacho = { ...this.state.despacho };
                                        let peso; let volume; let peso_kg; let volume_lt

                                        //Resetting values ​​if the user deletes
                                        if (values.value == '') {
                                          despacho.materiais_coletados = despacho.materiais_coletados.map((item2, id2) => {
                                            if (item2.id == item.id) {
                                              item2.peso = '';
                                              item2.volume = '';
                                              item2.peso_kg = '';
                                              item2.volume_lt = '';
                                            }
                                            return item2;
                                          })
                                          return this.setState({ despacho, show_warning: false });
                                        }

                                        //Conversion of values ​​if the type of waste or reject is "Peso"
                                        if (item.residuo_rejeito.unidade_principal.tipo == 'Peso') {
                                          peso = values.value;
                                          volume = parseFloat(values.value) * parseFloat(item.residuo_rejeito.equivalencia)
                                          peso_kg = parseFloat(values.value) * parseFloat(item.residuo_rejeito.unidade_principal.equivalencia)
                                          volume_lt = parseFloat(values.value) * parseFloat(item.residuo_rejeito.equivalencia) * parseFloat(item.residuo_rejeito.unidade_secundaria.equivalencia)
                                          //Conversion of values ​​if the type of waste or reject is "Volume"
                                        } else {
                                          peso = values.value;
                                          volume = parseFloat(values.value) / parseFloat(item.residuo_rejeito.equivalencia)
                                          peso_kg = parseFloat(values.value) * parseFloat(item.residuo_rejeito.unidade_secundaria.equivalencia)
                                          volume_lt = (parseFloat(values.value) / parseFloat(item.residuo_rejeito.equivalencia)) * parseFloat(item.residuo_rejeito.unidade_principal.equivalencia)
                                        }

                                        //Checking if there is an object with the data of the respective residue or reject within the weighing array
                                        let hasRegister = pesagens.filter(item2 => item2 === item.residuo_rejeito_id)[0]
                                        if (!hasRegister) pesagens = [...pesagens, item.residuo_rejeito_id]

                                        despacho.materiais_coletados = despacho.materiais_coletados.map((item2, id2) => {
                                          if (item2.id == item.id) {

                                            item2.peso = peso;
                                            item2.volume = volume;
                                            item2.peso_kg = peso_kg;
                                            item2.volume_lt = volume_lt;
                                            item2.valor = peso * parseFloat(item2.residuo_rejeito.valor_saldo_financeiro);

                                            let material = this.state.materiais_coletados.filter((item3, id2) => item3.residuo_rejeito_id == item2.residuo_rejeito_id && item2.solicitacao_id == item3.solicitacao_id)[0];

                                            item2.status = parseFloat(item2.peso) != parseFloat(material.peso) ? 'Alterado pelo recebedor' : material.status;
                                          }
                                          return item2;
                                        })

                                        //Updating weight and volume states through values ​​in weighings
                                        let PesoTotal = despacho.materiais_coletados.reduce((total, item2) => total + parseFloat(item2.peso_kg), 0) || 0;
                                        let VolumeTotal = despacho.materiais_coletados.reduce((total, item2) => total + parseFloat(item2.volume_lt), 0) || 0;
                                        let ReceitaTotal = despacho.materiais_coletados.reduce((total, item2) => {
                                          if (item2.movimentacao === 'Receita') {
                                            return total + parseFloat(item2.residuo_rejeito.valor_saldo_financeiro) * (item2.residuo_rejeito.unidade_principal.tipo == 'Peso' ? parseFloat(item2.peso) : parseFloat(item2.volume))
                                          } else {
                                            return total
                                          }
                                        }, 0)

                                        let DespesaTotal = despacho.materiais_coletados.reduce((total, item2) => {
                                          if (item2.movimentacao === 'Despesa') {
                                            return total + parseFloat(item2.residuo_rejeito.valor_saldo_financeiro) * (item2.residuo_rejeito.unidade_principal.tipo == 'Peso' ? parseFloat(item2.peso) : parseFloat(item2.volume))
                                          } else {
                                            return total
                                          }
                                        }, 0)
                                        let show_warning = despacho.materiais_coletados.some(item2 =>
                                          parseFloat(item2.peso) !== parseFloat(item2.peso_solicitado)
                                        );
                                        this.setState({ pesagens, despacho, PesoTotal, VolumeTotal, ReceitaTotal, DespesaTotal, Saldo: ReceitaTotal - DespesaTotal, show_warning });
                                      }} />

                                    {item.tipo_unidade_principal == undefined ?
                                      (item.residuo_rejeito.unidade_principal.tipo === 'Peso' ? item.residuo_rejeito.unidade_principal.sigla : item.residuo_rejeito.unidade_secundaria.sigla) :
                                      (item.tipo_unidade_principal === 'Peso' ? item.unidade_principal : item.unidade_secundaria)}
                                  </div>

                                </td>
                                {/* <td>{maskNumberBr(item.peso+'')} {item.tipo_unidade_principal == undefined ?
                                                                    item.residuo_rejeito.unidade_principal.tipo == 'Peso' ? item.residuo_rejeito.unidade_principal.sigla : item.residuo_rejeito.unidade_secundaria.sigla :
                                                                    item.tipo_unidade_principal == 'Peso' ? item.unidade_principal : item.unidade_secundaria}</td> */}
                                <td style={{ verticalAlign: 'middle' }}>{maskNumberBr(item.volume + '')} {item.tipo_unidade_principal == undefined ?
                                  item.residuo_rejeito.unidade_principal.tipo !== 'Peso' ? item.residuo_rejeito.unidade_principal.sigla : item.residuo_rejeito.unidade_secundaria.sigla :
                                  item.tipo_unidade_principal !== 'Peso' ? item.unidade_principal : item.unidade_secundaria}</td>
                                <td style={{ verticalAlign: 'middle' }}>{item.ponto_name}</td>
                                <td style={{ verticalAlign: 'middle' }}>{item.status}</td>


                              </tr>
                            ))}
                          </tbody>
                        </table>
                        {this.state.show_warning && (
                          <div className="form-group row">
                            <label className="col-sm-12 col-form-label">Justificativa<b style={{ color: 'red' }}>*</b></label>
                            <div className="col-sm-12">
                              <input
                                className="form-control"
                                placeholder="Justificativa para alteração"
                                value={this.state.despacho.justificativa}
                                type="text"
                                onChange={(e) => {
                                  let despacho = { ...this.state.despacho };
                                  despacho.justificativa = e.target.value;
                                  this.setState({ despacho });
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    }
                    {console.log('materials', this.state.despacho.materiais_coletados)}
                    <div className="row">
                      <div className="col-md-6">
                        <label className="col-sm-12 col-form-label" style={{ color: 'black', fontSize: '1.5rem' }}>Dados do despacho:</label>
                        <div className="form-group row">
                          <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Situação: </label>{this.state.Situacao}</div>
                        </div>
                        <div className="form-group row">
                          <div className="col-sm-12"> <label className="col-sm-4 col-form-label">Data da criação: </label>{this.state.DataCriacao.replace(/(\d*)-(\d*)-(\d*).*/, '$3/$2/$1')} </div>
                        </div>

                        <div className="form-group row">
                          <label className="col-sm-12 col-form-label">Recebido em:</label>
                          <div className="col-sm-12">
                            <input className="form-control" value={this.state.DataFinalizado} type="date" style={{ maxWidth: 168 }} min={this.state.DataCriacao} max={this.state.DataAtual} onChange={(e) => {
                              if (e.target.value != '' && e.target.value < this.state.DataCriacao || e.target.value != '' && e.target.value > this.state.DataAtual) {
                                this.setState({ DataFinalizado: this.state.DataAtual });
                              } else {
                                this.setState({ DataFinalizado: e.target.value })
                              }
                            }} />
                          </div>
                        </div>


                        <div className="form-group row">
                          <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Peso total: </label>{maskNumberBr(this.state.PesoTotal)} Kg</div>
                        </div>
                        <div className="form-group row">
                          <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Volume total: </label>{maskNumberBr(this.state.VolumeTotal)} L</div>
                        </div>
                        <div className="form-group row">
                          <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Receita: </label>{moneyMask2(this.state.ReceitaTotal.toString())}</div>
                        </div>
                        <div className="form-group row">
                          <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Despesas: </label>{moneyMask2(this.state.DespesaTotal.toString())}</div>
                        </div>
                        <div className="form-group row">
                          <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Saldo: </label>{moneyMask2(this.state.Saldo.toString())}</div>
                        </div>
                      </div>

                      <div className="col-md-12 align-self-end justify-content-center">
                        <div className="me-4">
                          <div className="form-group row">
                            <label className="col-sm-12 col-form-label">Chave da NF-e</label>
                            <div className="col-sm-12">
                              <input className="form-control" placeholder="Chave de acesso da nota fiscal" value={this.state.ChaveNota} type="text" onChange={(e) => { this.setState({ ChaveNota: e.target.value }) }} />
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className=" col-sm-12">
                              <div className="row">
                                <div className="col-md-6 col-12">
                                  <label className="col-sm-12 col-form-label">Número da NF-e </label>
                                  <div className="col-sm-12">
                                    <input className="form-control" placeholder="Número da nota fiscal" type="text" onChange={(e) => this.setState({ NumeroNota: e.target.value })} value={this.state.NumeroNota} />
                                  </div>
                                </div>
                                <div className="col-md-6 col-12">
                                  <label className="col-sm-12 col-form-label">Série da NF-e </label>
                                  <div className="col-sm-12">
                                    <input className="form-control" placeholder="Série da nota fiscal" type="text" onChange={(e) => this.setState({ SerieNota: e.target.value })} value={this.state.SerieNota} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <FileInput
                            label='Clique aqui para adicionar um anexo fiscal'
                            fileLabel='Anexo da NF-e:'
                            acceptedFiles='application/pdf,application/vnd.ms-excel'
                            callback={(file) => this.setState({ AnexoNota: file })}
                          />
                        </div>

                      </div>
                    </div>


                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">Transportadora: <span style={{ fontWeight: 'normal' }}>{this.state.TransportadoraNome}</span></label>
                    </div>
                    {/* <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Veículo:  <span style={{ fontWeight: 'normal' }}>{this.state.VeiculoNome}</span></label>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Motorista:  <span style={{ fontWeight: 'normal' }}>{this.state.MotoristaNome}</span></label>
                                        </div> */}
                    {<div className="form-group row">
                      <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Veículos/Motoristas selecionados:</label>

                      <table id="tech-companies-1" className="table table-striped table-hover" >
                        <thead>
                          <tr>
                            <th>Veículo</th>
                            <th>Motorista</th>
                            <th>Capacidade Peso</th>
                            <th>Capacidade Volume</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.veiculos_motoristas.length === 0 && (
                            <tr>
                              <td colSpan="5" style={{ textAlign: 'center' }}>Nenhum Veículo/Motorista registrado</td>
                            </tr>
                          )}
                          {this.state.veiculos_motoristas.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <select
                                  value={item.veiculo.id}
                                  onChange={(event) => this.handleVeiculoChange(index, event)}
                                  className="form-control"
                                  style={{ width: '200px' }}
                                >
                                  <option value={item.veiculo.id}>{item.veiculo.modelo.nome}</option>
                                  {this.state.VeiculosMotoristas.length > 0 ? (
                                    this.state.VeiculosMotoristas.map((veiculo) => (
                                      <option key={veiculo.id} value={veiculo.nome} data-id={veiculo.id}>
                                        {veiculo.modelo.nome}
                                      </option>
                                    ))
                                  ) : (
                                    <option disabled>Nenhum veículo disponível</option>
                                  )}
                                </select>
                              </td>
                              <td>
                                <select
                                  value={item.motorista.id.toString()}
                                  style={{ width: '200px' }}
                                  onChange={(e) => {
                                    let veiculos_motoristas = [...this.state.veiculos_motoristas];
                                    veiculos_motoristas = veiculos_motoristas.map((motoristaItem, i) => {
                                      if (i === index) {
                                        motoristaItem.motorista.id = parseInt(e.target.value);
                                        let selectedMotorista = this.state.MotoristasVeiculos.find(motorista => motorista.id === motoristaItem.motorista.id);
                                        motoristaItem.motorista.nome = selectedMotorista ? selectedMotorista.nome : e.target.value;
                                      }
                                      return motoristaItem;
                                    });
                                    this.setState({ veiculos_motoristas });
                                  }}
                                  className="form-control"
                                >
                                  <option value={item.motorista.id.toString()}>{item.motorista.nome}</option>
                                  {this.state.MotoristasVeiculos.length > 0 ? (
                                    this.state.MotoristasVeiculos.map((motorista) => (
                                      <option key={motorista.id} value={motorista.nome} data-id={motorista.id}>
                                        {motorista.nome}
                                      </option>
                                    ))
                                  ) : (
                                    <option disabled>Nenhum motorista disponível</option>
                                  )}
                                </select>
                              </td>
                              <td>{maskNumberBr(item.veiculo.capacidade_maxima_peso)} {item.veiculo.unidade_peso.sigla}</td>
                              <td>{maskNumberBr(item.veiculo.capacidade_maxima_volume)} {item.veiculo.unidade_volume.sigla}</td>
                            </tr>
                          ))}
                        </tbody>

                      </table>
                    </div>}
                    <div className="form-group row">
                      <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Peso total Veículo(s): </label>{maskNumberBr(parseFloat(this.state.PesoTotalVeiculo).toFixed(0))} Kg</div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Volume total Veículo(s): </label>{maskNumberBr(parseFloat(this.state.VolumeTotalVeiculo).toFixed(0))} L</div>
                    </div>
                    {this.state.Descricao != '' && <div className="form-group row">
                      <label className="col-sm-12 col-form-label">Observações:  <span style={{ fontWeight: 'normal' }}>{this.state.Descricao}</span> </label>
                    </div>}


                  </div>

                </div>

                {this.state.loading_save == false && <div className="row mt-3">
                  <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start"
                    style={{ marginBottom: '0.5rem' }}>
                    <span onClick={() => {
                      this.props.history.goBack();
                    }}
                      className="btn btn-secondary btn-lg waves-effect waves-light"><MdReply /> Voltar</span>
                  </div>
                  <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end" style={{ marginBottom: '0.5rem' }}>
                    {this.state.despacho.rotas_path != null && <a target="_blank" href={URL + 'storage/' + this.state.despacho.rotas_path} className="btn btn-success btn-lg waves-effect waves-light justify-content-sm-end"
                      style={{ marginLeft: '5px' }}>Visualizar Rota </a>}
                    {this.state.loading_rota_despacho == false && this.state.despacho.rotas_path == null && <button onClick={() => this.gerar_rota()} className="btn btn-success btn-lg waves-effect waves-light justify-content-sm-end"
                      style={{ marginLeft: '5px' }}>Gerar Rota </button>}
                    {this.state.loading_rota_despacho == true && <div className="spinner-border text-primary" role="status"  >
                    </div>}
                    {this.state.despacho.situacao == 'Iniciado' && <button type="button" onClick={() => this.finalizar('Retirado')} className="btn btn-info btn-lg waves-effect waves-light justify-content-sm-end"
                      style={{ marginLeft: '5px' }}>Marcar como retirado </button>}
                    {this.state.despacho.situacao == 'Retirado' && <button type="button" onClick={() => this.finalizar('Recebido')} className="btn btn-info btn-lg waves-effect waves-light justify-content-sm-end"
                      style={{ marginLeft: '5px' }}>Marcar como recebido </button>}
                    <button type="button" onClick={() => {
                      if (this.state.mtr === null || this.state.mtr.codigo_manifesto_numero_nacional === null) {
                        this.setState({ showGerarRRModal: true });
                      } else {
                        this.finalizar('Finalizada');
                      }
                    }} className="btn btn-info btn-lg waves-effect waves-light justify-content-sm-end"
                      style={{ marginLeft: '5px' }}>Finalizar </button>
                  </div>

                </div>}
                {this.state.showGerarRRModal && (
                  <GerarRRModal
                    open={true}
                    onClose={() => this.setState({ showGerarRRModal: false })}
                    onConfirm={(manNumero) => this.finalizar('Finalizada', manNumero)}
                    token={this.props.token}
                    despachoId={this.state.despacho.id}
                  />
                )}
                {this.state.loading_save == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                  <div className="spinner-border text-primary" role="status"  >
                  </div>
                </div></div>}
              </div>}

              {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                <div className="spinner-border text-primary" role="status"  >
                </div>
              </div></div>}

            </div>

          </div>
        </div>
        <ToastContent />
      </div>
    );
  }


}
const mapsStateToProps = (state) => (
  {
    token: state.AppReducer.token,
  }
);

export default connect(mapsStateToProps, { logout })(ReceberColeta);


