import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { logout } from '../../../actions/AppActions';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';

// Destinos
import ReactPaginate from 'react-paginate';
import Tooltip from 'react-tooltip';
import { MdDelete, MdModeEdit, MdSearch, MdVisibility } from 'react-icons/md';
import { DELETE, GET } from '../../../Auxiliar/Requests';

class Destinos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      path: '/',
      loading_screen: true,
      destinos: [],
      dados_contato: [],
      page: 1,
      total_pages: 1,
      msgErro: '',
      show_warning: false,
      show_erro: false,
      destino_to_delete: '',
      loading_search: false,
      search: '',
      list_loading: [],
      dateOf: '',
      dateFor: '',
      reload: false,
    };
  }

  componentDidMount() {
    this.getData(this.state.page);
    this.props.setSearch(false)
  }

  componentDidUpdate() {
    if (this.props.search) {
      this.props.setSearch(false)
      this.getData(this.state.page);
    }
  }

  getData = async (page, search = '', busca = false) => {
    this.setState({ loading_screen: true });
    const response = await GET({ url: `get_compliances?owner=${this.props.searchFor}&page=${page}&search=${search}&dateOf=${this.props.dateOf}&dateFor=${this.props.dateFor}` })
    console.log('response', response);

    if (response.error) {
      this.setState({ redirect: true })
      return
    }

    this.setState({
      data: response.data,
      total_pages: response.pagination.last_page,
      loading_screen: false
    });
  }

  deleteCompliance = async (id) => {
    this.setState({ loadingDelete: true })
    const response = await DELETE({ url: `delete_compliance/${id}` })

    if (response.error) {
      return
    }

    this.setState({
      // data: this.state.data.filter(item => item.id !== id),
      data_to_delete: '',
      show_warning: false,
      loadingDelete: false
    })

    this.getData(1)
  }

  formatPermission = (owner) => {
    return `compliance_${owner}`;
  }

  render() {
    return (
      <div className="row">
        {!this.state.loading_screen && (
          <>
            <div className="col-12 col-md-6">
              <form
                className="app-search mt-1"
                style={{ width: '100%' }}
                onSubmit={(e) => {
                  clearTimeout(this.pesquisarTimeout);
                  this.setState({ page: 1 });
                  this.getData(1, this.state.search, true);
                  e.preventDefault();
                }}
              >
                <div className="form-group mb-0" style={{ width: '100%', position: 'relative' }}>
                  <input
                    value={this.state.search}
                    name="search"
                    placeholder="Pesquisar pelo nome ou tipo do documento..."
                    className="form-control"
                    style={{
                      width: '100%',
                      paddingRight: 40,
                      borderRadius: 100,
                    }}
                    onChange={(e) => {
                      clearTimeout(this.pesquisarTimeout);
                      let search = e.target.value;
                      this.setState({ page: 1, search: search });
                      this.pesquisarTimeout = setTimeout(() => {
                        this.getData(1, search, true);
                      }, 500);
                    }}
                  />
                  <div
                    style={{
                      width: 40,
                      position: 'absolute',
                      right: 0,
                      top: 0,
                      bottom: 0,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <MdSearch />
                  </div>
                </div>
              </form>
            </div>

            {this.props.user.tipo_usuario.permissoes[this.formatPermission(this.props.owner)].escrita && (
              <div className="col-12 col-md-6 d-flex justify-content-end">
                <Link to={`/${this.props.owner}/compliance`}>
                  <span className="btn btn-primary btn-lg waves-effect waves-light mt-1">
                    Adicionar compliance
                  </span>
                </Link>
              </div>
            )}
            <div className="table-rep-plugin">
              <div className="table-responsive mb-0" data-pattern="priority-columns">
                <table id="tech-companies-1" className="table table-striped table-hover" style={{ textAlign: 'center' }}>
                  <thead>
                    <tr>
                      <th>Tipo</th>
                      <th>Nome do documento</th>
                      <th>Responsável</th>
                      <th data-priority="3">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data.length === 0 && <tr>
                      <td colSpan="5" style={{ textAlign: 'center' }}>Nenhum documento cadastrado</td>
                    </tr>}

                    {this.state.data.map(item => {
                      return (
                        <tr key={item.id}>
                          <td>{item.type}</td>
                          <td>{item.name}</td>
                          <td>{item.responsible}</td>

                          <td style={{ whiteSpace: 'nowrap' }}>
                            {this.props.user.tipo_usuario.permissoes[this.formatPermission(this.props.owner)].leitura &&
                              <Link to={`/${this.props.owner}/compliance/ver/${item.id}`}>
                                <span className="btn btn-warning btn-outline btn-circle me-1 mb-1" data-tip="Ver">
                                  <Tooltip />
                                  <MdVisibility style={{ color: '#fff' }} />
                                </span>
                              </Link>
                            }
                            {this.props.user.tipo_usuario.permissoes[this.formatPermission(this.props.owner)].escrita && (
                              <Link to={`/${this.props.owner}/compliance/editar/${item.id}`}>
                                <span className="btn btn-info btn-outline btn-circle me-1 mb-1" data-tip="Editar">
                                  <Tooltip />
                                  <MdModeEdit />
                                </span>
                              </Link>
                            )}

                            {this.props.user.tipo_usuario.permissoes[this.formatPermission(this.props.owner)].exclusao &&
                              <button
                                onClick={() => {
                                  this.setState({
                                    show_warning: true,
                                    data_to_delete: item.id,
                                  });
                                }}
                                className="destroy_it btn btn-danger btn-outline btn-circle me-1 mb-1"
                                data-tip="Excluir"
                              >
                                <Tooltip />
                                <MdDelete />
                              </button>
                            }
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row">
              <div className="col-12 d-flex justify-content-end">
                <ReactPaginate
                  previousLabel={'Anterior'}
                  nextLabel={'Próxima'}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={this.state.total_pages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={this.handlePageClick.bind(this)}
                  containerClassName={'pagination'}
                  subContainerClassName={'pages pagination'}
                  activeClassName={'active'}
                  initialPage={this.state.page - 1}
                />
              </div>
            </div>
          </>
        )}

        {this.state.loading_screen && (
          <div className="row">
            <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
              <div className="spinner-border text-primary" role="status"></div>
            </div>
          </div>
        )}
        <SweetAlert
          warning
          title={'Atenção'}
          onConfirm={() => {
            this.deleteCompliance(this.state.data_to_delete);
          }}
          onCancel={() => {
            this.setState({ show_warning: false });
          }}
          show={this.state.show_warning}
          confirmBtnText={!this.state.loadingDelete
            ? "Sim, desejo apagar"
            :
            <div className="col-12 d-flex justify-content-center align-items-center" style={{ width: 200 }}>
              <div className="spinner-border text-white" role="status"></div>
            </div>}
          cancelBtnText="Cancelar"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          disabled={this.state.loadingDelete}
          showCancel={true}
        >
          Ao deletar este documento ele não estará mais disponível no
          sistema
        </SweetAlert>
        <SweetAlert
          danger
          title={'Erro'}
          onConfirm={() => {
            this.setState({ show_erro: false });
          }}
          show={this.state.show_erro}
          confirmBtnText="OK"
          confirmBtnBsStyle="primary"
        >
          {this.state.msgErro}
        </SweetAlert>
      </div>
    );
  }

  handlePageClick(page) {
    console.log(page.selected);
    if (page.selected + 1 != this.state.page) {
      this.setState({ page: page + 1 });
      this.getData(page.selected + 1);
    }
  }
}
const mapsStateToProps = (state) => ({
  user: state.AppReducer.user,
  token: state.AppReducer.token,
});

export default connect(mapsStateToProps, { logout })(Destinos);
