
import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { logout } from '../../../actions/AppActions';
import { connect } from 'react-redux';

// users
import moment from 'moment';
import { THIS_URL, URL } from '../../../variables';
import { maskTelefone, maskCpf } from '../../../Auxiliar/Masks';

import { MdReply } from 'react-icons/md';

class CriarPessoa extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/pessoas',
            loading_screen: true,
            roles: [],
            name: '',
            email: '',
            imagem: '',
            imagem_path: '',
            cpf: '',
            telefone: '',
            segundo_telefone: '',
            // password: '',
            // confirm_password: '',
            tipo: '',
            loading_save: false,
            msgErro: '',
            acessoSistema: false,
            permissao_notificacao: false,

            acesso: {
                'ContatoDestino': true,
                'ResponsavelPontoColeta': true,
                'PessoaAutorizadaPontoColeta': true,
                'ResponsavelEstoque': true,
                'PessoaAutorizadaEstoque': true,
                'ResponsavelTransportadora': true,
                'ResponsavelRegistroEntradaCT': true,
                'ResponsavelSolicitacaoColeta': true,
                'ResponsavelRegistroEntradaGI': true,
                'NotificacaoIndividual': false,
            },
            tipos: [],
        };
    }


    get_pessoa_tipos() {

        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/get_pessoa_tipos`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.setState({
                        loading_screen: false, tipos: resp.tipos,
                    });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }



    salvar() {
        this.setState({ loading_save: true, msgErro: '' });
        let form = new FormData();

        form.append('imagem', this.state.imagem);
        form.append('name', this.state.name);
        form.append('email', this.state.email);
        form.append('cpf', this.state.cpf);
        form.append('telefone', this.state.telefone);
        form.append('segundo_telefone', this.state.segundo_telefone);
        form.append('acesso', this.state.acessoSistema);
        form.append('acessos_modulos', JSON.stringify(this.state.acesso));

        if (this.state.acessoSistema == true) {
            //form.append('password', this.state.password);
            //form.append('password_confirmation', this.state.confirm_password);
            form.append('tipo', this.state.tipo);
            form.append('url', THIS_URL )
        }

        console.log(form);


        fetch(`${URL}api/store_pessoa`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            },
            body: form,
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    window.scrollTo(500, 0);

                    this.setState({ loading_save: false, msgErro: erro });
                }
                else {
                    resp.status ? this.setState({ loading_save: false, redirect: true }) : this.setState({ loading_save: false, msgErro: "Erro ao cadastrar usuário." });                    
                }
            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });
            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }


    componentDidMount() {
        this.get_pessoa_tipos();
    }





    change_tipo(event) {
        this.setState({ tipo: event.target.value });

        fetch(`${URL}api/get_tipo/${event.target.value}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            let resp = await responseLog.json()
            let item = resp.permissoes_notificacoes?.["destinacaointerna.ativar_notificacoes"]
            if (item?.has_access) {
                this.setState({ permissao_notificacao: item?.has_access })
            }else{
                this.setState({ permissao_notificacao: false })
            }
        }).catch((err) => console.log(err))
    }


    render() {
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">


                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Cadastrar pessoa</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Cadastre as pessoas do seu sistema</p>
                            <br />

                            {/* <hr /> */}
                            {this.state.loading_screen == false && <div>
                                {this.state.msgErro != '' && <div>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>


                                        <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                                    </div>
                                    <br />

                                </div>}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Imagem</label>
                                            <div className="col-sm-12">
                                                <input className="form-control" type="file" accept="image/*" onChange={(e) => {
                                                    let imagem = e.target.files[0];
                                                    console.log(imagem)
                                                    this.setState({ imagem });
                                                    let imagem_path = '';
                                                    let reader = new FileReader()
                                                    reader.readAsDataURL(e.target.files[0])
                                                    reader.onload = () => {
                                                        imagem_path = reader.result;
                                                        this.setState({ imagem_path });
                                                    };
                                                    reader.onerror = function (error) {
                                                        console.log('Error: ', error);
                                                    }
                                                    e.target.value = '';
                                                }} />

                                            </div>
                                        </div>
                                        <div className="row">
                                            {this.state.imagem_path != '' &&
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center" }} className="col-12 col-md-12 mt-3">
                                                    <div>
                                                        <img src={this.state.imagem_path} style={{ height: 100, width: 100, objectFit: "contain", border: '1px solid lightgrey' }} />
                                                        <p><a onClick={() => {
                                                            this.setState({ imagem: '', imagem_path: '' });
                                                        }} className="btn btn-link btn-link-dark" style={{ color: 'indianred', cursor: 'pointer' }}><span style={{ marginRight: 5 }}>Remover</span><i className="fas fa-times"></i></a></p>
                                                    </div>
                                                </div>

                                            }
                                        </div>
                                        <hr />
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Nome <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <input className="form-control" placeholder="Nome da pessoa" type="text" onChange={(e) => this.setState({ name: e.target.value })} value={this.state.name} />

                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">E-mail <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <input className="form-control" placeholder="E-mail da pessoa" type="email" onChange={(e) => this.setState({ email: e.target.value })} value={this.state.email} />

                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">CPF <b style={{ color: 'red' }}></b></label>
                                            <div className="col-sm-12">
                                                <input className="form-control" placeholder="CPF" type="text" onChange={(e) => this.setState({ cpf: maskCpf(e.target.value) })} value={this.state.cpf} />

                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Telefone <b style={{ color: 'red' }}></b></label>
                                            <div className="col-sm-12">
                                                <input className="form-control" placeholder="Telefone" type="text" onChange={(e) => this.setState({ telefone: maskTelefone(e.target.value) })} value={this.state.telefone} />

                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Segundo telefone <b style={{ color: 'red' }}></b></label>
                                            <div className="col-sm-12">
                                                <input className="form-control" placeholder="Segundo telefone" type="text" onChange={(e) => this.setState({ segundo_telefone: maskTelefone(e.target.value) })} value={this.state.segundo_telefone} />

                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-12 col-form-label" >Terá acesso ao sistema? <b style={{ color: 'red' }}></b></label>

                                            <div className="col-sm-12 d-flex align-items-center">
                                                <label className="form-check-label" htmlFor="acesso" style={{ marginRight: 10 }}>Não</label>

                                                <div className="form-check form-switch col-12 col-md-4 col-lg-4">

                                                    <input className="form-check-input" type="checkbox" id="acesso" checked={this.state.acessoSistema} onChange={(e) => {
                                                        console.log(e.target.checked);
                                                        this.setState({ acessoSistema: e.target.checked });
                                                    }} />
                                                    <label className="form-check-label" htmlFor="acesso">Sim</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div hidden={this.state.acessoSistema == false}>
                                            <hr />
                                            {/* /********************Senha removida, agora gera aleatóriamente*********************/}

                                            {/* <div className="form-group row">
                                                <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Senha <b style={{ color: 'red' }}>*</b></label>

                                                <div className="col-sm-12">
                                                    <input className="form-control" placeholder="Senha" type="password" value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} />

                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Confirmar senha <b style={{ color: 'red' }}>*</b></label>

                                                <div className="col-sm-12">
                                                    <input className="form-control" placeholder="Confirmar senha" value={this.state.confirm_password} type="password" onChange={(e) => this.setState({ confirm_password: e.target.value })} />

                                                </div>
                                            </div> */}

                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label">Tipo <b style={{ color: 'red' }}>*</b></label>
                                                <div className="col-sm-12">
                                                    {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'custom-select','id'=>'faculdade']) }} */}
                                                    <select value={this.state.tipo} className="form-control" id="cameras" onChange={this.change_tipo.bind(this)}>
                                                        <option value={''}>{'Selecione um tipo de usuário'}</option>
                                                        {this.state.tipos.map((item, id) => (
                                                            <option key={id} value={item.id}>{item.nome}</option>

                                                        ))}


                                                    </select>
                                                </div>
                                            </div>

                                            <div className="row mt-2">
                                                <div className="col-12 col-md-6 col-lg-4">

                                                    <div className="form-group row">
                                                        <label className="col-12 col-form-label" >Usuário pode ser contato de um destino? <b style={{ color: 'red' }}></b></label>

                                                        <div className="col-sm-12 d-flex align-items-center">
                                                            <label className="form-check-label" htmlFor="contatodestino" style={{ marginRight: 10 }}>Não</label>

                                                            <div className="form-check form-switch col-12 col-md-4 col-lg-4">

                                                                <input className="form-check-input" type="checkbox" id="contatodestino" checked={this.state.acesso['ContatoDestino']} onChange={(e) => {
                                                                    let acesso = { ...this.state.acesso };
                                                                    acesso['ContatoDestino'] = e.target.checked;
                                                                    this.setState({ acesso: acesso });
                                                                }} />
                                                                <label className="form-check-label" htmlFor="contatodestino">Sim</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-md-6 col-lg-4">

                                                    <div className="form-group row">
                                                        <label className="col-12 col-form-label" >Usuário pode ser responsável por um ponto de coleta? <b style={{ color: 'red' }}></b></label>

                                                        <div className="col-sm-12 d-flex align-items-center">
                                                            <label className="form-check-label" htmlFor="responsavelpontocoleta" style={{ marginRight: 10 }}>Não</label>

                                                            <div className="form-check form-switch col-12 col-md-4 col-lg-4">

                                                                <input className="form-check-input" type="checkbox" id="responsavelpontocoleta" checked={this.state.acesso['ResponsavelPontoColeta']} onChange={(e) => {
                                                                    let acesso = { ...this.state.acesso };
                                                                    if (e.target.checked == true) {
                                                                        acesso['PessoaAutorizadaPontoColeta'] = e.target.checked;
                                                                    }
                                                                    acesso['ResponsavelPontoColeta'] = e.target.checked;
                                                                    this.setState({ acesso: acesso });
                                                                }} />
                                                                <label className="form-check-label" htmlFor="responsavelpontocoleta">Sim</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-md-6 col-lg-4">

                                                    <div className="form-group row">
                                                        <label className="col-12 col-form-label" >Usuário pode ser uma pessoa autorizada em um ponto de coleta? <b style={{ color: 'red' }}></b></label>

                                                        <div className="col-sm-12 d-flex align-items-center">
                                                            <label className="form-check-label" htmlFor="autorizadopontocoleta" style={{ marginRight: 10 }}>Não</label>

                                                            <div className="form-check form-switch col-12 col-md-4 col-lg-4">

                                                                <input className="form-check-input" type="checkbox" id="autorizadopontocoleta" checked={this.state.acesso['PessoaAutorizadaPontoColeta']} onChange={(e) => {
                                                                    let acesso = { ...this.state.acesso };
                                                                    if (acesso['ResponsavelPontoColeta'] == true) {
                                                                        return false;
                                                                    } else {

                                                                        acesso['PessoaAutorizadaPontoColeta'] = e.target.checked;
                                                                        this.setState({ acesso: acesso });
                                                                    }
                                                                }} />
                                                                <label className="form-check-label" htmlFor="autorizadopontocoleta">Sim</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-md-6 col-lg-4">

                                                    <div className="form-group row">
                                                        <label className="col-12 col-form-label" >Usuário pode ser responsável por um estoque? <b style={{ color: 'red' }}></b></label>

                                                        <div className="col-sm-12 d-flex align-items-center">
                                                            <label className="form-check-label" htmlFor="responsavelestoque" style={{ marginRight: 10 }}>Não</label>

                                                            <div className="form-check form-switch col-12 col-md-4 col-lg-4">

                                                                <input className="form-check-input" type="checkbox" id="responsavelestoque" checked={this.state.acesso['ResponsavelEstoque']} onChange={(e) => {
                                                                    let acesso = { ...this.state.acesso };
                                                                    if (e.target.checked == true) {
                                                                        acesso['PessoaAutorizadaEstoque'] = e.target.checked;
                                                                    }
                                                                    acesso['ResponsavelEstoque'] = e.target.checked;
                                                                    this.setState({ acesso: acesso });
                                                                }} />
                                                                <label className="form-check-label" htmlFor="responsavelestoque">Sim</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-md-6 col-lg-4">

                                                    <div className="form-group row">
                                                        <label className="col-12 col-form-label" >Usuário pode ser uma pessoa autorizada em um estoque? <b style={{ color: 'red' }}></b></label>

                                                        <div className="col-sm-12 d-flex align-items-center">
                                                            <label className="form-check-label" htmlFor="autorizadoestoque" style={{ marginRight: 10 }}>Não</label>

                                                            <div className="form-check form-switch col-12 col-md-4 col-lg-4">

                                                                <input className="form-check-input" type="checkbox" id="autorizadoestoque" checked={this.state.acesso['PessoaAutorizadaEstoque']} onChange={(e) => {
                                                                    let acesso = { ...this.state.acesso };
                                                                    if (acesso['ResponsavelEstoque'] == true) {
                                                                        return false;
                                                                    } else {
                                                                        acesso['PessoaAutorizadaEstoque'] = e.target.checked;
                                                                        this.setState({ acesso: acesso });
                                                                    }
                                                                }} />
                                                                <label className="form-check-label" htmlFor="autorizadoestoque">Sim</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-md-6 col-lg-4">

                                                    <div className="form-group row">
                                                        <label className="col-12 col-form-label" >Usuário pode ser responsável em uma transportadora? <b style={{ color: 'red' }}></b></label>

                                                        <div className="col-sm-12 d-flex align-items-center">
                                                            <label className="form-check-label" htmlFor="responsaveltransportadora" style={{ marginRight: 10 }}>Não</label>

                                                            <div className="form-check form-switch col-12 col-md-4 col-lg-4">

                                                                <input className="form-check-input" type="checkbox" id="responsaveltransportadora" checked={this.state.acesso['ResponsavelTransportadora']} onChange={(e) => {
                                                                    let acesso = { ...this.state.acesso };
                                                                    acesso['ResponsavelTransportadora'] = e.target.checked;
                                                                    this.setState({ acesso: acesso });
                                                                }} />
                                                                <label className="form-check-label" htmlFor="responsaveltransportadora">Sim</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-md-6 col-lg-4">

                                                    <div className="form-group row">
                                                        <label className="col-12 col-form-label" >Usuário pode ser responsável em um registro de entrada (coleta e transporte)? <b style={{ color: 'red' }}></b></label>

                                                        <div className="col-sm-12 d-flex align-items-center">
                                                            <label className="form-check-label" htmlFor="responsavelentradacoleta" style={{ marginRight: 10 }}>Não</label>

                                                            <div className="form-check form-switch col-12 col-md-4 col-lg-4">

                                                                <input className="form-check-input" type="checkbox" id="responsavelentradacoleta" checked={this.state.acesso['ResponsavelRegistroEntradaCT']} onChange={(e) => {
                                                                    let acesso = { ...this.state.acesso };
                                                                    acesso['ResponsavelRegistroEntradaCT'] = e.target.checked;
                                                                    this.setState({ acesso: acesso });
                                                                }} />
                                                                <label className="form-check-label" htmlFor="responsavelentradacoleta">Sim</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="col-12 col-md-6 col-lg-4">

                                                    <div className="form-group row">
                                                        <label className="col-12 col-form-label" >Usuário pode ser responsável em uma solicitação de coleta? <b style={{ color: 'red' }}></b></label>

                                                        <div className="col-sm-12 d-flex align-items-center">
                                                            <label className="form-check-label" htmlFor="responsavelsolicitacaocoleta" style={{ marginRight: 10 }}>Não</label>

                                                            <div className="form-check form-switch col-12 col-md-4 col-lg-4">

                                                                <input className="form-check-input" type="checkbox" id="responsavelsolicitacaocoleta" checked={this.state.acesso['ResponsavelSolicitacaoColeta']} onChange={(e) => {
                                                                    let acesso = { ...this.state.acesso };
                                                                    acesso['ResponsavelSolicitacaoColeta'] = e.target.checked;
                                                                    this.setState({ acesso: acesso });
                                                                }} />
                                                                <label className="form-check-label" htmlFor="responsavelsolicitacaocoleta">Sim</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-md-6 col-lg-4">

                                                    <div className="form-group row">
                                                        <label className="col-12 col-form-label" >Usuário pode ser responsável em um registro de entrada (gestão interna)? <b style={{ color: 'red' }}></b></label>

                                                        <div className="col-sm-12 d-flex align-items-center">
                                                            <label className="form-check-label" htmlFor="responsavelentradainterna" style={{ marginRight: 10 }}>Não</label>

                                                            <div className="form-check form-switch col-12 col-md-4 col-lg-4">

                                                                <input className="form-check-input" type="checkbox" id="responsavelentradainterna" checked={this.state.acesso['ResponsavelRegistroEntradaGI']} onChange={(e) => {
                                                                    let acesso = { ...this.state.acesso };
                                                                    acesso['ResponsavelRegistroEntradaGI'] = e.target.checked;
                                                                    this.setState({ acesso: acesso });
                                                                }} />
                                                                <label className="form-check-label" htmlFor="responsavelentradainterna">Sim</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {this.state.permissao_notificacao && <div className="col-12 col-md-6 col-lg-4">

                                                    <div className="form-group row">
                                                        <label className="col-12 col-form-label" >Usuário recebe notificações de todo sistema ou apenas as direcionadas à ele? <b style={{ color: 'red' }}></b></label>

                                                        <div className="col-sm-12 d-flex align-items-center">
                                                            <label className="form-check-label" htmlFor="notificacaoIndividual" style={{ marginRight: 10 }}>Geral</label>

                                                            <div className="form-check form-switch col-12 col-md-4 col-lg-4">

                                                                <input className="form-check-input" type="checkbox" id="notificacaoIndividual" checked={this.state.acesso['NotificacaoIndividual']} onChange={(e) => {
                                                                    let acesso = { ...this.state.acesso };
                                                                    acesso['NotificacaoIndividual'] = e.target.checked;
                                                                    this.setState({ acesso: acesso });
                                                                }} />
                                                                <label className="form-check-label" htmlFor="notificacaoIndividual">Apenas para ele</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>}
                                            </div>

                                        </div>


                                    </div>

                                </div>

                                {this.state.loading_save == false && <div className="row mt-3">
                                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <Link to="/pessoas"> <span
                                            className="btn btn-secondary btn-lg waves-effect waves-light"><MdReply /> Voltar</span></Link>
                                    </div>
                                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <button type="button" onClick={() => this.salvar()} className="btn btn-success btn-lg waves-effect waves-light"
                                            style={{ backgorundColor: '#f2f2f2' }}>Salvar pessoa</button>
                                    </div>
                                </div>}
                                {this.state.loading_save == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                    <div className="spinner-border text-primary" role="status"  >
                                    </div>
                                </div></div>}

                                {/* {this.state.andamento == true && <AvaliacaoAndamento get_processo={this.get_processo.bind(this)} {...this.props} />} */}

                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                </div>
                            </div></div>}


                        </div>

                    </div>
                </div>
            </div>
        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout })(CriarPessoa);


