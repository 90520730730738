
import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { logout } from '../../../actions/AppActions';
import { connect } from 'react-redux';

// users
import { URL } from '../../../variables';
import { maskLimitedText, maskNumber, maskNumberBr, maskPrice, moneyMask2 } from '../../../Auxiliar/Masks';

import { MdInfo, MdModeEdit, MdReply } from 'react-icons/md';
import { FaTrash } from 'react-icons/fa';
import SweetAlert from 'react-bootstrap-sweetalert';
import { NumericFormat } from 'react-number-format';
import { toast, ToastContainer } from 'react-toastify';
import GetToken from '../../SigorRequests/GetToken';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import TransportadoraModal from '../../Modais/TransportadoraModal';
import MotoristaModal from '../../Modais/MotoristaModal';
import VeiculoModal from '../../Modais/VeiculoModal';
import SwitchInput from '../../../Auxiliar/SwitchInput';
import FileInput from '../../../Auxiliar/FileInput';
import Label from '../../../Auxiliar/Label';
import StatusStepper from '../../../Auxiliar/StatusStepper';
import { StatusColeta } from '../../../Configs/Status';

class EditarDespacho extends Component {

  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      path: '/acompanhar_etapas',
      loading_screen: false,
      ResiduosRejeitos: [],
      Estoques: [],
      EstoqueSelecionado: [],
      Solicitacoes: [],
      SolicitacoesSelecionadas: [],
      SolicitacoesInseridas: [],
      MateriaisSolicitacao: [],
      MateriaisInseridos: [],
      Transportadoras: [],
      Veiculos: [],
      Motoristas: [],
      Devolucao: [],
      veiculos_motoristas: [],

      DevolucaoSelecionada: {},
      DespachoAtual: {},
      Materialselecionado: {},
      SolicitacaoSelecionada: {},

      TransportadoraId: '',
      Situacao: 'Rascunho',
      Descricao: '',
      ValorFinanceiro: '',
      Movimentacao: '',
      Outros: false,
      Peso: '',
      Volume: '',
      MaterialSolicitacao: '',
      Solicitacao: '',
      Estoque: '',
      DataAtual: '',
      DataSolicitacao: '',
      DataAgendamento: '',
      DataInicio: '',
      DataCriacao: '',
      PesoTotal: '0',
      VolumeTotal: '0',
      PesoTotalVeiculo: '0',
      VolumeTotalVeiculo: '0',
      ReceitaTotal: '0.00',
      DespesaTotal: '0.00',
      Saldo: '0.00',
      MotoristaId: '',
      VeiculoId: '',
      loading_save: false,
      msgErro: '',
      show_warning: false,
      show_warning_inserir: false,
      msgErroMotoristaVeiculo: '',
      loading_rota_despacho:false,
      destinoCnpj: '',
      unidadeDestino: '',
      transportadoraCnpj: '',
      unidadeTransportadora: '',
      sigorCodigos: [],
      DestinacaoId: '',
      destinacaoError: false,
      DestinosSigor: [],
      TransportadorasSigor: [],
      MaterialSolicitacaoSigor: [],
      TratamentosSigor: [],
      Destinacoes: [],
      Tratamentos: [],
      Tratamento: '',
      tratamentoError: false,
      sigorDate: new Date().getTime(),
      estoque_interno: false,
      destino_externo: false,
      genMtr: false,
      ChaveNota: '',
      NumeroNota: '',
      SerieNota: '',
      operationError: '',
      idHorarioSelecionado: '',
      HorariosDisponiveis: [],
      filterFixedRequests: false,
      newSolicitations: [],
      checkError: ''
    };
  }
  diasSemana = ['segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado', 'domingo'];


  get_data() {
    let type = this.props.type === 'interno' ? 'despacho' : 'destinacao'
    let MateriaisInseridos = []
    this.props.data[type].materiais.forEach(item => {
      MateriaisInseridos = [...MateriaisInseridos, {
        "residuo_rejeito_id": item.residuo_rejeito.id,
        "nome": item.residuo_rejeito.nome,
        "peso": item.peso,
        "tipo_unidade_principal": item.residuo_rejeito.unidade_principal.tipo,
        "unidade_principal": item.residuo_rejeito.unidade_principal.sigla,
        "unidade_secundaria": item.residuo_rejeito.unidade_secundaria.sigla,
        "volume": item.volume,
        "peso_kg": item.peso_kg,
        "volume_lt": item.volume_lt,
        "movimentacao": item.movimentacao,
        "valor": item.valor,
        'solicitacao_id': item.solicitacao_id,
        'ponto_name': item.ponto_name,
        "destinacao_disposicao_id": item.destinacao_disposicao_id,
        "tratamento_id": item.tratamento_id
      }]
    })
    const { key = null, number = null, serie = null, document = null } = this.props.data[type].invoice || {}

    let dataObj = {
      Solicitacoes: this.props.data.solicitacoes,
      ResiduosRejeitos: this.props.data.materiais,
      Transportadoras: this.props.data.transportadoras,
      Veiculos: this.props.data.veiculos,
      Motoristas: this.props.data.motoristas,

      TransportadoraId: this.props.data[type].transportadora_id,
      Situacao: this.props.data[type].situacao,
      Descricao: this.props.data[type].descricao != null ? this.props.data[type].descricao : '',

      DataCriacao: this.props.data[type].data_criacao,
      DataSolicitacao: this.props.data[type].data_solicitacao,
      DataAgendamento: this.props.data[type].data_agendamento != null ? this.props.data[type].DataInicio : this.props.data[type].data_inicio != null ? this.props.data[type].data_inicio : '',
      PesoTotal: this.props.data[type].peso_total,
      VolumeTotal: this.props.data[type].volume_total,
      ReceitaTotal: this.props.data[type].receita_total,
      DespesaTotal: this.props.data[type].despesa_total,

      Saldo: this.props.data[type].saldo,
      EstoqueSelecionado: [this.props.data[type].estoque],
      newSolicitations: this.props.data.new_solicitations,
      ChaveNota: key ?? this.props.data[type].chave_nota,
      NumeroNota: number ?? this.props.data[type].numero_nota,
      SerieNota: serie ?? this.props.data[type].serie_nota,
      AnexoNota: document,
      MateriaisInseridos,
    }

    if (this.props.type === 'interno') {
      console.log('props', this.props.data)
      let estoque_sel = this.props.data.estoques.filter(d => d.id === this.props.data.despacho.estoque_id);
      let sol_sel = [];
      for (let i = 0; i < this.props.data.solicitacoes.length; i++) {
        for (let j = 0; j < this.props.data.solicitacoes[i].material.length; j++) {
          if (estoque_sel[0].residuos_rejeitos.length > 0) {
            for (let k = 0; k < estoque_sel[0].residuos_rejeitos.length; k++) {
              if (this.props.data.solicitacoes[i].material[j].residuo_rejeito_id === estoque_sel[0].residuos_rejeitos[k].id) {

                sol_sel.push(this.props.data.solicitacoes[i]);
                k = estoque_sel[0].residuos_rejeitos.length;
                j = this.props.data.solicitacoes[i].material.length;
              }

            }
          }
        }
      }
      let solicitacao = sol_sel.filter(item => item.id === this.props.data.despacho.solicitacao_id);

      let PesoTotalVeiculo = 0;
      let VolumeTotalVeiculo = 0;

      for (let i = 0; i < this.props.data.veiculos_despacho.length; i++) {
        let veiculo = this.props.data.veiculos_despacho[i];
        PesoTotalVeiculo = parseFloat(PesoTotalVeiculo) + (parseFloat(veiculo.capacidade_maxima_peso) * parseFloat(veiculo.unidade_peso.equivalencia));
        VolumeTotalVeiculo = parseFloat(VolumeTotalVeiculo) + (parseFloat(veiculo.capacidade_maxima_volume) * parseFloat(veiculo.unidade_volume.equivalencia));

      }

      const diasFuncionamento = this.props.data.solicitacoes_selecionadas[0].ponto_coleta.dia_funcionamento

      dataObj = {
        ...dataObj,
        loading_screen: false,
        Estoques: this.props.data.estoques,
        PesoTotalVeiculo,
        VolumeTotalVeiculo,
        DespachoAtual: this.props.data.despacho,
        Estoque: this.props.data.despacho.estoque_id,
        DataInicio: this.props.data.despacho.data_inicio != null ? this.props.data.despacho.data_inicio : '',
        DataAgendamento: this.props.data.despacho.data_agendamento != null ? this.props.data.despacho.data_agendamento : '',
        veiculos_motoristas: this.props.data.despacho.veiculos_motoristas,
        SolicitacoesSelecionadas: sol_sel,
        SolicitacaoSelecionada: this.props.data.solicitacoes_selecionadas,
        filterFixedRequests: this.props.data.solicitacoes_selecionadas[0].codigo_solicitacao.slice(-1) === '*' ? true : false,
        idHorarioSelecionado: this.props.data.despacho.horario_id,
        HorariosDisponiveis: diasFuncionamento.length > 0 ? diasFuncionamento.filter(
          item => Number(item.dia_sem) === new Date(this.props.data.despacho.data_agendamento).getUTCDay()
        )[0].horario_funcionamento : '',
        Solicitacao: this.props.data.solicitacoes_selecionadas[0].id,
        MateriaisSolicitacao: this.props.data.solicitacoes_selecionadas[0].material,
        estoque_interno: true
      };
    } else {
      console.log('props', this.props.data)
      let destino_sel = this.props.data.destinos.filter(d => d.id === this.props.data.destinacao.destino_id);
      let sol_sel = [];
      for (let i = 0; i < this.props.data.solicitacoes.length; i++) {
        for (let j = 0; j < this.props.data.solicitacoes[i].material.length; j++) {
          if (destino_sel[0].residuos_rejeitos.length > 0) {
            for (let k = 0; k < destino_sel[0].residuos_rejeitos.length; k++) {
              if (this.props.data.solicitacoes[i].material[j].residuo_rejeito_id === destino_sel[0].residuos_rejeitos[k].id) {

                sol_sel.push(this.props.data.solicitacoes[i]);
                k = destino_sel[0].residuos_rejeitos.length;
                j = this.props.data.solicitacoes[i].material.length;
              }

            }
          }
        }
      }
      let solicitacao = sol_sel.filter(item => item.id === this.props.data.destinacao.solicitacao_id);
      let materiais = solicitacao[0].material;

      console.log('solicitacap', solicitacao)
      console.log('destino', this.props.data.destinacao)

      let PesoTotalVeiculo = 0;
      let VolumeTotalVeiculo = 0;

      this.props.data.destinacao.veiculos_motoristas.forEach(item => {
        PesoTotalVeiculo = parseFloat(PesoTotalVeiculo) + (parseFloat(item.veiculo.capacidade_maxima_peso) * parseFloat(item.veiculo.unidade_peso.equivalencia));
        VolumeTotalVeiculo = parseFloat(VolumeTotalVeiculo) + (parseFloat(item.veiculo.capacidade_maxima_volume) * parseFloat(item.veiculo.unidade_volume.equivalencia));
      });
      dataObj = {
        ...dataObj,
        loading_screen: false,
        Destinos: this.props.data.destinos,
        Destinacoes: this.props.data.destinacoes,
        Tratamentos: this.props.data.tratamentos,
        Estoque: this.props.data.destinacao.destino.id,
        idHorarioSelecionado: this.props.data.destinacao.horario_id,
        HorariosDisponiveis: solicitacao[0].ponto_coleta.dia_funcionamento.filter(item => Number(item.dia_sem) === new Date(this.props.data.destinacao.data_agendamento).getUTCDay())[0]?.horario_funcionamento,

        DestinacaoAtual: this.props.data.destinacao,
        Solicitacao: this.props.data.destinacao.solicitacao_id,
        Destino: this.props.data.destinacao.destino_id,
        DataInicio: this.props.data.destinacao.data_inicio != null ? this.props.data.destinacao.data_inicio : '',
        veiculos_motoristas: this.props.data.destinacao.veiculos_motoristas,
        SolicitacoesSelecionadas: sol_sel,
        SolicitacaoSelecionada: solicitacao,
        DestinoSelecionado: this.props.data.destinacao.destino,
        MateriaisSolicitacao: materiais,
        PesoTotalVeiculo,
        VolumeTotalVeiculo,
        destino_externo: true,
      }
    }
    this.setState({ ...dataObj })
  }



  salvar() {
    if (this.state.DataAgendamento != "" && this.state.idHorarioSelecionado == "") {
     this.setState({msgErro:'Ao selecionar uma data de agendamento, selecione também um horário'});
     window.scrollTo(500, 0);
     return
    }
    if (this.state.Materialselecionado.length > 0) {

      this.setState({ show_warning_inserir: true, msgErro: '' })
    } else {
      if (this.props.type === 'interno') {
        this.setState({ show_warning_inserir: false, loading_save: true, msgErro: '' });
        fetch(`${URL}api/update_despacho`, {
          method: 'PATCH',
          headers: {
            Accept: 'application/json',
            'Authorization': `Bearer ${this.props.token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            //olhar aqui depois - despacho_id
            despacho_id: this.props.data.despacho.id,
            solicitacao_id: this.state.Solicitacao,
            estoque_id: this.state.Estoque,
            data_criacao: this.state.DataAtual,
            data_solicitacao: this.state.DataSolicitacao,
            data_agendamento: this.state.DataAgendamento,
            data_inicio: this.state.DataInicio,
            peso_total: this.state.PesoTotal,
            volume_total: this.state.VolumeTotal,
            receita_total: this.state.ReceitaTotal,
            despesa_total: this.state.DespesaTotal,
            saldo: this.state.Saldo,
            // motorista_id: this.state.MotoristaId,
            // veiculo_id: this.state.VeiculoId,
            veiculos_motoristas: this.state.veiculos_motoristas,
            status_estoque_interno: this.state.estoque_interno,

            descricao: this.state.Descricao,
            situacao: this.state.Situacao,
            transportadora_id: this.state.TransportadoraId,
            materiais_coletados: this.state.MateriaisInseridos,
            horario_id: this.state.idHorarioSelecionado,
            new_solicitations: this.state.newSolicitations
          })
        }).then(async (responseLog) => {
          try {
            let resp = await responseLog.json();
            if (resp.errors != null || resp.error != null) {
              let errors = Object.values(resp.errors);
              let erro = '';
              for (let i = 0; i < errors.length; i++) {
                if (i != errors.length - 1)
                  erro += errors[i] + '\n';
                else
                  erro += errors[i];
              }
              console.log(erro);
              window.scrollTo(500, 0);
              this.setState({ loading_save: false, msgErro: erro });
            }
            else {

              this.setState({ loading_save: false, redirect: true });

            }

          } catch (err) {
            console.log(err);
            this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

          }

        })
          .catch((err) => {
            console.log(err);
            this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
          });
      } else {
        this.setState({ show_warning_inserir: false, loading_save: true, msgErro: '' });

        fetch(`${URL}api/update_destinacao`, {
          method: 'PATCH',
          headers: {
            Accept: 'application/json',
            'Authorization': `Bearer ${this.props.token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            destinacao_coleta_id: this.props.data.destinacao.id,
            solicitacao_id: this.state.Solicitacao,
            destino_id: this.state.Destino,
            data_criacao: this.state.DataCriacao,
            data_solicitacao: this.state.DataSolicitacao,
            data_agendamento: this.state.DataAgendamento,
            data_inicio: this.state.DataInicio,
            peso_total: this.state.PesoTotal,
            volume_total: this.state.VolumeTotal,
            receita_total: this.state.ReceitaTotal,
            despesa_total: this.state.DespesaTotal,
            saldo: this.state.Saldo,
            veiculos_motoristas: this.state.veiculos_motoristas,
            descricao: this.state.Descricao,
            situacao: this.state.Situacao,
            transportadora_id: this.state.TransportadoraId,
            materiais_coletados: this.state.MateriaisInseridos,
            key: this.state.ChaveNota,
            number: this.state.NumeroNota,
            serie: this.state.SerieNota,
            document: this.state.AnexoNota,
            horario_id: this.state.idHorarioSelecionado,
            new_solicitations: this.state.newSolicitations
          })
        }).then(async (responseLog) => {
          try {
            let resp = await responseLog.json();
            if (resp.errors != null || resp.error != null) {
              let errors = Object.values(resp.errors);
              let erro = '';
              for (let i = 0; i < errors.length; i++) {
                if (i != errors.length - 1)
                  erro += errors[i] + '\n';
                else
                  erro += errors[i];
              }
              console.log(erro);
              window.scrollTo(500, 0);
              this.setState({ loading_save: false, msgErro: erro });
            }
            else {

              this.setState({ loading_save: false, redirect: true });

            }

          } catch (err) {
            console.log(err);
            this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

          }

        })
          .catch((err) => {
            console.log(err);
            this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
          });
      }
    }
  }

  //Function responsible for deleting the deleted residues from the inserted list of the list that appears everything that is pending to the vehicles 
  clearCargas(residuoId) {
    //Loop through each item in the list of added vehicles
    let veiculos_motoristas = this.state.veiculos_motoristas.map(item => {
      //Filter to validate if the residue exists in that vehicle item
      let residuo = item.sigor?.filter(item2 => item2.residuo_id === residuoId)[0]

      //Filter where it separates the vehicle residues from the one selected for deletion
      let newSigor = item.sigor?.filter(item2 => item2.residuo_id !== residuoId)
      item.sigor = newSigor

      //Filter where it separates the sigor controllers, where it serves to validate the true or false status of the checked lists
      let newCargas = item.cargas?.filter(item2 => item2.residuo_rejeito_id !== residuoId)
      item.cargas = newCargas

      //If it is known that that vehicle has the residue, the value added for transport is returned to the vehicle's capacity
      if (residuo) {
        item.veiculo.capacidade_disponivel = item.veiculo.capacidade_disponivel + residuo.quantidadeKg
        item.isFull = false
      }
      return item
    })
    return veiculos_motoristas
  }


  componentDidMount() {
    let data = new Date();
    let dia = String(data.getDate()).padStart(2, '0');
    let mes = String(data.getMonth() + 1).padStart(2, '0');
    let ano = data.getFullYear();
    this.setState({
      DataAtual: ano + '-' + mes + '-' + dia,
      DataSolicitacao: ano + '-' + mes + '-' + dia
    })
    this.get_data();
  }

  handleDateChange = ({ target }) => {
    if (target.value !== '' && target.value !== this.state.DataAtual) {
      this.setState({ DataInicio: this.state.DataAtual })
    }
    if (target.value !== '' && target.value === this.state.DataAtual) {
      this.setState({ Situacao: 'Iniciado', DataInicio: target.value })
    }
    if (target.value === '' && this.state.DataAgendamento !== '') {
      this.setState({ Situacao: 'Agendado', DataInicio: '' })
    }
    if (target.value === '' && this.state.DataAgendamento === '') {
      this.setState({ Situacao: 'Rascunho', DataInicio: '' })
    }
  }

  handleTransportChange = (e) => {
    let veiculos_motoristas = [...this.state.veiculos_motoristas];
    let exists = false;
    let msgErroMotoristaVeiculo = '';

    // Check if the selected veiculo or motorista already exists in the table.
    veiculos_motoristas.map((item1, id1) => {
      if (item1.veiculo.id === this.state.VeiculoId) {
        exists = true;
        msgErroMotoristaVeiculo = 'O veículo selecionado já está presente na tabela.';
      } else if (item1.motorista.id === this.state.MotoristaId) {
        exists = true;
        msgErroMotoristaVeiculo = 'O motorista selecionado já está presente na tabela.';
      }
      return item1;
    });

    // Check if a transportadora is required for MTR generation.
    if (this.state.genMtr && !this.state.TransportadoraId) {
      msgErroMotoristaVeiculo = 'É necessário um vínculo com transportadora para geração de MTR.';
    }

    // If the veiculo and motorista are unique and no error message, add them to veiculos_motoristas.
    if (!exists && !msgErroMotoristaVeiculo) {
      let veiculo = this.state.Veiculos.filter((item1, id1) => item1.id === this.state.VeiculoId)[0];
      let motorista = this.state.Motoristas.filter((item1, id1) => item1.id === this.state.MotoristaId)[0];
      let PesoTotalVeiculo = parseFloat(this.state.PesoTotalVeiculo) + (parseFloat(veiculo.capacidade_maxima_peso) * parseFloat(veiculo.unidade_peso.equivalencia));
      let VolumeTotalVeiculo = parseFloat(this.state.VolumeTotalVeiculo) + (parseFloat(veiculo.capacidade_maxima_volume) * parseFloat(veiculo.unidade_volume.equivalencia));
      veiculo.capacidade_disponivel = veiculo.capacidade_maxima_peso;

      // Add the veiculo and motorista to veiculos_motoristas along with other relevant data.
      if (this.state.genMtr) {
        let cargas = [];
        this.state.MateriaisInseridos.forEach(item => {
          cargas = [...cargas, {
            residuo_rejeito_id: item.residuo_rejeito_id,
            nome: item.nome,
            unidade: item.unidade_principal,
            checked: false,
          }];
        });

        veiculos_motoristas.push({
          veiculo: veiculo,
          motorista: motorista,
          open: false,
          cargas: cargas,
          transportadorObj: {
            unidade: this.state?.unidadeTransportadora,
            cpfCnpj: this.state?.transportadoraCnpj.replace(/[^\d]+/g, '')
          }
        });
      } else {
        veiculos_motoristas.push({
          veiculo: veiculo,
          motorista: motorista,
        });
      }

      this.setState({ PesoTotalVeiculo, VolumeTotalVeiculo, veiculos_motoristas, VeiculoId: '', MotoristaId: '', msgErroMotoristaVeiculo: '' });
    } else {
      this.setState({ msgErroMotoristaVeiculo: msgErroMotoristaVeiculo });
    }
  }

  handleDeleteTransport = (item, id) => {
    // Filters out the transport item with the specified ID from the state array.
    let veiculos_motoristas = this.state.veiculos_motoristas.filter((item1, id1) => id1 !== id);
    // Retrieves the transport item with the specified ID from the state array.
    let veiculo_motorista = this.state.veiculos_motoristas.filter((item1, id1) => id1 === id)[0];
    // Calculates the updated total weight of the vehicles, considering the removed transport item.
    let PesoTotalVeiculo = parseFloat(this.state.PesoTotalVeiculo) - (parseFloat(veiculo_motorista.veiculo.capacidade_maxima_peso) * parseFloat(veiculo_motorista.veiculo.unidade_peso.equivalencia));
    // Calculates the updated total volume of the vehicles, considering the removed transport item.
    let VolumeTotalVeiculo = parseFloat(this.state.VolumeTotalVeiculo) - (parseFloat(veiculo_motorista.veiculo.capacidade_maxima_volume) * parseFloat(veiculo_motorista.veiculo.unidade_volume.equivalencia));
    // Removes the associated cargo of the removed transport item if 'genMtr' is true.
    if (this.state.genMtr) this.removeCarga(item.motorista.id);
    // Updates the state with the modified transport items and total weight/volume values.
    this.setState({ veiculos_motoristas, PesoTotalVeiculo, VolumeTotalVeiculo });
  }

  handleVehicleChange = ({ target }) => {
    this.proprio = false;
    for (let i = 0; i < this.state.Veiculos.length; i++) {
      if (this.state.Veiculos[i].id === target.value) {
        if (this.state.Veiculos[i].transportadora_id == null) {
          this.proprio = true;
          break;
        }
      }
    }
    this.setState({ VeiculoId: target.value, MotoristaId: '' }, () => {
    })
  }

  handleCarrierChange = ({ target }) => {
    if (target.value !== '') {
      const transp_selecionada = this.state.Transportadoras.filter(item => item.id === target.value)
      this.setState({
        TransportadoraId: target.value,
        VeiculoId: "",
        MotoristaId: '',
        unidadeTransportadora: transp_selecionada[0].unidade_sigor?.num,
        transportadoraCnpj: transp_selecionada[0].cnpj.replace(/\D/g, '')
      });
    }
  }

  handleStockChange = ({ target }) => {
    if (target.value === '') {
      // Reset state when no stock is selected
      this.setState({
        SolicitacoesSelecionadas: [],
        Estoque: '',
        Solicitacao: '',
        EstoqueSelecionado: [],
        SolicitacaoSelecionada: {},
        MaterialSolicitacao: {},
        Peso: '',
        Volume: '',
        ValorFinanceiro: '',
        Movimentacao: '',
        Outros: false,
        Materialselecionado: {},
        MateriaisInseridos: [],
        veiculos_motoristas: this.state.genMtr ? [] : this.state.veiculos_motoristas,
        sigorCodigos: []
      });
    } else {
      let estoqueSel = this.state[this.state.estoque_interno ? 'Estoques' : 'Destinos'].filter(d => d.id === target.value);
      // console.log('estoque sel', estoqueSel);

      let solSel = this.state.Solicitacoes.filter(item => {
        return estoqueSel[0].residuos_rejeitos.some(item2 => item.material.id === item2.id);
      });

      this.state.Solicitacoes.forEach(solicitacao => {
        solicitacao.material.forEach(material => {
          if (estoqueSel[0].residuos_rejeitos.some(residuo => material.residuo_rejeito_id === residuo.id)) {
            solSel.push(solicitacao);
          }
        });
      });


      this.setState({
        SolicitacoesSelecionadas: solSel,
        Estoque: target.value,
        Destino: target.value,
        Solicitacao: '',
        EstoqueSelecionado: estoqueSel,
        DestinoSelecionado: estoqueSel,
        SolicitacaoSelecionada: {},
        MaterialSolicitacao: {},
        Peso: '',
        Volume: '',
        Movimentacao: '',
        Outros: false,
        ValorFinanceiro: '',
        Materialselecionado: {},
        MateriaisInseridos: [],
        unidadeDestino: estoqueSel[0].unidade_sigor ? estoqueSel[0].unidade_sigor.num : '',
        destinoCnpj: estoqueSel[0].cnpj.replace(/\D/g, ''),
        veiculos_motoristas: this.state.genMtr ? [] : this.state.veiculos_motoristas,
        sigorCodigos: []
      });
    }
  }

  handleSolicitationChange = ({ target },callback=()=>{}) => {
    // Check if the material is already inserted
    const isMaterialAlreadyInserted = this.state.MateriaisInseridos.some(item =>
      item.residuo_rejeito_id === target.value);

    if (target.value === '' || isMaterialAlreadyInserted) {
      // Reset state when no solicitation is selected or the material is already inserted
      this.setState({
        SolicitacaoSelecionada: {},
        MateriaisSolicitacao: [],
        Solicitacao: '',
        MaterialSolicitacao: {},
        Peso: '',
        Volume: '',
        Movimentacao: '',
        Outros: false,
        ValorFinanceiro: '',
      });
    } else {
      let solicitacao = this.state.SolicitacoesSelecionadas.filter(item => item.id === target.value);
      let materiais = solicitacao[0].material.map(item => {
        if (item.peso !== 0) {
          item.solicitacao_id = solicitacao[0].id;
          item.ponto_name = solicitacao[0].ponto_coleta.nome_fantasia;

          return item;
        }
        return item;
      });
      materiais = materiais.filter((item) => item !== undefined);
      let material_filtro = [];
      for (let i = 0; i < this.state.EstoqueSelecionado[0].residuos_rejeitos.length; i++) {
        for (let j = 0; j < materiais.length; j++) {
          if (materiais[j].residuo_rejeito_id === this.state.EstoqueSelecionado[0].residuos_rejeitos[i].id) {
            material_filtro.push(materiais[j]);
          }
        }
      }

      let MaterialSolicitacaoSigor = []
      if (this.state.genMtr) {
        MaterialSolicitacaoSigor = this.getSigorMaterials(materiais)
      }

      this.setState({
        SolicitacaoSelecionada: solicitacao,
        SolicitacoesInseridas: [...this.state.SolicitacoesInseridas, solicitacao[0]],
        MateriaisSolicitacao: this.state.estoque_interno ? material_filtro : materiais,
        Solicitacao: target.value,
        MaterialSolicitacao: {},
        Peso: '',
        Volume: '',
        Movimentacao: '',
        Outros: false,
        ValorFinanceiro: '',
        Materialselecionado: {},
        MaterialSolicitacaoSigor,
      },()=>{
        callback();
      });
    }
  }

  handleMaterialChange = ({ target }) => {
    const { MateriaisInseridos, Solicitacao, genMtr, MaterialSolicitacaoSigor, MateriaisSolicitacao } = this.state;
    let verificador = false;

    // Check if the selected material is already inserted for the current request
    for (let i = 0; i < MateriaisInseridos.length; i++) {
      if (target.value === MateriaisInseridos[i].residuo_rejeito_id && Solicitacao === MateriaisInseridos[i].solicitacao_id) {
        verificador = true;
        break;
      }
    }

    if (target.value === '' || verificador === true) {
      // Reset the form fields if the selected material is empty or already inserted
      this.setState({
        MaterialSolicitacao: '',
        Materialselecionado: {},
        Peso: '',
        Movimentacao: '',
        Outros: false,
        ValorFinanceiro: '',
        Volume: '',
      });
    } else {
      let mat_sel = genMtr ? MaterialSolicitacaoSigor.filter(item => item.residuo_rejeito_id === target.value) : MateriaisSolicitacao.filter(item => item.residuo_rejeito_id === target.value);

      this.setState({
        MaterialSolicitacao: target.value,
        Materialselecionado: mat_sel,
        Tratamento: mat_sel[0].tratamento_id,
        Peso: '',
        Movimentacao: mat_sel[0].saldo_financeiro,
        ValorFinanceiro: '',
        Volume: '',
      });
    }
  }

  handleScheduledChange = ({ target }) => {
    const { SolicitacaoSelecionada, DataInicio, DataAtual } = this.state;
    const diasFuncionamento = SolicitacaoSelecionada[0].ponto_coleta.dia_funcionamento;
    const diaSemana = new Date(target.value).getUTCDay();

    let Situacao = 'Rascunho';
    let DataAgendamento = target.value;

    // Handle different scenarios based on the selected date and other state variables
    if (target.value !== '' && DataInicio === '') {
      // If no start date is set and the selected date is in the future or today
      if (target.value >= DataAtual) {
        Situacao = 'Agendado';
        DataAgendamento = target.value;
      } else {
        // If the selected date is in the past, set the date to today
        DataAgendamento = DataAtual;
      }
    } else if (target.value !== '' && DataInicio !== '') {
      // If a start date is set and the selected date is in the future or today
      if (target.value >= DataAtual) {
        DataAgendamento = target.value;
      } else {
        // If the selected date is in the past, set the date to today
        DataAgendamento = DataAtual;
      }
    }

    if (diasFuncionamento.length === 0) {
      this.setState({ Situacao, DataAgendamento })
    } else {
      // Check if the selected day is within the functioning days of the selected point of collection
      if (diasFuncionamento.some(item => Number(item.dia_sem) === diaSemana)) {
        const horarios = diasFuncionamento.find(item => Number(item.dia_sem) === diaSemana).horario_funcionamento;

        this.setState({
          Situacao,
          DataAgendamento,
          operationError: '',
          HorariosDisponiveis: horarios ? horarios : []
        });
      } else {
        const nomeFantasia = SolicitacaoSelecionada[0].ponto_coleta.nome_fantasia;
        const diaSemanaTexto = this.diasSemana[new Date(target.value).getDay()];

        // If the selected day is not within the functioning days, show an error message
        this.setState({
          operationError: `${nomeFantasia} não funciona ${diaSemanaTexto}`,
          HorariosDisponiveis: []
        });
      }
    }
  }

  handleAddMaterials = () => {
    let receita;
    let despesa;
    let saldo;
    let valor = this.state.ValorFinanceiro;
    if (this.state.Movimentacao === 'Receita') {
      saldo = parseFloat(parseFloat(this.state.Saldo).toFixed(2)) + parseFloat(parseFloat(valor).toFixed(2));
      receita = parseFloat(parseFloat(this.state.ReceitaTotal).toFixed(2)) + parseFloat(parseFloat(valor).toFixed(2));
      despesa = parseFloat(parseFloat(this.state.DespesaTotal).toFixed(2));
    } else if (this.state.Movimentacao === 'Despesa') {
      despesa = parseFloat(parseFloat(this.state.DespesaTotal).toFixed(2)) + parseFloat(parseFloat(valor).toFixed(2));
      saldo = parseFloat(parseFloat(this.state.Saldo).toFixed(2)) - parseFloat(parseFloat(valor).toFixed(2));
      receita = parseFloat(parseFloat(this.state.ReceitaTotal).toFixed(2));
    } else {
      despesa = parseFloat(parseFloat(this.state.DespesaTotal).toFixed(2));
      saldo = parseFloat(parseFloat(this.state.Saldo).toFixed(2));
      receita = parseFloat(parseFloat(this.state.ReceitaTotal).toFixed(2));
    }
    let error = false
    if ((this.state.Materialselecionado[0].unidade_principal.tipo === 'Peso' && this.state.Peso === '') ||
      (this.state.Materialselecionado[0].unidade_principal.tipo !== 'Peso' && this.state.Volume === '')) {
      error = true
      this.setState({ required: true })
    } if ((this.state.Materialselecionado[0].unidade_principal.tipo === 'Peso' && parseFloat(this.state.Peso) > parseFloat(this.state.Materialselecionado[0].peso_kg / this.state.Materialselecionado[0].unidade_principal.equivalencia)) ||
      (this.state.Materialselecionado[0].unidade_principal.tipo !== 'Peso' && parseFloat(this.state.Volume) > parseFloat(this.state.Materialselecionado[0].volume_lt / this.state.Materialselecionado[0].unidade_principal.equivalencia))) {
      error = true
      this.setState({ validated: true })
    } if (!this.state.Tratamento && !this.state.estoque_interno) {
      error = true
      this.setState({ tratamentoError: true })
    } if (!this.state.DestinacaoId && !this.state.estoque_interno) {
      error = true
      this.setState({ destinacaoError: true })
    }
    if (!error) {
      let peso_kg;
      let volume_lt;
      if (this.state.Materialselecionado[0].unidade_principal.tipo === 'Peso') {
        peso_kg = parseFloat(this.state.Peso) * parseFloat(this.state.Materialselecionado[0].unidade_principal.equivalencia);
        volume_lt = parseFloat(this.state.Volume) * parseFloat(this.state.Materialselecionado[0].unidade_secundaria.equivalencia);
      } else {
        peso_kg = parseFloat(this.state.Peso) * parseFloat(this.state.Materialselecionado[0].unidade_secundaria.equivalencia);
        volume_lt = parseFloat(this.state.Volume) * parseFloat(this.state.Materialselecionado[0].unidade_principal.equivalencia);
      }

      let rr = { ...this.state.Materialselecionado[0] }
      let materiais = [...this.state.MateriaisInseridos];
      let new_material = {
        "residuo_rejeito_id": this.state.MaterialSolicitacao,
        "nome": this.state.Materialselecionado[0].nome,
        "peso": this.state.Peso,
        "tipo_unidade_principal": this.state.Materialselecionado[0].unidade_principal.tipo,
        "unidade_principal": this.state.Materialselecionado[0].unidade_principal.sigla,
        "unidade_secundaria": this.state.Materialselecionado[0].unidade_secundaria.sigla,
        "volume": this.state.Volume,
        "peso_kg": peso_kg,
        "volume_lt": volume_lt,
        "movimentacao": this.state.Movimentacao,
        "valor": this.state.ValorFinanceiro,
        'solicitacao_id': this.state.Materialselecionado[0].solicitacao_id,
        'ponto_name': this.state.SolicitacaoSelecionada[0].ponto_coleta.nome_fantasia,
        "destinacao_disposicao_id": this.state.DestinacaoId,
        "tratamento_id": this.state.Tratamento
      };

      let codigos = {}
      let codigos_risco = {}
      let rrData = false
      if (rr.sigor) {
        rrData = true
        codigos = {
          "residuo_id": this.state.MaterialSolicitacao,
          "pesoKg": peso_kg,
          "unidade": new_material.unidade_principal,
          "nome": new_material.nome,
          "equivalencia": rr.unidade_principal.equivalencia,
          "marQuantidade": Number(this.state.Peso) / rr.unidade_principal.equivalencia,
          "resCodigoIbama": rr.sigor.residuo,
          "uniCodigo": rr.sigor.unidade_principal,
          "traCodigo": rr.sigor.tratamento,
          "tieCodigo": rr.sigor.estado_fisico,
          "tiaCodigo": rr.sigor.acondicionamento,
          "claCodigo": rr.sigor.classe,
          "inherited": true
          // rr.unidade_principal.relation ? true : false
        }

        // rr.unidade_principal.relation ? Number(this.state.Peso) / rr.unidade_principal.relation.dados_unidade_sigor.equivalencia : Number(this.state.Peso)

        if (rr.sigor.codigo_risco) {
          codigos_risco = {
            "marNumeroONU": rr.sigor.codigo_risco.mar_numero_onu,
            "marClasseRisco": rr.sigor.codigo_risco.mar_classe_risco,
            "marNomeEmbarque": rr.sigor.codigo_risco.mar_nome_embarque,
            "greCodigo": rr.sigor.codigo_risco.gre_codigo
          }
        }
      }

      if (this.state.genMtr && !rrData) {
        toast.warning('Resíduo desatualizado, cadastre-o novamente para evitar erros!')
      }

      let veiculos_motoristas = [...this.state.veiculos_motoristas]
      if (this.state.genMtr && this.state.veiculos_motoristas.length > 0) {
        veiculos_motoristas = this.state.veiculos_motoristas.map(item => {
          item.cargas = [...item.cargas, {
            residuo_rejeito_id: new_material.residuo_rejeito_id,
            nome: new_material.nome,
            unidade: new_material.unidade_principal,
            checked: false,
          }]
          return item
        })
      }

      codigos = { ...codigos, ...codigos_risco }
      materiais.push(new_material);
      this.setState({
        PesoTotal: parseFloat(this.state.PesoTotal) + peso_kg,
        VolumeTotal: parseFloat(this.state.VolumeTotal) + volume_lt,
        MateriaisInseridos: materiais,
        CargaId: materiais[0].residuo_rejeito_id,
        ReceitaTotal: receita.toFixed(2).toString(),
        DespesaTotal: despesa.toFixed(2).toString(),
        Saldo: saldo.toFixed(2).toString(),
        MaterialSolicitacao: '',
        Materialselecionado: {},
        Peso: '',
        Movimentacao: '',
        Outros: false,
        ValorFinanceiro: '',
        Volume: '',
        DestinacaoId: '',
        Tratamento: '',
        sigorCodigos: [...this.state.sigorCodigos, codigos],
        veiculos_motoristas
      })
    }
  }

  handleDeleteMaterial = (item,callback=()=>{}) => {
    const {
      ReceitaTotal,
      DespesaTotal,
      Saldo,
      MateriaisInseridos,
      PesoTotal,
      VolumeTotal,
      sigorCodigos,
      SolicitacaoSelecionada
    } = this.state;

    // Calculate new revenue based on item's movement type
    const receita = item.movimentacao === 'Receita' ? parseFloat(ReceitaTotal) - parseFloat(item.valor) : parseFloat(ReceitaTotal);

    // Calculate new expenses based on item's movement type
    const despesa = item.movimentacao === 'Despesa' ? parseFloat(DespesaTotal) - parseFloat(item.valor) : parseFloat(DespesaTotal);

    // Calculate new balance based on item's movement type
    const saldo = item.movimentacao === 'Receita' ? parseFloat(Saldo) - parseFloat(item.valor) : parseFloat(Saldo);

    // Remove the specific material from the inserted materials array
    const materias_inseridos = MateriaisInseridos.filter((item2) => !(item2.residuo_rejeito_id === item.residuo_rejeito_id && item2.solicitacao_id === item.solicitacao_id));

    // Find the specific material in the Rejected residues array
    const material = SolicitacaoSelecionada[0].material.filter(item3 => item3.residuo_rejeito_id === item.residuo_rejeito_id);

    // Extract units from the found material
    const { unidade_principal, unidade_secundaria } = material[0];

    // Calculate weight in kg based on the selected unit type
    const peso_kg = (unidade_principal.tipo === 'Peso') ? parseFloat(item.peso) * parseFloat(unidade_principal.equivalencia) : parseFloat(item.peso) * parseFloat(unidade_secundaria.equivalencia);

    // Calculate volume in liters based on the selected unit type
    const volume_lt = (unidade_principal.tipo === 'Peso') ? parseFloat(item.volume) * parseFloat(unidade_secundaria.equivalencia) : parseFloat(item.volume) * parseFloat(unidade_principal.equivalencia);

    // Clear cargos based on the specific residue/rejection ID
    const veiculos_motoristas = this.clearCargas(item.residuo_rejeito_id);

    // Update Sigor codes by filtering out the specific residue ID
    const updatedSigorCodigos = sigorCodigos.filter(item => item.residuo_id !== item.residuo_rejeito_id);

    // Update the state with the new values
    this.setState({
      PesoTotal: parseFloat(PesoTotal) - parseFloat(peso_kg),
      VolumeTotal: parseFloat(VolumeTotal) - parseFloat(volume_lt),
      ReceitaTotal: receita.toFixed(2).toString(),
      DespesaTotal: despesa.toFixed(2).toString(),
      Saldo: saldo.toFixed(2).toString(),
      MateriaisInseridos: materias_inseridos,
      veiculos_motoristas,
      sigorCodigos: updatedSigorCodigos
    },()=>{
      callback();
    });
  }

  //Função que itera resultado do fetch do modal ao state da tela pertencente
  updateState = (item, data, item_id, id) => {
    //Usando prevState como função indica o estado anterior sem precisar passar o valor do state como props
    this.setState(prevState => ({
      //Forma dinâmica de iterar sobre o array de itens certo, exemplo: Trasnportadoras: [...prevState.Transportadoras, {NovoObjeto}], TransportadoraId: NovoId
      [item]: [
        ...prevState[item], { ...data }
      ],
      [item_id]: id
    }));
  }

  handleNewSolicitation = (checked, solicitation) => {
    let { newSolicitations } = this.state
    const hasSolicitation = newSolicitations.some(item => item === solicitation.id)

    if (checked && !solicitation.total) {
      this.setState({ checkError: solicitation.id })
      return setTimeout(() => this.setState({ checkError: '' }), 300)
    }

    // Adding a new solicitation when check switch
    if (checked && !hasSolicitation) {
      newSolicitations = [...newSolicitations, solicitation.id]
      return this.setState({ newSolicitations })
    }

    // Removing a solicitation when deselecting switch
    if (!checked && hasSolicitation) {
      newSolicitations = newSolicitations.filter(item => item !== solicitation.id)
      return this.setState({ newSolicitations })
    }
  }

  reduceSolicitationsGroup = (materialsArray) => {
    const { SolicitacoesSelecionadas, newSolicitations } = this.state

    const solicitationsGroup = materialsArray.reduce((group, item) => {
      const id = item.solicitacao_id;
      // Validates whether the material exists in the group to add or change its total
      if (!group.some(groupItem => groupItem.id === id)) {
        const solicitation = SolicitacoesSelecionadas.find(sol => sol.id === id)

        const isChecked = newSolicitations.some(item => item === id)

        const totalKg = solicitation.material.reduce((total, object) => {
          return total += Number(object.peso_kg)
        }, 0)

        group.push({ total: totalKg - Number(item.peso_kg), checked: isChecked, ...solicitation });
      } else {
        group.find(groupItem => groupItem.id === id).total -= Number(item.peso_kg)
      }

      return group;
    }, []);

    return solicitationsGroup;
  }

  render() {
    const pesoExcedido = parseFloat(this.state.PesoTotal) > parseFloat(this.state.PesoTotalVeiculo);
    const volumeExcedido = parseFloat(this.state.VolumeTotal) > parseFloat(this.state.VolumeTotalVeiculo);
    
    const updateCondition = pesoExcedido || volumeExcedido;
    return (
      <div className="row " >
        {this.state.redirect === true && <Redirect to={this.state.path} />}

        <div className="col-12">
          <div className="card">
            <div className="card-body" id="card">


              <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Despacho</h4>
              <p style={{ fontSize: '1rem', color: 'black' }}>Edite o despacho</p>
              <br />

              {this.state.loading_screen === false && <div>
                {this.state.msgErro !== '' && <div>
                  <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>
                    <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                  </div>
                  <br />

                </div>}
                <div className="row">
                  <div className="col-12">
                  <Label title='Status' text={this.state.Situacao} />
          <div className="my-3" style={{ overflowY: 'scroll' }}>
            <StatusStepper statusIndex={StatusColeta.getKeyByValue(this.state.Situacao)} />
          </div>

                    <div className="mb-3">
                      <h1 className='col-form col-form-label'>Selecione o destino do resíduo<b style={{ color: 'red' }}>*</b></h1>
                      <div className="row ms-1">
                        <div className="form-check col-3">
                          <input disabled className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={this.state.estoque_interno} onChange={() => this.setState({ estoque_interno: true, destino_externo: false, genMtr: false, Solicitacao: '', MaterialSolicitacao: '', Materialselecionado: [] })} />
                          <label className="form-check-label" htmlFor="flexRadioDefault1">
                            Estoque Interno
                          </label>
                        </div>
                        <div className="form-check col-3">
                          <input disabled className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked={this.state.destino_externo} onChange={() => this.setState({ destino_externo: true, estoque_interno: false, Solicitacao: '', MaterialSolicitacao: '', Materialselecionado: [] })} />
                          <label className="form-check-label" htmlFor="flexRadioDefault2">
                            Destino Externo
                          </label>
                        </div>
                        {this.state.destino_externo && <div className="form-check form-switch col-3">
                          <input disabled className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" value={this.state.genMtr} onChange={async () => {
                            this.setState({ genMtr: !this.state.genMtr, Solicitacao: '', MaterialSolicitacao: '', Materialselecionado: [] })

                            let token = this.state.sigorToken
                            if (!this.state.sigorToken) {
                              this.setState({ loadingSigor: true })
                              token = await GetToken({ token: this.props.token })
                              this.setState({ sigorToken: token, loadingSigor: false })
                            }
                          }} />
                          <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Gerar MTR ao salvar</label>
                        </div>}
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">{this.state.estoque_interno ? 'Estoque' : 'Destino'}<b style={{ color: 'red' }}>*</b>{this.state.loadingSigor && <CircularProgress className='ms-2' size={13} color='success' />}</label>
                      <div className="col-sm-12">
                        <select value={this.state.Estoque} className="form-control"
                          onChange={this.handleStockChange}>
                          {this.state.estoque_interno &&
                            <><option value={''}>Selecione um estoque</option>
                              {this.state.Estoques.map((item, id) => (<option key={id} value={item.id}>{item.nome_fantasia}</option>))}
                            </>
                          }

                          {this.state.destino_externo &&
                            <>
                              <option value={''}>Selecione um destino</option>
                              {this.state.genMtr && !this.state.loadingSigor
                                ?
                                this.state.DestinosSigor.map((item, id) => (<option key={id} value={item.id}>{item.fantasia}</option>))
                                :
                                this.state.Destinos.map((item, id) => (<option key={id} value={item.id}>{item.fantasia}</option>))}
                            </>
                          }
                        </select>
                      </div>
                    </div>

                    {<><hr />
                      <label className="col-sm-12 col-form-label" style={{ color: 'black', fontSize: '1.5rem' }}>Dados de Solicitação em Aberto:</label>
                      <div className="form-group row">
                        <div className="d-flex align-items-center">
                          <label className="col-form-label">Solicitações<b style={{ color: 'red' }}>*</b></label>

                          <Tooltip className='ms-1' title='Códigos terminados em * possuem ligação com solicitações fixas' arrow>
                            <IconButton>
                              <MdInfo size={20} />
                            </IconButton>
                          </Tooltip>

                          <div className="form-check ms-1">
                            <input className="form-check-input" type="checkbox" name="filterFixedRequests" id="filterFixedRequests" checked={this.state.filterFixedRequests} onChange={() => this.setState({ filterFixedRequests: !this.state.filterFixedRequests })} />
                            <label className="form-check-label" htmlFor="filterFixedRequests">
                              Filtrar solicitações fixas
                            </label>
                          </div>
                        </div>

                        <div className="col-sm-12">
                          <select value={this.state.Solicitacao} className="form-control"
                            onChange={this.handleSolicitationChange}>
                            <option value={''}>Selecione uma opção</option>

                            {this.state.SolicitacoesSelecionadas.map((item, id) => {
                              if (this.state.filterFixedRequests && item.codigo_solicitacao.slice(-1) !== '*') {
                                return
                              }

                              return (
                                <option key={id} value={item.id}> {item.ponto_coleta.nome_fantasia} - {item.codigo_solicitacao}</option>
                              )
                            })}
                          </select>
                        </div>
                      </div>
                      <br />

                      {this.state.SolicitacaoSelecionada.length > 0 && <>
                        <div className="form-group row">
                          <p className="col-sm-6 m-0"> <b className="col-sm-4 ">Dias de funcionamento: </b></p>
                          <div className="row justify-content-center mb-3">
                            {console.log('asdasd', this.state.SolicitacaoSelecionada)}
                            {this.state.SolicitacaoSelecionada[0].ponto_coleta.dia_funcionamento.map(item => (
                              <div className="col-sm-6 col-md-4 col-lg-2" key={item.id}>
                                <div className="operation-card">
                                  <p>{item.dia.toUpperCase()}</p>
                                  {item.horario_funcionamento.map(item2 => (
                                    <div className="hour" key={item2.id}>
                                      <p>{item2.hora_inicio} - {item2.hora_fim}</p>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="form-group row">
                          <p className="col-sm-6 "> <b className="col-sm-4 ">Data da solicitação: </b> {this.state.SolicitacaoSelecionada[0].data.replace(/(\d*)-(\d*)-(\d*).*/, '$3/$2/$1')} </p>
                        </div>
                        <div className="form-group row">
                          <p className="col-sm-6 "><b className="col-sm-4 " >Ponto de coleta:  </b> {this.state.SolicitacaoSelecionada[0].ponto_coleta.nome_fantasia}</p>
                        </div>
                        <div className="form-group row">
                          <p className="col-sm-6 "><b className="col-sm-4 " >Responsável: </b>{this.state.SolicitacaoSelecionada[0].responsavel.name}</p>
                        </div>
                        <div className="form-group row">
                          <p className="col-sm-6 "> <b className="col-sm-4 ">Código da solicitação : </b> {this.state.SolicitacaoSelecionada[0].codigo_solicitacao} </p>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-12 col-form-label">Material<b style={{ color: 'red' }}>*</b></label>
                          <div className="col-sm-12">
                            <select value={this.state.MaterialSolicitacao} className="form-control"
                              onChange={this.handleMaterialChange}>
                              <option value={''}>Selecione uma opção</option>
                              {this.state.genMtr
                                ?
                                this.state.MaterialSolicitacaoSigor.map((item, id) => (<option key={id} value={item.residuo_rejeito_id}>{item.nome}</option>))
                                :
                                this.state.MateriaisSolicitacao.map((item, id) => (<option key={id} value={item.residuo_rejeito_id}>{item.nome}</option>))}
                            </select>
                          </div>
                        </div>
                        {this.state.Materialselecionado.length > 0 && <>

                          {this.state.Materialselecionado[0].unidade_principal.tipo === 'Peso' &&
                            <div className="row">
                              <div className="col-md-6 col-12">
                                <div className="d-flex justify-content-between align-items-center mt-3">
                                  <label className="col-form-label m-0">Peso<b style={{ color: 'red' }}>*</b></label>

                                  {/* {this.state.Peso && this.state.Peso !== this.state.Materialselecionado[0].peso &&
                                    <SwitchInput
                                      title={'Adicionar diferença à nova solicitação'}
                                      handleChange={this.handleNewSolicitation} />} */}
                                </div>
                                <div className="input-group mb-3">
                                  <span className="input-group-text" id="basic-addon3">Solicitado: {this.state.DevolucaoSelecionada.length > 0 ?
                                    (parseFloat(this.state.Materialselecionado[0].peso) + parseFloat(this.state.DevolucaoSelecionada[0].peso))
                                    : this.state.Materialselecionado[0].peso} {this.state.Materialselecionado[0].unidade_principal.sigla}</span>
                                  <NumericFormat className="form-control"
                                    placeholder="Informe o peso" type="text" id="peso"
                                    thousandsGroupStyle='thousand'
                                    thousandSeparator="."
                                    decimalScale={2}
                                    decimalSeparator=","
                                    valueIsNumericString
                                    onValueChange={(values, e) => {
                                      if (values.value == '') {
                                        this.setState({
                                          Peso: values.value,
                                          ValorFinanceiro: '',
                                          Volume: ''
                                        })
                                      } else {
                                        let valor = parseFloat(values.value) * parseFloat(this.state.Materialselecionado[0].valor_saldo_financeiro);
                                        this.setState({
                                          Peso: values.value,
                                          ValorFinanceiro: valor.toFixed(2),
                                          Volume: parseFloat(values.value) * parseFloat(this.state.Materialselecionado[0].equivalencia),
                                          validated: false,
                                          required: false
                                        })
                                      }
                                    }}
                                    value={this.state.Peso} />

                                </div>
                                {this.state.validated === true && <b style={{ color: 'red', fontSize: '12px' }}>O peso atendido não pode ser maior que o solicitado</b>}
                                {this.state.required === true && <b style={{ color: 'red', fontSize: '12px' }}>O campo Peso é obrigatório</b>}
                              </div>
                              <div className="col-md-6 col-12">
                                <label className="col-sm-12 col-form-label">Volume<b style={{ color: 'red' }}>*</b></label>
                                <div className="input-group mb-3">
                                  <span className="input-group-text" id="basic-addon3">Solicitado: {this.state.DevolucaoSelecionada.length > 0 ?
                                    (parseFloat(this.state.Materialselecionado[0].volume) + parseFloat(this.state.DevolucaoSelecionada[0].volume))
                                    : this.state.Materialselecionado[0].volume} {this.state.Materialselecionado[0].unidade_secundaria.sigla} </span>
                                  <NumericFormat className="form-control"
                                    disabled placeholder="Informe o volume" id="volume" type="text"
                                    thousandsGroupStyle='thousand'
                                    thousandSeparator="."
                                    decimalScale={2}
                                    decimalSeparator=","
                                    valueIsNumericString
                                    value={this.state.Volume} />
                                </div>
                              </div>
                            </div>}

                          {this.state.Materialselecionado[0].unidade_principal.tipo !== 'Peso' &&
                            <div className="row">
                              <div className="col-md-6 col-12">
                                <div className="d-flex justify-content-between align-items-center mt-3">
                                  <label className="col-form-label m-0">Volume<b style={{ color: 'red' }}>*</b></label>

                                  {this.state.Volume && this.state.Volume !== this.state.Materialselecionado[0].volume &&
                                    <SwitchInput
                                      title={'Adicionar diferença à nova solicitação'}
                                      handleChange={this.handleNewSolicitation} />}
                                </div>
                                <div className="input-group mb-3">
                                  <span className="input-group-text" id="basic-addon3">Solicitado: {this.state.DevolucaoSelecionada.length > 0 ?
                                    (parseFloat(this.state.Materialselecionado[0].volume) + parseFloat(this.state.DevolucaoSelecionada[0].volume))
                                    : this.state.Materialselecionado[0].volume} {this.state.Materialselecionado[0].unidade_principal.sigla} </span>
                                  <NumericFormat className="form-control"
                                    placeholder={`${this.state.Materialselecionado[0].unidade_principal.tipo === 'Volume' ? 'Informe o' : 'Informe a'} ${this.state.Materialselecionado[0].unidade_principal.tipo.toLowerCase()}`}
                                    id="volume"
                                    type="text"
                                    thousandsGroupStyle='thousand'
                                    thousandSeparator="."
                                    decimalScale={2}
                                    decimalSeparator=","
                                    valueIsNumericString
                                    value={this.state.Volume}
                                    onValueChange={(values, e) => {
                                      if (values.value == '') {
                                        this.setState({
                                          Volume: values.value,
                                          ValorFinanceiro: '',
                                          Peso: ''
                                        })
                                      } else {
                                        let valor = parseFloat(values.value) * parseFloat(this.state.Materialselecionado[0].valor_saldo_financeiro);
                                        this.setState({
                                          Volume: values.value,
                                          ValorFinanceiro: valor.toFixed(2),
                                          Peso: parseFloat(values.value) * parseFloat(this.state.Materialselecionado[0].equivalencia),
                                          validated: false,
                                          required: false
                                        })
                                      }
                                    }} />
                                </div>
                                {this.state.validated === true && <b style={{ color: 'red', fontSize: '12px' }}>O volume atendido não pode ser maior que o solicitado</b>}
                                {this.state.required === true && <b style={{ color: 'red', fontSize: '12px' }}>O campo Volume é obrigatório</b>}
                              </div>
                              <div className="col-md-6 col-12">
                                <label className="col-sm-12 col-form-label">Peso<b style={{ color: 'red' }}>*</b></label>
                                <div className="input-group mb-3">
                                  <span className="input-group-text" id="basic-addon3">Solicitado: {this.state.DevolucaoSelecionada.length > 0 ?
                                    (parseFloat(this.state.Materialselecionado[0].peso) + parseFloat(this.state.DevolucaoSelecionada[0].peso))
                                    : this.state.Materialselecionado[0].peso} {this.state.Materialselecionado[0].unidade_secundaria.sigla}</span>
                                  <NumericFormat className="form-control"
                                    disabled placeholder="Informe o peso" id="peso" type="text"
                                    thousandsGroupStyle='thousand'
                                    thousandSeparator="."
                                    decimalScale={2}
                                    decimalSeparator=","
                                    valueIsNumericString
                                    value={this.state.Peso} />
                                </div>
                              </div>
                            </div>}

                          <div className="row">
                            <div className="col-md-6 col-12">
                              <label className="col-sm-12 col-form-label">Tipo de movimentação<b style={{ color: 'red' }}>*</b></label>
                              <div className="input-group mb-3">
                                <select value={this.state.Movimentacao} className="form-control"
                                  onChange={(e) => {
                                    if (e.target.value === 'Outros') {
                                      this.setState({
                                        Outros: true,
                                        Movimentacao: 'Não Aplicável'
                                      })
                                    } else {
                                      this.setState({
                                        Movimentacao: e.target.value,
                                      })
                                    }
                                  }}>
                                  <option value={'Outros'}>Outros</option>
                                  <option value={'Receita'}>Receita</option>
                                  <option value={'Despesa'}>Despesa</option>

                                </select>
                              </div>
                            </div>
                            {this.state.Outros === true &&
                              <div className="col-md-6 col-12">
                                <label className="col-sm-12 col-form-label">Outros<b style={{ color: 'red' }}>*</b></label>
                                <div className="input-group mb-3">
                                  <select value={this.state.Movimentacao} className="form-control"
                                    onChange={(e) => {
                                      this.setState({
                                        Movimentacao: e.target.value
                                      })
                                    }}>
                                    <option value={'Não Aplicável'}>Não Aplicável</option>
                                    <option value={'Doação'}>Doação</option>
                                    <option value={'Despesa Evitada'}>Despesa Evitada</option>
                                  </select>
                                </div>
                              </div>}
                            {this.state.Outros === false && <div className="col-md-6 col-12">
                              <label className="col-sm-12 col-form-label">Valor financeiro<b style={{ color: 'red' }}>*</b></label>
                              <div className="input-group mb-3">
                                <NumericFormat className="form-control"
                                  placeholder="R$ 99,99" type="text"
                                  prefix={'R$ '}
                                  thousandsGroupStyle='thousand'
                                  thousandSeparator="."
                                  decimalScale={2}
                                  decimalSeparator=","
                                  valueIsNumericString
                                  onValueChange={(values, e) =>
                                    this.setState({ ValorFinanceiro: values.value })}
                                  value={this.state.ValorFinanceiro} />
                              </div>
                            </div>}
                            {this.state.Outros === true && <div className="col-md-6 col-12">
                              <label className="col-sm-12 col-form-label">Valor financeiro<b style={{ color: 'red' }}>*</b></label>
                              <div className="input-group mb-3">
                                <NumericFormat className="form-control"
                                  disabled placeholder="R$ 99,99" type="text"
                                  prefix={'R$ '}
                                  thousandsGroupStyle='thousand'
                                  thousandSeparator="."
                                  decimalScale={2}
                                  decimalSeparator=","
                                  valueIsNumericString
                                  onValueChange={(values, e) =>
                                    this.setState({ ValorFinanceiro: values.value })}
                                  value={maskPrice('0.00')} />
                              </div>
                            </div>}
                          </div>

                          {this.state.destino_externo &&
                            <div className="row">
                              <div className="col-md-6 col-12">
                                <label className="col-sm-12 col-form-label">Destinação<b style={{ color: 'red' }}>*</b></label>
                                <div className="input-group mb-3">
                                  <select value={this.state.DestinacaoId} className="form-control"
                                    onChange={(e) => {
                                      this.setState({
                                        DestinacaoId: e.target.value,
                                        destinacaoError: false
                                      })
                                    }}>
                                    <option value={''}>Selecione uma opção</option>
                                    {this.state.Destinacoes.map((item, id) => (<option key={id} value={item.id}>{'[' + item.codigo + '] ' + item.descricao}</option>))}
                                  </select>
                                </div>
                                {this.state.destinacaoError && <b style={{ color: 'red', fontSize: '12px' }}>O campo Destinação é obrigatório</b>}
                              </div>
                              <div className="col-md-6 col-12">
                                <label className="col-sm-12 col-form-label">Tratamento<b style={{ color: 'red' }}>*</b></label>
                                <div className="input-group mb-3">
                                  <select disabled={this.state.genMtr} value={this.state.Tratamento} className="form-control"
                                    onChange={(e) => {
                                      this.setState({
                                        Tratamento: e.target.value,
                                        tratamentoError: false
                                      })
                                    }}>
                                    <option value={''}>Selecione uma opção</option>
                                    {this.state[this.state.genMtr ? 'TratamentosSigor' : 'Tratamentos'].map((item, id) => (<option key={id} value={item.id}>{'[' + item.codigo + '] ' + item.descricao}</option>))}
                                  </select>
                                </div>
                                {this.state.tratamentoError && <b style={{ color: 'red', fontSize: '12px' }}>O campo Tratamento é obrigatório</b>}
                              </div>
                            </div>
                          }



                          <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                            <button type="button" className="btn btn-success" style={{ backgorundColor: '#f2f2f2' }} onClick={this.handleAddMaterials}>Inserir</button>
                          </div>
                        </>}
                      </>}
                    </>}
                    <hr />

                    {this.state.MateriaisInseridos.length > 0 &&
                      <div className="form-group row">
                        <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Materiais selecionados:</label>

                        <table id="tech-companies-1" className="table table-striped table-hover" >
                          <thead>
                            <tr>
                              <th>Material</th>
                              <th>Peso</th>
                              <th>Volume</th>
                              <th>Ponto Coleta</th>

                              <th data-priority="3">Excluir</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.MateriaisInseridos.map((item) => (
                              <tr key={item.residuo_rejeito_id + '_' + item.solicitacao_id}>
                                <td>{item.nome}</td>
                                <td>{maskNumberBr(item.peso + '')}  {item.tipo_unidade_principal === 'Peso' ? item.unidade_principal : item.unidade_secundaria}</td>
                                <td>{maskNumberBr(item.volume + '')} {item.tipo_unidade_principal !== 'Peso' ? item.unidade_principal : item.unidade_secundaria}</td>
                                <td>{item.ponto_name}</td>

                                <td>
                                <button 
                                onClick={()=>{
                                  if(this.state.Estoque==''){
                                    this.setState({show_warning:true})
                                    return;
                                  }
                                  console.log(item)
                                    this.handleDeleteMaterial(item,(e)=>{
                                      this.handleSolicitationChange({target:{value:item.solicitacao_id}},()=>{
                                        this.handleMaterialChange({target:{value:item.residuo_rejeito_id}});
                                      })
                                    })
                                  
                                }}
                                style={{ background: 'white', border: '0px solid #38a4f8' }}
                                className="btn btn-info btn-outline btn-circle me-1 mb-1"
                                                    data-tip="Editar"
                                                ><MdModeEdit color='#38a4f8' /></button>
                                  <button className="btn btn-danger btn-outline btn-circle m-r-5" style={{ background: 'white', border: '0px solid red' }}><FaTrash color='red' onClick={() => this.handleDeleteMaterial(item)} /></button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>}

                    {this.state.MateriaisInseridos.length > 0 &&
                      <div className="form-group row mt-3">
                        <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Solicitações selecionadas:</label>

                        <table id="tech-companies-1" className="table table-striped table-hover" >
                          <thead>
                            <tr>
                              <th>Código</th>
                              <th>Ponto Coleta</th>
                              <th>Responsável</th>
                              <th>Materiais</th>
                              <th>Pendente</th>
                              <th className='no-wrap'>Criar nova
                                {<Tooltip className='p-0 m-0' title='Cria uma nova solicitação de coleta com os registros de materiais pendentes não adicionados no atual despacho/destinação' arrow placement='top'>
                                  {<IconButton className='p-0 ms-2'><MdInfo /></IconButton>}
                                </Tooltip>}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.reduceSolicitationsGroup(this.state.MateriaisInseridos).map(item => (
                              <tr key={item.id}>
                                <td>{item.codigo_solicitacao}</td>
                                <td>{item.ponto_coleta.nome_fantasia}</td>
                                <td>{item.responsavel.name}</td>
                                <td>{maskLimitedText(item.material.map(object => object.nome))}</td>
                                <td>
                                  <span className={`no-wrap ${this.state.checkError === item.id ? 'animated-text-error' : ''}`}>
                                    {maskNumberBr(item.total) + ' Kg'}
                                  </span>
                                </td>
                                <td>
                                  <div className="d-flex justify-content-center">
                                    <input
                                      className='form-check-input pointer'
                                      type='checkbox'
                                      checked={item.checked}
                                      onChange={({ target }) => this.handleNewSolicitation(target.checked, item)} />
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>}

                    <label className="col-sm-12 col-form-label" style={{ color: 'black', fontSize: '1.5rem' }}>Dados do despacho:</label>
                    <div className="form-group row">
                      <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Situação: </label>{this.state.Situacao}</div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-12"> <label className="col-sm-4 col-form-label">Data da criação: </label>{this.state.DataAtual.replace(/(\d*)-(\d*)-(\d*).*/, '$3/$2/$1')} </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">Agendado para: </label>
                      <div className="col-sm-12">
                        <input disabled={this.state.SolicitacaoSelecionada.length==0} className="form-control" value={this.state.DataAgendamento} type="date" style={{ maxWidth: 168 }} min={this.state.DataAtual} onChange={this.handleScheduledChange} />
                        <span className='small' style={{ color: 'red' }}>{this.state.operationError}</span>
                      </div>
                    </div>

                    {this.state?.HorariosDisponiveis?.length > 0 &&
                      <>
                        <label className="col-sm-12 col-form-label">Selecione um horário: </label>
                        {this.state.HorariosDisponiveis.map(item => (
                          <div key={item.id}>
                            <input id={item.id} type="radio" className='form-check-input me-1' onChange={() => this.setState({ idHorarioSelecionado: item.id })} checked={this.state.idHorarioSelecionado === item.id ? true : false} />
                            <label className='pointer' htmlFor={item.id}>{item.hora_inicio} - {item.hora_fim}</label>
                          </div>
                        ))}
                      </>
                    }

                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">Iniciado em:</label>
                      <div className="col-sm-12">
                        <input className="form-control" value={this.state.DataInicio} type="date" style={{ maxWidth: 168 }} min={this.state.DataAtual} max={this.state.DataAtual} onChange={(e) => {
                          if (e.target.value !== '' && e.target.value !== this.state.DataAtual) {
                            this.setState({ DataInicio: this.state.DataAtual })
                          }
                          if (e.target.value !== '' && e.target.value === this.state.DataAtual) {
                            this.setState({ Situacao: 'Iniciado', DataInicio: e.target.value })
                          }
                          if (e.target.value === '' && this.state.DataAgendamento !== '') {
                            this.setState({ Situacao: 'Agendado', DataInicio: '' })
                          }
                          if (e.target.value === '' && this.state.DataAgendamento === '') {
                            this.setState({ Situacao: 'Rascunho', DataInicio: '' })
                          }
                        }} />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Peso total: </label>{maskNumberBr(this.state.PesoTotal)} Kg</div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Volume total: </label>{maskNumberBr(this.state.VolumeTotal)} L</div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Receita: </label>{moneyMask2(this.state.ReceitaTotal.toString())}</div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Despesas: </label>{moneyMask2(this.state.DespesaTotal.toString())}</div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Saldo: </label>{moneyMask2(this.state.Saldo.toString())}</div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">Transportadora<b style={{ color: 'red' }}>*</b></label>
                      <div className="col-sm-12 input-group">
                        <select value={this.state.TransportadoraId} className="form-control"
                          onChange={this.handleCarrierChange}>
                          <option value={''}>Sem transportadora</option>
                          {this.state.genMtr
                            ?
                            this.state.TransportadorasSigor.map((item, id) => (<option key={id} value={item.id}>{item.nome_fantasia}</option>))
                            :
                            this.state.Transportadoras.map((item, id) => (<option key={id} value={item.id}>{item.nome_fantasia}</option>))}


                        </select>
                        <TransportadoraModal updateState={this.updateState} />
                      </div>
                    </div>
                    <hr />
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">Veículo<b style={{ color: 'red' }}>*</b></label>
                      <div className="col-sm-12 input-group">
                        <select value={this.state.VeiculoId} className="form-control" onChange={this.handleVehicleChange}>
                          <option value={''}>Selecione uma opção</option>
                          {this.state.Veiculos.filter((item, id) => {
                            if (item.transportadora_id == null && this.state.TransportadoraId === '') return true;
                            else if (item.transportadora_id === this.state.TransportadoraId) return true;
                            else return false;
                          }).map((item, id) => {
                            return (<option key={id} value={item.id}>{item.modelo.nome}</option>)
                          })}
                        </select>
                        <VeiculoModal updateState={this.updateState} transportadoraId={this.state.TransportadoraId} />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">Motorista<b style={{ color: 'red' }}>*</b></label>
                      <div className="col-sm-12 input-group">
                        <select value={this.state.MotoristaId} className="form-control"
                          onChange={(e) => { this.setState({ MotoristaId: e.target.value }) }}>
                          <option value={''}>Selecione uma opção</option>
                          {this.state.VeiculoId !== '' && this.state.Motoristas.filter((item, id) => {
                            if (this.proprio === true) {
                              if (item.veiculo_id === this.state.VeiculoId) {
                                return true;
                              }
                            }
                            else {
                              if (item.transportadora_id === this.state.TransportadoraId) return true;
                              else return false;
                            }
                            return item

                          }).map((item, id) => (<option key={id} value={item.id}>{item.nome}</option>))}
                        </select>
                        <MotoristaModal updateState={this.updateState} />
                        {this.state.msgErroMotoristaVeiculo !== '' && <b style={{ color: 'red', fontSize: '12px' }}>{this.state.msgErroMotoristaVeiculo}</b>}

                      </div>
                    </div>
                    <div className="d-grid gap-2 d-md-flex justify-content-md-end mt-2">
                      <button type="button" disabled={this.state.VeiculoId === '' || this.state.MotoristaId === ''} className="btn btn-success" style={{ backgorundColor: '#f2f2f2' }} onClick={this.handleTransportChange}>Inserir</button>
                    </div>

                    {
                      <div className="form-group row">
                        <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Veículos/Motoristas selecionados:</label>

                        <table id="tech-companies-1" className="table table-striped table-hover" >
                          <thead>
                            <tr>
                              <th>Veículo</th>
                              <th>Motorista</th>
                              <th>Capacidade Peso</th>
                              <th>Capacidade Volume</th>

                              <th data-priority="3">Excluir</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.veiculos_motoristas.length === 0 && <tr>
                              <td colSpan="5" style={{ textAlign: 'center' }}>Nenhum Veículo/Motorista registrado</td>
                            </tr>}
                            {this.state.veiculos_motoristas.map((item, id) => (
                              <tr key={id}>
                                <td>{item.veiculo.modelo.nome}</td>
                                <td>{item.motorista.nome}</td>
                                <td>{maskNumberBr(item.veiculo.capacidade_maxima_peso)}  {item.veiculo.unidade_peso.sigla}</td>
                                <td>{maskNumberBr(item.veiculo.capacidade_maxima_volume)}  {item.veiculo.unidade_volume.sigla}</td>
                                <td>
                                  <button className="btn btn-danger btn-outline btn-circle m-r-5" style={{ background: 'white', border: '0px solid red', zIndex: '2 !important' }}><FaTrash color='red' onClick={() => this.handleDeleteTransport(item, id)} /></button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <div className="col-12">
                          {((parseFloat(this.state.PesoTotal) > parseFloat(this.state.PesoTotalVeiculo)) || (parseFloat(this.state.VolumeTotal) > parseFloat(this.state.VolumeTotalVeiculo))) && <b style={{ color: 'red', fontSize: '12px' }}>O Peso e Volume total da capacidade dos veículos deve ser maior que o Peso e Volume total de materiais</b>}
                        </div>
                      </div>}
                    <div className="form-group row">
                      <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Peso total Veículo(s): </label>{maskNumberBr(parseFloat(this.state.PesoTotalVeiculo).toFixed(0))} Kg</div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Volume total Veículo(s): </label>{maskNumberBr(parseFloat(this.state.VolumeTotalVeiculo).toFixed(0))} L</div>
                    </div>

                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">Observações </label>
                      <div className="col-sm-12">
                        <textarea className="form-control" placeholder="Observações" value={this.state.Descricao} type="text" onChange={(e) => { this.setState({ Descricao: e.target.value }) }} />
                      </div>
                    </div>

                    {this.state.destino_externo &&
                      <div>
                        <div className="form-group row">
                          <label className="col-sm-12 col-form-label">Chave da NF-e</label>
                          <div className="col-sm-12">
                            <input className="form-control" placeholder="Chave de acesso da nota fiscal" value={this.state.ChaveNota} type="text" onChange={(e) => { this.setState({ ChaveNota: e.target.value }) }} />
                          </div>
                        </div>
                        <div className="form-group  row">
                          <div className=" col-sm-12">
                            <div className="row">
                              <div className="col-md-8 col-12">
                                <label className="col-sm-12 col-form-label">Número da NF-e </label>
                                <div className="col-sm-12">
                                  <input className="form-control" placeholder="Número da nota fiscal" type="text" onChange={(e) => this.setState({ NumeroNota: e.target.value })} value={this.state.NumeroNota} />
                                </div>
                              </div>
                              <div className="col-md-4 col-12">
                                <label className="col-sm-12 col-form-label">Série da NF-e </label>
                                <div className="col-sm-12">
                                  <input className="form-control" placeholder="Série da nota fiscal" type="text" onChange={(e) => this.setState({ SerieNota: e.target.value })} value={this.state.SerieNota} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <FileInput
                          label='Clique aqui para adicionar um anexo fiscal'
                          fileLabel='Anexo da NF-e:'
                          acceptedFiles='application/pdf,application/vnd.ms-excel'
                          callback={(file) => this.setState({ AnexoNota: file })}
                          filePath={this.state.AnexoNota}
                        />
                      </div>}
                  </div>
                </div>


                {this.state.loading_save === false && <div className="row mt-3">
                  <div className="col-12 col-sm-4 d-flex justify-content-center justify-content-sm-start"
                    style={{ marginBottom: '0.5rem' }}>
                    <Link to="/acompanhar_etapas"> <span
                      className="btn btn-secondary btn-lg waves-effect waves-light"><MdReply /> Voltar</span></Link>
                  </div>
                  <div className="col-12 col-sm-8 d-flex justify-content-center justify-content-sm-end"
                    style={{ marginBottom: '0.5rem' }}>
                      {this.state.DespachoAtual?.rotas_path != null && <a target="_blank" href={URL + 'storage/' + this.state.DespachoAtual?.rotas_path} className="btn btn-success btn-lg waves-effect waves-light justify-content-sm-end"
                                            style={{ marginLeft: '5px' }}>Visualizar Rota </a>}
                                        {this.state.loading_rota_despacho == false && this.state.DespachoAtual?.rotas_path == null && <button onClick={() => this.gerar_rota()} className="btn btn-success btn-lg waves-effect waves-light justify-content-sm-end"
                                            style={{ marginLeft: '5px' }}>Gerar Rota </button>}
                                            {this.state.loading_rota_despacho == true && <div className="spinner-border text-primary" role="status"  >
                                              </div>}
                    <button disabled={updateCondition} type="button" onClick={() => this.salvar()} className="btn btn-success btn-lg waves-effect waves-light"
                      style={{ backgorundColor: '#f2f2f2', marginLeft: '5px' }}>Salvar despacho</button>
                  </div>
                </div>}
                {this.state.loading_save === true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                  <div className="spinner-border text-primary" role="status"  >
                  </div>
                </div></div>}
              </div>}

              {this.state.loading_screen === true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                <div className="spinner-border text-primary" role="status"  >
                </div>
              </div></div>}

              <SweetAlert
                warning
                title={"Atenção"}
                onConfirm={() => { this.setState({ Materialselecionado: {} }, () => this.salvar()) }}
                onCancel={() => { this.setState({ show_warning_inserir: false }); }}
                show={this.state.show_warning_inserir}
                confirmBtnText='Sim, desejo salvar '
                cancelBtnText='Cancelar'
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                showCancel={true}
              >
                Existe um material selecionado que não foi inserido, deseja salvar mesmo assim ?
              </SweetAlert>

              <ToastContainer
                position="bottom-right"
                toastStyle={{ border: '1px solid gray', borderRadius: 4 }}
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnHover
              />
            </div>

          </div>
        </div>
      </div>
    );
  }

  gerar_rota() {
    this.setState({ loading_rota_despacho: true });
    fetch(`${URL}api/gerar_rota_despacho/${this.props.params.id}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Authorization': `Bearer ${this.props.token}`,
            'Content-Type': 'application/json',
        },
    }).then(async (responseLog) => {
        try {
            let resp = await responseLog.json();
            console.log(resp)
            if (resp.errors != null || resp.error != null) {
                let errors = Object.values(resp.errors);
                let erro = '';
                for (let i = 0; i < errors.length; i++) {
                    if (i != errors.length - 1)
                        erro += errors[i] + '\n';
                    else
                        erro += errors[i];
                }
                this.timeout = setTimeout(() => {
                    this.gerar_rota();
                }, 5000);
            }
            else {

                console.log(resp.despacho)
                this.setState({ loading_rota_despacho: false, DespachoAtual: resp.despacho });

            }

        } catch (err) {
            console.log(err);
            this.timeout = setTimeout(() => {
                this.gerar_rota();
            }, 5000);
        }

    })
        .catch((err) => {
            console.log(err);
            this.timeout = setTimeout(() => {
                this.gerar_rota();
            }, 5000);
        });

}


}
const mapsStateToProps = (state) => (
  {
    token: state.AppReducer.token,
  }
);

export default connect(mapsStateToProps, { logout })(EditarDespacho);


