
import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { logout } from '../../../actions/AppActions';
import { connect } from 'react-redux';

// users
import { URL } from '../../../variables';
import { maskNumber, maskNumberBr, maskPrice } from '../../../Auxiliar/Masks';

import { MdReply } from 'react-icons/md';
import { FaTrash } from 'react-icons/fa';
import SweetAlert from 'react-bootstrap-sweetalert';
import jsPDF from 'jspdf';
import { NumericFormat } from 'react-number-format';

class EditarDestinacao extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/destinacao',
            loading_screen: false,
            ResiduosRejeitos: [],
            Destinos: [],
            DestinoSelecionado: [],
            Solicitacoes: [],
            SolicitacoesSelecionadas: [],
            MateriaisSolicitacao: [],
            Destinacoes: [],
            Tratamentos: [],
            MateriaisInseridos: [],
            Transportadoras: [],
            Veiculos: [],
            Motoristas: [],
            Devolucao: [],

            DevolucaoSelecionada: {},
            DestinacaoAtual: {},
            Materialselecionado: {},
            SolicitacaoSelecionada: {},

            ChaveNota: '',
            NumeroNota: '',
            SerieNota: '',
            TransportadoraId: '',
            DestinacaoId: '',
            Situacao: 'Rascunho',
            Descricao: '',
            Tratamento: '',
            ValorFinanceiro: '',
            Movimentacao: '',
            Outros: false,
            Peso: '',
            Volume: '',
            MaterialSolicitacao: '',
            Solicitacao: '',
            Destino: '',
            DataAtual: '',
            DataSolicitacao: '',
            DataAgendamento: '',
            DataInicio: '',
            DataFinalizado: '',
            DataCriacao: '',
            PesoTotal: '0',
            VolumeTotal: '0',
            ReceitaTotal: '0.00',
            DespesaTotal: '0.00',
            Saldo: '0.00',
            MotoristaId: '',
            VeiculoId: '',
            loading_save: false,
            msgErro: '',
            show_warning: false,
            show_warning_inserir: false

        };
    }


    get_data(id) {
        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/get_editar_destinacao/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });
                }
                else {
                    console.log(resp.destinacao.materiais);
                    let destino_sel = resp.destinos.filter(d => d.id == resp.destinacao.destino_id);
                    let sol_sel = [];
                    for (let i = 0; i < resp.solicitacoes.length; i++) {
                        for (let j = 0; j < resp.solicitacoes[i].material.length; j++) {
                            if (destino_sel[0].residuos_rejeitos.length > 0) {
                                for (let k = 0; k < destino_sel[0].residuos_rejeitos.length; k++) {
                                    if (resp.solicitacoes[i].material[j].residuo_rejeito_id == destino_sel[0].residuos_rejeitos[k].id) {

                                        sol_sel.push(resp.solicitacoes[i]);
                                        k = destino_sel[0].residuos_rejeitos.length;
                                        j = resp.solicitacoes[i].material.length;
                                    }

                                }
                            }
                        }
                    }
                    let solicitacao = sol_sel.filter(item => item.id == resp.destinacao.solicitacao_id);
                    console.log(solicitacao);
                    let materiais = solicitacao[0].material;


                    this.setState({
                        loading_screen: false,
                        Destinos: resp.destinos,
                        Solicitacoes: resp.solicitacoes,
                        Destinacoes: resp.destinacoes,
                        ResiduosRejeitos: resp.materiais,
                        Tratamentos: resp.tratamentos,
                        Transportadoras: resp.transportadoras,
                        Veiculos: resp.veiculos,
                        Motoristas: resp.motoristas,

                        DestinacaoAtual: resp.destinacao,
                        TransportadoraId: resp.destinacao.transportadora_id,
                        Situacao: resp.destinacao.situacao,
                        Descricao: resp.destinacao.descricao != null ? resp.destinacao.descricao : '',
                        Solicitacao: resp.destinacao.solicitacao_id,
                        Destino: resp.destinacao.destino_id,
                        DataSolicitacao: resp.destinacao.data_solicitacao,
                        DataAgendamento: resp.destinacao.data_agendamento != null ? resp.destinacao.data_agendamento : '',
                        DataInicio: resp.destinacao.data_inicio != null ? resp.destinacao.data_inicio : '',
                        PesoTotal: resp.destinacao.peso_total,
                        VolumeTotal: resp.destinacao.volume_total,
                        ReceitaTotal: resp.destinacao.receita_total,
                        DespesaTotal: resp.destinacao.despesa_total,
                        Saldo: resp.destinacao.saldo,
                        MotoristaId: resp.destinacao.motorista_id,
                        VeiculoId: resp.destinacao.veiculo_id,
                        MateriaisInseridos: resp.destinacao.materiais.map((item, id) => {
                            item.solicitacao_id = solicitacao[0].id
                            console.log(item)
                            return item;
                        }),
                        ChaveNota: resp.destinacao.chave_nota != null ? resp.destinacao.chave_nota : '',
                        NumeroNota: resp.destinacao.numero_nota != null ? resp.destinacao.numero_nota : '',
                        SerieNota: resp.destinacao.serie_nota != null ? resp.destinacao.serie_nota : '',
                        SolicitacoesSelecionadas: sol_sel,
                        SolicitacaoSelecionada: [resp.destinacao.solicitacao_coleta],
                        DestinoSelecionado: resp.destinacao.destino,
                        MateriaisSolicitacao: materiais,
                        DataCriacao: resp.destinacao.data_criacao,
                    });
                }
            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);
            }
        }).catch((err) => {
            this.setState({ redirect: true });

            console.log(err);
        });
    }



    salvar() {
        console.log(this.state.Materialselecionado)
        if (this.state.Materialselecionado.length > 0) {

            this.setState({ show_warning_inserir: true, msgErro: '' })
        } else {
            this.setState({ show_warning_inserir: false, loading_save: true, msgErro: '' });
            fetch(`${URL}api/update_destinacao`, {
                method: 'PATCH',
                headers: {
                    Accept: 'application/json',
                    'Authorization': `Bearer ${this.props.token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    destinacao_coleta_id: this.props.match.params.id,
                    solicitacao_id: this.state.Solicitacao,
                    destino_id: this.state.Destino,
                    data_criacao: this.state.DataCriacao,
                    data_solicitacao: this.state.DataSolicitacao,
                    data_agendamento: this.state.DataAgendamento,
                    data_inicio: this.state.DataInicio,
                    peso_total: this.state.PesoTotal,
                    volume_total: this.state.VolumeTotal,
                    receita_total: this.state.ReceitaTotal,
                    despesa_total: this.state.DespesaTotal,
                    saldo: this.state.Saldo,
                    motorista_id: this.state.MotoristaId,
                    veiculo_id: this.state.VeiculoId,
                    descricao: this.state.Descricao,
                    situacao: this.state.Situacao,
                    transportadora_id: this.state.TransportadoraId,
                    materiais_coletados: this.state.MateriaisInseridos,
                    chave_nota: this.state.ChaveNota,
                    numero_nota: this.state.NumeroNota,
                    serie_nota: this.state.SerieNota,
                })
            }).then(async (responseLog) => {
                try {
                    let resp = await responseLog.json();
                    if (resp.errors != null || resp.error != null) {
                        let errors = Object.values(resp.errors);
                        let erro = '';
                        for (let i = 0; i < errors.length; i++) {
                            if (i != errors.length - 1)
                                erro += errors[i] + '\n';
                            else
                                erro += errors[i];
                        }
                        console.log(erro);
                        window.scrollTo(500, 0);
                        this.setState({ loading_save: false, msgErro: erro });
                    }
                    else {

                        this.setState({ loading_save: false, redirect: true });

                    }

                } catch (err) {
                    console.log(err);
                    this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

                }

            })
                .catch((err) => {
                    console.log(err);
                    this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
                });
        }

    }


    finalizar() {
        this.setState({ loading_save: true, msgErro: '' });

        fetch(`${URL}api/finalizar_destinacao`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                destinacao_coleta_id: this.props.match.params.id,
                solicitacao_id: this.state.Solicitacao,
                destino_id: this.state.Destino,
                data_criacao: this.state.DataCriacao,
                data_solicitacao: this.state.DataSolicitacao,
                data_agendamento: this.state.DataAgendamento,
                data_inicio: this.state.DataInicio,
                data_finalizado: this.state.DataFinalizado,
                peso_total: this.state.PesoTotal,
                volume_total: this.state.VolumeTotal,
                receita_total: this.state.ReceitaTotal,
                despesa_total: this.state.DespesaTotal,
                saldo: this.state.Saldo,
                motorista_id: this.state.MotoristaId,
                veiculo_id: this.state.VeiculoId,
                descricao: this.state.Descricao,
                situacao: 'Finalizada',
                transportadora_id: this.state.TransportadoraId,
                materiais_coletados: this.state.MateriaisInseridos,
                chave_nota: this.state.ChaveNota,
                numero_nota: this.state.NumeroNota,
                serie_nota: this.state.SerieNota,
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    window.scrollTo(500, 0);
                    this.setState({ loading_save: false, msgErro: erro });
                }
                else {

                    this.setState({ loading_save: false, redirect: true });

                }

            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });

    }



    Pdf() {

        const doc = new jsPDF();

        doc.text('MTR – MANIFESTO PARA TRANSPORTE DE RESÍDUOS/REJEITOS', 15.8, 10)
        console.log(this.state.SolicitacaoSelecionada);
        let ponto_coleta = this.state.SolicitacaoSelecionada[0].ponto_coleta;
        console.log(ponto_coleta);
        doc.autoTable({
            theme: 'striped',
            tableLineWidth: 0.2,
            columnStyles: {
                titulo: { fontStyle: 'bold', textColor: 'black', cellWidth: 35, halign: 'right' },
                texto: { fontStyle: 'normal', halign: 'left' }
            },
            tableLineColor: 'black',
            headStyles: { fontSize: 10, textColor: 'white', fillColor: [22, 160, 133], lineColor: 'black' },
            bodyStyles: { fontSize: 10, lineWidth: 0 },
            columns: [
                { header: '1. GERADOR', dataKey: 'titulo' },
                { dataKey: 'texto' },
            ],
            body: [{ titulo: 'Razão social:', texto: ponto_coleta.nome_fantasia },
            { titulo: 'Endereço:', texto: ponto_coleta.logradouro + ', ' + ponto_coleta.numero + ' - ' + ponto_coleta.bairro + ', ' + ponto_coleta.cidade + '-' + ponto_coleta.estado },
            { titulo: 'Telefone:', texto: ponto_coleta.responsavel.user_dado.telefone },
            { titulo: 'E-mail:', texto: ponto_coleta.responsavel.email },
            { titulo: 'Responsável:', texto: ponto_coleta.responsavel.name }]
        });

        var mat_inserido = [];
        var mat_data = [];
        for (let i = 0; i < this.state.MateriaisInseridos.length; i++) {
            for (let j = 0; j < this.state.ResiduosRejeitos.length; j++)
                if (this.state.ResiduosRejeitos[j].id == this.state.MateriaisInseridos[i].residuo_rejeito_id) {
                    mat_data.push(this.state.ResiduosRejeitos[j]);
                    j = this.state.ResiduosRejeitos.length;
                }
        }
        if (this.state.MateriaisInseridos.length == 1) {
            mat_inserido = [{
                name: mat_data[0].nome,
                ef: mat_data[0].estado_fisico.descricao,
                ac: mat_data[0].acondicionamento.descricao,
                cl: mat_data[0].classe.nome,
                qt: this.state.MateriaisInseridos[0].peso_kg,
                un: 'Kg'
            },
            { name: 'TOTAL', ef: '', ac: '', cl: '', qt: this.state.PesoTotal, un: 'Kg' },]
        } else if (this.state.MateriaisInseridos.length < 1) {
            mat_inserido = [{ name: '', id: '' }, { name: '', id: '' },];
        } else {
            mat_inserido = mat_data.map(item => ({
                name: item.nome, ef: item.estado_fisico.descricao, ac: item.acondicionamento.descricao, cl: item.classe.nome,
                qt: this.state.MateriaisInseridos.filter(item2 => item2.residuo_rejeito_id == item.id).map(item3 => item3.peso_kg), un: 'Kg'
            }));
            mat_inserido.push({ name: 'TOTAL', ef: '', ac: '', cl: '', qt: this.state.PesoTotal, un: 'Kg' },)
        }

        doc.setFillColor(22, 160, 133);
        doc.rect(14.12, 72.8, 181.76, 7.5, 'FD'); //Fill and Border
        doc.setFontSize(10);
        doc.setTextColor('white');
        doc.setFont('Helvetica', 'bold')
        doc.text('2. DESCRIÇÃO DOS RESÍDUOS/REJEITOS', 16, 77);

        doc.autoTable({
            theme: 'striped',
            tableLineWidth: 0.2,
            startY: 80,
            columnStyles: {
                titulo: { fontStyle: 'bold', textColor: 'black', cellWidth: 40, halign: 'right' },
                texto: { fontStyle: 'normal', halign: 'left' }
            },
            tableLineColor: 'black',
            headStyles: { fontSize: 10, textColor: 'white', lineWidth: 0, fillColor: 'gray', lineColor: 'black', },
            bodyStyles: { fontSize: 10, lineWidth: 0 },
            columns: [
                { header: 'Nome', dataKey: 'name' },
                { header: 'Estado físico', dataKey: 'ef' },
                { header: 'Acondicionamento', dataKey: 'ac' },
                { header: 'Classe', dataKey: 'cl' },
                { header: 'Q.Total', dataKey: 'qt' },
                { header: 'U.Medida', dataKey: 'un' },

            ],
            body: mat_inserido,
        });

        let transportadora = this.state.Transportadoras.filter(e => e.id === this.state.TransportadoraId);
        doc.autoTable({
            theme: 'striped',
            tableLineWidth: 0.2,
            columnStyles: {
                titulo: { fontStyle: 'bold', textColor: 'black', cellWidth: 40, halign: 'right' },
                texto: { fontStyle: 'normal', halign: 'left' }
            },
            tableLineColor: 'black',
            headStyles: { fontSize: 10, textColor: 'white', fillColor: [22, 160, 133], lineColor: 'black' },
            bodyStyles: { fontSize: 10, lineWidth: 0 },

            columns: [
                { header: '3. TRANSPORTADOR', dataKey: 'titulo' },
                { dataKey: 'texto' },
            ],
            body: [{ titulo: 'Razão social:', texto: transportadora.map(item => item.nome_fantasia) },
            { titulo: 'Endereço:', texto: transportadora.map(item => item.logradouro + ', ' + item.numero + ' - ' + item.bairro + ', ' + item.cidade + '-' + item.estado) },
            { titulo: 'Telefone:', texto: transportadora.map(item => item.responsavel.user_dado.telefone) },
            { titulo: 'E-mail:', texto: transportadora.map(item => item.responsavel.email) },
            { titulo: 'Descrição:', texto: transportadora.map(item => item.descricao) }],
        });

        let veiculo = this.state.Veiculos.filter(e => e.id === this.state.VeiculoId);
        doc.autoTable({
            theme: 'striped',
            tableLineWidth: 0.2,
            columnStyles: {
                titulo: { fontStyle: 'bold', textColor: 'black', cellWidth: 40, halign: 'right' },
                texto: { fontStyle: 'normal', halign: 'left' }
            },
            tableLineColor: 'black',
            headStyles: { fontSize: 10, textColor: 'white', fillColor: [22, 160, 133], lineColor: 'black' },
            bodyStyles: { fontSize: 10, lineWidth: 0 },
            columns: [
                { header: '4. VEÍCULO', dataKey: 'titulo' },
                { dataKey: 'texto' },
            ],
            body: [{ titulo: 'Placa:', texto: veiculo.map(item => item.placa) },
            { titulo: 'Tipo de veículo:', texto: veiculo.map(item => item.tipo.nome) },
            { titulo: 'Marca:', texto: veiculo.map(item => item.marca.nome) },
            { titulo: 'Modelo:', texto: veiculo.map(item => item.modelo.nome) },
            { titulo: 'Ano:', texto: veiculo.map(item => item.ano) },
            { titulo: 'Conservação:', texto: veiculo.map(item => item.conservacao.nome) },
            { titulo: 'Nome do condutor:', texto: this.state.Motoristas.filter(m => m.id === this.state.MotoristaId).map(m => m.nome) }],
        });

        let destino = this.state.Destinos.filter(e => e.id === this.state.Destino);
        doc.autoTable({
            pageBreak: 'avoid',
            theme: 'striped',
            tableLineWidth: 0.2,
            columnStyles: {
                titulo: { fontStyle: 'bold', textColor: 'black', cellWidth: 35, halign: 'right' },
                texto: { fontStyle: 'normal', halign: 'left' }
            },
            tableLineColor: 'black',
            headStyles: { fontSize: 10, textColor: 'white', fillColor: [22, 160, 133], lineColor: 'black' },
            bodyStyles: { fontSize: 10, lineWidth: 0 },
            columns: [
                { header: '5. DESTINO', dataKey: 'titulo' },
                { dataKey: 'texto' },
            ],
            body: [{ titulo: 'Razão social:', texto: destino.map(item => item.fantasia) },
            { titulo: 'Endereço:', texto: destino.map(item => item.logradouro + ', ' + item.numero + ' - ' + item.bairro + ', ' + item.cidade + '-' + item.estado) },
            { titulo: 'Telefone:', texto: destino.map(item => item.user_id.user_dado.telefone) },
            { titulo: 'E-mail:', texto: destino.map(item => item.user_id.email) },
            { titulo: 'Licenciamento:', texto: destino.map(item => item.licenca) },
            { titulo: 'Número da licença:', texto: destino.map(item => item.numlicenca) },
            { titulo: 'Descrição:', texto: destino.map(item => item.descricao) }],
        });

        doc.autoTable({
            theme: 'striped',
            tableLineWidth: 0.2,
            columnStyles: {
                titulo: { fontStyle: 'bold', textColor: 'black', cellWidth: 35, halign: 'right' },
                texto: { fontStyle: 'normal', halign: 'left' }
            },
            tableLineColor: 'black',
            headStyles: { fontSize: 10, textColor: 'white', fillColor: [22, 160, 133], lineColor: 'black' },
            bodyStyles: { fontSize: 10, lineWidth: 0 },
            columns: [
                { header: '6. FISCAL', dataKey: 'titulo' },
                { dataKey: 'texto' },
            ],
            body: [{ titulo: 'Chave da nota fiscal:', texto: this.state.ChaveNota },
            { titulo: 'Número e série da nota fiscal:', texto: this.state.NumeroNota + '-' + this.state.SerieNota }],
        });

        doc.autoTable({
            theme: 'striped',
            tableLineWidth: 0.2,
            columnStyles: {
                titulo: { fontStyle: 'bold', textColor: 'black', cellWidth: 35, halign: 'right' },
                texto: { fontStyle: 'normal', halign: 'left' }
            },
            tableLineColor: 'black',
            headStyles: { fontSize: 10, textColor: 'white', fillColor: [22, 160, 133], lineColor: 'black' },
            bodyStyles: { fontSize: 10, lineWidth: 0 },
            columns: [
                { header: '7. DESPACHO', dataKey: 'titulo' },
                { dataKey: 'texto' },
            ],
            body: [{ titulo: 'Volume total:', texto: this.state.VolumeTotal + ' l' },
            { titulo: 'Receita:', texto: 'R$ ' + this.state.ReceitaTotal.replace('.', ',') },
            { titulo: 'Despesa:', texto: 'R$ ' + this.state.DespesaTotal.replace('.', ',') },
            { titulo: 'Saldo:', texto: 'R$ ' + this.state.Saldo.replace('.', ',') }],
        });

        doc.autoTable({
            theme: 'striped',
            tableLineWidth: 0.2,
            columnStyles: {
                titulo: { fontStyle: 'bold', textColor: 'black', cellWidth: 35, halign: 'right', lineWidth: 0 },
                texto: { fontStyle: 'normal', halign: 'left', lineWidth: 0 }
            },
            tableLineColor: 'black',
            headStyles: { fontSize: 10, textColor: 'white', fillColor: [22, 160, 133], lineColor: 'black' },
            bodyStyles: { fontSize: 10, lineWidth: 0.2 },
            columns: [
                { header: '8. RESPONSAVEÍS', dataKey: 'titulo' },
                { dataKey: 'texto' },
                { header: 'Nome', dataKey: 'nome' },
                { header: 'Data', dataKey: 'data' },
                { header: 'Assinatura', dataKey: 'ass' },
            ],
            body: [{ titulo: 'Gerador:', texto: ponto_coleta.nome_fantasia, nome: ponto_coleta.responsavel.name, data: '          ', ass: '' },
            { titulo: 'Transportador:', texto: transportadora.map(item => item.nome_fantasia), nome: transportadora.map(item => item.responsavel.name), data: '          ', ass: '' },
            { titulo: 'Destino:', texto: destino.map(item => item.fantasia), nome: destino.map(item => item.user_id.name), data: '          ', ass: '' }],
        });

        doc.autoTable({
            theme: 'plain',
            tableLineWidth: 0.2,
            tableLineColor: 'black',
            headStyles: { fontSize: 10, textColor: 'black', fillColor: null, lineColor: 'black' },
            bodyStyles: { fontSize: 10, lineWidth: 0 },
            head: [['9. Relato de intercorrências ou discrepância das indicações deste manifesto:']],
            body: [[], []],
        });

        doc.autoTable({
            theme: 'plain',
            tableLineColor: 'black',
            tableLineWidth: 0.2,
            headStyles: { fontSize: 10, textColor: 'black', fillColor: null, },
            bodyStyles: { fontSize: 8, lineWidth: 0 },
            head: [['10. Recebimento do destino: Certificação de recebimento dos resíduos/rejeitos descritos neste manifesto: ']],
            body: [[], ['                    ______________________________   ______________________________   ______________________________          '],
            ['                                                                                                           Assinatura                                                    '],
            [], ['OBS.: Preencher em 5 vias – 1a Gerador – 2a Transportador – 3a Destino – 4a Órgão Ambiental – 5a Via Gerador assinada pelo destino']],
        });
        // doc.save('table.pdf');  
        // doc.output('dataurlnewwindow');
        window.open(doc.output('bloburl'))
    }



    componentDidMount() {
        let data = new Date();
        let dia = String(data.getDate()).padStart(2, '0');
        let mes = String(data.getMonth() + 1).padStart(2, '0');
        let ano = data.getFullYear();
        this.setState({
            DataAtual: ano + '-' + mes + '-' + dia,
            DataSolicitacao: ano + '-' + mes + '-' + dia
        })
        this.get_data(this.props.match.params.id);
    }



    render() {

        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">


                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Destinação</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Adicione uma nova destinação</p>
                            <br />

                            {this.state.loading_screen == false && <div>
                                {this.state.msgErro != '' && <div>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>
                                        <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                                    </div>
                                    <br />

                                </div>}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Destino<b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <select value={this.state.Destino} className="form-control"
                                                    onChange={(e) => {
                                                        if (e.target.value == '') {
                                                            this.setState({
                                                                SolicitacoesSelecionadas: [],
                                                                Destino: '',
                                                                Solicitacao: '',
                                                                DestinoSelecionado: [],
                                                                SolicitacaoSelecionada: {},
                                                                MaterialSolicitacao: {},
                                                                Peso: '',
                                                                Volume: '',
                                                                ValorFinanceiro: '',
                                                                Movimentacao: '',
                                                                Outros: false,
                                                                DestinacaoId: '',
                                                                Materialselecionado: {},
                                                                Tratamento: '',
                                                                DevolucaoSelecionada: {},
                                                                MateriaisInseridos: []
                                                            })
                                                        }
                                                        else {
                                                            let destino_sel = this.state.Destinos.filter(d => d.id == e.target.value);
                                                            let sol_sel = [];
                                                            for (let i = 0; i < this.state.Solicitacoes.length; i++) {
                                                                for (let j = 0; j < this.state.Solicitacoes[i].material.length; j++) {
                                                                    if (destino_sel[0].residuos_rejeitos.length > 0) {
                                                                        for (let k = 0; k < destino_sel[0].residuos_rejeitos.length; k++) {
                                                                            if (this.state.Solicitacoes[i].material[j].residuo_rejeito_id == destino_sel[0].residuos_rejeitos[k].id) {

                                                                                sol_sel.push(this.state.Solicitacoes[i]);
                                                                                k = destino_sel[0].residuos_rejeitos.length;
                                                                                j = this.state.Solicitacoes[i].material.length;
                                                                            }

                                                                        }
                                                                    }
                                                                }
                                                            }
                                                            this.setState({
                                                                SolicitacoesSelecionadas: sol_sel,
                                                                Destino: e.target.value,
                                                                Solicitacao: '',
                                                                DestinoSelecionado: destino_sel,
                                                                SolicitacaoSelecionada: {},
                                                                MaterialSolicitacao: {},
                                                                Peso: '',
                                                                Volume: '',
                                                                Movimentacao: '',
                                                                Outros: false,
                                                                ValorFinanceiro: '',
                                                                DestinacaoId: '',
                                                                Materialselecionado: {},
                                                                Tratamento: '',
                                                                DevolucaoSelecionada: {},
                                                                MateriaisInseridos: []
                                                            })
                                                        }
                                                    }}>
                                                    <option value={''}>Selecione um destino</option>
                                                    {this.state.Destinos.map((item, id) => (<option key={id} value={item.id}>{item.fantasia}</option>))}
                                                </select>
                                            </div>
                                        </div>

                                        {this.state.SolicitacoesSelecionadas.length > 0 && <><hr />
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black', fontSize: '1.5rem' }}>Dados da solicitação em aberto:</label>
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label">Solicitações<b style={{ color: 'red' }}>*</b></label>
                                                <div className="col-sm-12">
                                                    <select value={this.state.Solicitacao} className="form-control"
                                                        onChange={(e) => {
                                                            let verificador = false;
                                                            for (let i = 0; i < this.state.MateriaisInseridos.length; i++) {
                                                                if (e.target.value == this.state.MateriaisInseridos.residuo_rejeito_id) {
                                                                    verificador = true;
                                                                }
                                                            }
                                                            if (e.target.value == '' || verificador == true) {
                                                                this.setState({
                                                                    SolicitacaoSelecionada: {},
                                                                    MateriaisSolicitacao: [],
                                                                    Solicitacao: '',
                                                                    MaterialSolicitacao: {},
                                                                    Peso: '',
                                                                    Volume: '',
                                                                    Movimentacao: '',
                                                                    Outros: false,
                                                                    ValorFinanceiro: '',
                                                                    SolicitacaoSelecionada: {},
                                                                    DestinacaoId: '',
                                                                    Materialselecionado: {},
                                                                    Tratamento: '',
                                                                    DevolucaoSelecionada: {},
                                                                    MateriaisInseridos: []
                                                                });
                                                            }
                                                            else {
                                                                let solicitacao = this.state.SolicitacoesSelecionadas.filter(item => item.id == e.target.value);
                                                                let materiais = solicitacao[0].material.filter(item => item.peso != 0);
                                                                console.log(solicitacao)
                                                                this.setState({
                                                                    SolicitacaoSelecionada: solicitacao,
                                                                    MateriaisSolicitacao: materiais,
                                                                    Solicitacao: e.target.value,
                                                                    MaterialSolicitacao: {},
                                                                    Peso: '',
                                                                    Volume: '',
                                                                    Movimentacao: '',
                                                                    Outros: false,
                                                                    ValorFinanceiro: '',
                                                                    DestinacaoId: '',
                                                                    Materialselecionado: {},
                                                                    Tratamento: '',
                                                                    DevolucaoSelecionada: {},
                                                                    MateriaisInseridos: []
                                                                });
                                                            }
                                                        }}>
                                                        <option value={''}>Selecione uma opção</option>
                                                        {this.state.SolicitacoesSelecionadas.map((item, id) => (<option key={id} value={item.id}> {item.ponto_coleta.nome_fantasia} - {item.codigo_solicitacao}</option>))}
                                                    </select>
                                                </div>
                                            </div>
                                            <br />

                                            {this.state.SolicitacaoSelecionada.length > 0 && <>
                                                <div className="form-group row">
                                                    <p className="col-sm-6 "> <b className="col-sm-4 ">Data da solicitação: </b> {this.state.SolicitacaoSelecionada[0].data.replace(/(\d*)-(\d*)-(\d*).*/, '$3/$2/$1')} </p>
                                                </div>
                                                <div className="form-group row">
                                                    <p className="col-sm-6 "><b className="col-sm-4 " >Ponto de coleta:  </b> {this.state.SolicitacaoSelecionada[0].ponto_coleta.nome_fantasia}</p>
                                                </div>
                                                <div className="form-group row">
                                                    <p className="col-sm-6 "><b className="col-sm-4 " >Responsável: </b>{this.state.SolicitacaoSelecionada[0].responsavel.name}</p>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-sm-12 col-form-label">Material<b style={{ color: 'red' }}>*</b></label>
                                                    <div className="col-sm-12">
                                                        <select value={this.state.MaterialSolicitacao} className="form-control"
                                                            onChange={(e) => {
                                                                let verificador = false;
                                                                for (let i = 0; i < this.state.MateriaisInseridos.length; i++) {
                                                                    if (e.target.value == this.state.MateriaisInseridos[i].residuo_rejeito_id) {
                                                                        verificador = true
                                                                    }
                                                                }
                                                                if (e.target.value == '' || verificador == true) {
                                                                    this.setState({
                                                                        MaterialSolicitacao: '',
                                                                        Materialselecionado: {},
                                                                        Peso: '',
                                                                        Movimentacao: '',
                                                                        Outros: false,
                                                                        ValorFinanceiro: '',
                                                                        Volume: '',
                                                                        DestinacaoId: '',
                                                                        Tratamento: ''
                                                                    })
                                                                } else {
                                                                    let mat_sel = this.state.MateriaisSolicitacao.filter(item => item.residuo_rejeito_id == e.target.value);
                                                                    let mat_dev = this.state.MateriaisSolicitacao.filter(item => item.residuo_rejeito_id == e.target.value && item.solicitar_coleta_id == this.state.DestinacaoAtual.solicitacao_id);
                                                                    let devolucao_sel = this.state.Devolucao.filter(dev => dev.residuo_rejeito_id == mat_dev[0].residuo_rejeito_id);
                                                                    console.log(mat_dev);
                                                                    console.log(devolucao_sel);
                                                                    this.setState({
                                                                        MaterialSolicitacao: e.target.value,
                                                                        Materialselecionado: mat_sel,
                                                                        Peso: '',
                                                                        Movimentacao: mat_sel[0].residuo_rejeito.saldo_financeiro,
                                                                        ValorFinanceiro: '',
                                                                        Volume: '',
                                                                        DestinacaoId: mat_sel[0].residuo_rejeito.destinacao_id,
                                                                        Tratamento: mat_sel[0].residuo_rejeito.tratamento_id,
                                                                        DevolucaoSelecionada: devolucao_sel
                                                                    })
                                                                }
                                                            }}>
                                                            <option value={''}>Selecione uma opção</option>
                                                            {this.state.MateriaisSolicitacao.map((item, id) => (<option key={id} value={item.residuo_rejeito_id}>{item.residuo_rejeito.nome}</option>))}
                                                        </select>
                                                    </div>
                                                </div>
                                                {this.state.Materialselecionado.length > 0 && <>

                                                    {this.state.Materialselecionado[0].residuo_rejeito.unidade_principal.tipo == 'Peso' &&
                                                        <div className="row">
                                                            <div className="col-md-6 col-12">
                                                                <label className="col-sm-12 col-form-label">Peso<b style={{ color: 'red' }}>*</b></label>
                                                                <div className="input-group mb-3">
                                                                    <span className="input-group-text" id="basic-addon3">Solicitado: {this.state.DevolucaoSelecionada.length > 0 ?
                                                                        (parseFloat(this.state.Materialselecionado[0].peso) + parseFloat(this.state.DevolucaoSelecionada[0].peso))
                                                                        : this.state.Materialselecionado[0].peso} {this.state.Materialselecionado[0].residuo_rejeito.unidade_principal.sigla}</span>
                                                                    <NumericFormat className="form-control " id="peso"
                                                                        placeholder="Informe o peso" type="text"
                                                                        valueIsNumericString
                                                                        thousandsGroupStyle='thousand'
                                                                        thousandSeparator="."
                                                                        decimalScale={2}
                                                                        decimalSeparator=","
                                                                        value={this.state.Peso}
                                                                        onValueChange={(values, e) => {
                                                                            if (values.value == '') {
                                                                                this.setState({
                                                                                    Peso: values.value,
                                                                                    ValorFinanceiro: '',
                                                                                    Volume: ''
                                                                                })
                                                                            } else {
                                                                                let valor = parseFloat(values.value) * parseFloat(this.state.Materialselecionado[0].residuo_rejeito.valor_saldo_financeiro);
                                                                                this.setState({
                                                                                    Peso: values.value,
                                                                                    ValorFinanceiro: valor.toFixed(2),
                                                                                    Volume: parseFloat(values.value) * parseFloat(this.state.Materialselecionado[0].residuo_rejeito.equivalencia),
                                                                                    validated: false,
                                                                                    required: false
                                                                                })
                                                                            }
                                                                        }} />
                                                                </div>
                                                                {this.state.validated == true && <b style={{ color: 'red', fontSize: '12px' }}>O peso atendido não pode ser maior que o solicitado</b>}
                                                                {this.state.required == true && <b style={{ color: 'red', fontSize: '12px' }}>O campo Peso é obrigatório</b>}
                                                            </div>
                                                            <div className="col-md-6 col-12">
                                                                <label className="col-sm-12 col-form-label">Volume<b style={{ color: 'red' }}>*</b></label>
                                                                <div className="input-group mb-3">
                                                                    <span className="input-group-text" id="basic-addon3">Solicitado: {this.state.DevolucaoSelecionada.length > 0 ?
                                                                        (parseFloat(this.state.Materialselecionado[0].volume) + parseFloat(this.state.DevolucaoSelecionada[0].volume))
                                                                        : this.state.Materialselecionado[0].volume} {this.state.Materialselecionado[0].residuo_rejeito.unidade_secundaria.sigla} </span>
                                                                    <NumericFormat className="form-control" id="volume"
                                                                        disabled placeholder="Informe o volume" type="text"
                                                                        valueIsNumericString
                                                                        thousandsGroupStyle='thousand'
                                                                        thousandSeparator="."
                                                                        decimalScale={2}
                                                                        decimalSeparator=","
                                                                        value={this.state.Volume} />
                                                                </div>
                                                            </div>
                                                        </div>}

                                                    {this.state.Materialselecionado[0].residuo_rejeito.unidade_principal.tipo !== 'Peso' &&
                                                        <div className="row">
                                                            <div className="col-md-6 col-12">
                                                                <label className="col-sm-12 col-form-label">{this.state.Materialselecionado[0].residuo_rejeito.unidade_principal.tipo}<b style={{ color: 'red' }}>*</b></label>
                                                                <div className="input-group mb-3">
                                                                    <span className="input-group-text" id="basic-addon3">Solicitado: {this.state.DevolucaoSelecionada.length > 0 ?
                                                                        (parseFloat(this.state.Materialselecionado[0].volume) + parseFloat(this.state.DevolucaoSelecionada[0].volume))
                                                                        : this.state.Materialselecionado[0].volume} {this.state.Materialselecionado[0].residuo_rejeito.unidade_principal.sigla} </span>
                                                                    <NumericFormat className="form-control" id="volume"
                                                                        placeholder={`${this.state.Materialselecionado[0].unidade_principal.tipo === 'Volume' ? 'Informe o' : 'Informe a'} ${this.state.Materialselecionado[0].residuo_rejeito.unidade_principal.tipo.toLowerCase()}`}
                                                                        type="text"
                                                                        valueIsNumericString
                                                                        thousandsGroupStyle='thousand'
                                                                        thousandSeparator="."
                                                                        decimalScale={2}
                                                                        decimalSeparator=","
                                                                        value={this.state.Volume}
                                                                        onValueChange={(values, e) => {
                                                                            if (values.value == '') {
                                                                                this.setState({
                                                                                    Volume: values.value,
                                                                                    ValorFinanceiro: '',
                                                                                    Peso: ''
                                                                                })
                                                                            } else {
                                                                                let valor = parseFloat(values.value) * parseFloat(this.state.Materialselecionado[0].residuo_rejeito.valor_saldo_financeiro);
                                                                                this.setState({
                                                                                    Volume: values.value,
                                                                                    ValorFinanceiro: valor.toFixed(2),
                                                                                    Peso: parseFloat(values.value) * parseFloat(this.state.Materialselecionado[0].residuo_rejeito.equivalencia),
                                                                                    validated: false,
                                                                                    required: false
                                                                                })
                                                                            }
                                                                        }} />
                                                                </div>
                                                                {this.state.validated == true && <b style={{ color: 'red', fontSize: '12px' }}>O volume atendido não pode ser maior que o solicitado</b>}
                                                                {this.state.required == true && <b style={{ color: 'red', fontSize: '12px' }}>O campo Volume é obrigatório</b>}
                                                            </div>
                                                            <div className="col-md-6 col-12">
                                                                <label className="col-sm-12 col-form-label">Peso<b style={{ color: 'red' }}>*</b></label>
                                                                <div className="input-group mb-3">
                                                                    <span className="input-group-text" id="basic-addon3">Solicitado: {this.state.DevolucaoSelecionada.length > 0 ?
                                                                        (parseFloat(this.state.Materialselecionado[0].peso) + parseFloat(this.state.DevolucaoSelecionada[0].peso))
                                                                        : this.state.Materialselecionado[0].peso} {this.state.Materialselecionado[0].residuo_rejeito.unidade_secundaria.sigla}</span>
                                                                    <NumericFormat className="form-control " id="peso"
                                                                        disabled placeholder="Informe o peso" type="text"
                                                                        valueIsNumericString
                                                                        thousandsGroupStyle='thousand'
                                                                        thousandSeparator="."
                                                                        decimalScale={2}
                                                                        decimalSeparator=","
                                                                        value={this.state.Peso} />
                                                                </div>
                                                            </div>
                                                        </div>}

                                                    <div className="row">
                                                        <div className="col-md-6 col-12">
                                                            <label className="col-sm-12 col-form-label">Tipo de movimentação<b style={{ color: 'red' }}>*</b></label>
                                                            <div className="input-group mb-3">
                                                                <select value={this.state.Movimentacao} className="form-control"
                                                                    onChange={(e) => {
                                                                        if (e.target.value == 'Outros') {
                                                                            this.setState({
                                                                                Outros: true,
                                                                                Movimentacao: 'Não Aplicável'
                                                                            })
                                                                        } else {
                                                                            this.setState({
                                                                                Movimentacao: e.target.value,
                                                                            })
                                                                        }
                                                                    }}>
                                                                    <option value={'Outros'}>Outros</option>
                                                                    <option value={'Receita'}>Receita</option>
                                                                    <option value={'Despesa'}>Despesa</option>

                                                                </select>
                                                            </div>
                                                        </div>
                                                        {this.state.Outros == true &&
                                                            <div className="col-md-6 col-12">
                                                                <label className="col-sm-12 col-form-label">Outros<b style={{ color: 'red' }}>*</b></label>
                                                                <div className="input-group mb-3">
                                                                    <select value={this.state.Movimentacao} className="form-control"
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                Movimentacao: e.target.value
                                                                            })
                                                                        }}>
                                                                        <option value={'Não Aplicável'}>Não Aplicável</option>
                                                                        <option value={'Doação'}>Doação</option>
                                                                        <option value={'Despesa Evitada'}>Despesa Evitada</option>
                                                                    </select>
                                                                </div>
                                                            </div>}
                                                        {this.state.Outros == false && <div className="col-md-6 col-12">
                                                            <label className="col-sm-12 col-form-label">Valor financeiro<b style={{ color: 'red' }}>*</b></label>
                                                            <div className="input-group mb-3">
                                                                <NumericFormat className="form-control"
                                                                    placeholder="R$ 99,99" type="text"
                                                                    prefix={'R$ '}
                                                                    thousandsGroupStyle='thousand'
                                                                    thousandSeparator="."
                                                                    decimalScale={2}
                                                                    decimalSeparator=","
                                                                    valueIsNumericString
                                                                    onValueChange={(values, e) =>
                                                                        this.setState({ ValorFinanceiro: values.value })}
                                                                    value={this.state.ValorFinanceiro} />
                                                            </div>
                                                        </div>}
                                                        {this.state.Outros == true && <div className="col-md-6 col-12">
                                                            <label className="col-sm-12 col-form-label">Valor financeiro<b style={{ color: 'red' }}>*</b></label>
                                                            <div className="input-group mb-3">
                                                                <NumericFormat className="form-control"
                                                                    disabled placeholder="R$ 99,99" type="text"
                                                                    prefix={'R$ '}
                                                                    thousandsGroupStyle='thousand'
                                                                    thousandSeparator="."
                                                                    decimalScale={2}
                                                                    decimalSeparator=","
                                                                    valueIsNumericString
                                                                    onValueChange={(values, e) =>
                                                                        this.setState({ ValorFinanceiro: values.value })}
                                                                    value={maskPrice('0.00')} />
                                                            </div>
                                                        </div>}
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-6 col-12">
                                                            <label className="col-sm-12 col-form-label">Destinação<b style={{ color: 'red' }}>*</b></label>
                                                            <div className="input-group mb-3">
                                                                <select value={this.state.DestinacaoId} className="form-control"
                                                                    onChange={(e) => {
                                                                        this.setState({
                                                                            DestinacaoId: e.target.value
                                                                        })
                                                                    }}>
                                                                    <option value={''}>selecione uma opção</option>
                                                                    {this.state.Destinacoes.map((item, id) => (<option key={id} value={item.id}>{'[' + item.codigo + '] ' + item.descricao}</option>))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-12">
                                                            <label className="col-sm-12 col-form-label">Tratamento<b style={{ color: 'red' }}>*</b></label>
                                                            <div className="input-group mb-3">
                                                                <select value={this.state.Tratamento} className="form-control"
                                                                    onChange={(e) => {
                                                                        this.setState({
                                                                            Tratamento: e.target.value
                                                                        })
                                                                    }}>
                                                                    <option value={''}>selecione uma opção</option>
                                                                    {this.state.Tratamentos.map((item, id) => (<option key={id} value={item.id}>{'[' + item.codigo + '] ' + item.descricao}</option>))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                        <button type="button" className="btn btn-success" style={{ backgorundColor: '#f2f2f2' }} onClick={() => {
                                                            let receita;
                                                            let despesa;
                                                            let saldo;
                                                            let valor = this.state.ValorFinanceiro;
                                                            console.log(valor);
                                                            console.log(this.state.ReceitaTotal);
                                                            console.log(parseFloat(this.state.ReceitaTotal) + parseFloat(valor));
                                                            if (this.state.Movimentacao == 'Receita') {
                                                                saldo = parseFloat(parseFloat(this.state.Saldo).toFixed(2)) + parseFloat(parseFloat(valor).toFixed(2));
                                                                receita = parseFloat(parseFloat(this.state.ReceitaTotal).toFixed(2)) + parseFloat(parseFloat(valor).toFixed(2));
                                                                despesa = parseFloat(parseFloat(this.state.DespesaTotal).toFixed(2));
                                                            } else if (this.state.Movimentacao == 'Despesa') {
                                                                despesa = parseFloat(parseFloat(this.state.DespesaTotal).toFixed(2)) + parseFloat(parseFloat(valor).toFixed(2));
                                                                saldo = parseFloat(parseFloat(this.state.Saldo).toFixed(2)) - parseFloat(parseFloat(valor).toFixed(2));
                                                                receita = parseFloat(parseFloat(this.state.ReceitaTotal).toFixed(2));
                                                            } else {
                                                                despesa = parseFloat(parseFloat(this.state.DespesaTotal).toFixed(2));
                                                                saldo = parseFloat(parseFloat(this.state.Saldo).toFixed(2));
                                                                receita = parseFloat(parseFloat(this.state.ReceitaTotal).toFixed(2));
                                                            }

                                                            console.log(receita.toFixed(2))
                                                            console.log(despesa.toFixed(2))
                                                            console.log(saldo.toFixed(2))

                                                            if (this.state.Materialselecionado[0].residuo_rejeito.unidade_principal.tipo === 'Peso' && this.state.Peso == '' ||
                                                                this.state.Materialselecionado[0].residuo_rejeito.unidade_principal.tipo !== 'Peso' && this.state.Volume == '') {
                                                                this.setState({ required: true })
                                                            } else if (this.state.DevolucaoSelecionada.length > 0) {
                                                                console.log(parseFloat(this.state.DevolucaoSelecionada[0].peso) + parseFloat(this.state.Materialselecionado[0].peso));
                                                                if (this.state.Materialselecionado[0].residuo_rejeito.unidade_principal.tipo === 'Peso' && parseFloat(this.state.Peso) > parseFloat(this.state.DevolucaoSelecionada[0].peso) + parseFloat(this.state.Materialselecionado[0].peso) ||
                                                                    this.state.Materialselecionado[0].residuo_rejeito.unidade_principal.tipo !== 'Peso' && parseFloat(this.state.Volume) > parseFloat(this.state.DevolucaoSelecionada[0].volume) + parseFloat(this.state.Materialselecionado[0].volume)) {
                                                                    this.setState({ validated: true })
                                                                } else {
                                                                    let peso_kg;
                                                                    let volume_lt;
                                                                    if (this.state.Materialselecionado[0].residuo_rejeito.unidade_principal.tipo === 'Peso') {
                                                                        peso_kg = parseFloat(this.state.Peso) * parseFloat(this.state.Materialselecionado[0].residuo_rejeito.unidade_principal.equivalencia);
                                                                        volume_lt = parseFloat(this.state.Volume) * parseFloat(this.state.Materialselecionado[0].residuo_rejeito.unidade_secundaria.equivalencia);
                                                                    } else {
                                                                        peso_kg = parseFloat(this.state.Peso) * parseFloat(this.state.Materialselecionado[0].residuo_rejeito.unidade_secundaria.equivalencia);
                                                                        volume_lt = parseFloat(this.state.Volume) * parseFloat(this.state.Materialselecionado[0].residuo_rejeito.unidade_principal.equivalencia);
                                                                    }
                                                                    let materiais = [...this.state.MateriaisInseridos];
                                                                    let new_material = {
                                                                        "residuo_rejeito_id": this.state.MaterialSolicitacao,
                                                                        "nome": this.state.Materialselecionado[0].residuo_rejeito.nome,
                                                                        "peso": this.state.Peso,
                                                                        "volume": this.state.Volume,
                                                                        "peso_kg": peso_kg,
                                                                        "volume_lt": volume_lt,
                                                                        "movimentacao": this.state.Movimentacao,
                                                                        "tipo_unidade_principal": this.state.Materialselecionado[0].residuo_rejeito.unidade_principal.tipo,
                                                                        "unidade_principal": this.state.Materialselecionado[0].residuo_rejeito.unidade_principal.sigla,
                                                                        "unidade_secundaria": this.state.Materialselecionado[0].residuo_rejeito.unidade_secundaria.sigla,
                                                                        "valor": this.state.ValorFinanceiro,
                                                                        "destinacao_disposicao_id": this.state.DestinacaoId,
                                                                        "tratamento_id": this.state.Tratamento
                                                                    };
                                                                    materiais.push(new_material);
                                                                    console.log(materiais);

                                                                    this.setState({
                                                                        PesoTotal: parseFloat(this.state.PesoTotal) + peso_kg,
                                                                        VolumeTotal: parseFloat(this.state.VolumeTotal) + volume_lt,
                                                                        MateriaisInseridos: materiais,
                                                                        ReceitaTotal: receita.toFixed(2).toString(),
                                                                        DespesaTotal: despesa.toFixed(2).toString(),
                                                                        Saldo: saldo.toFixed(2).toString(),
                                                                        MaterialSolicitacao: '',
                                                                        Materialselecionado: {},
                                                                        Peso: '',
                                                                        Movimentacao: '',
                                                                        Outros: false,
                                                                        ValorFinanceiro: '',
                                                                        Volume: '',
                                                                        DestinacaoId: '',
                                                                        Tratamento: ''
                                                                    })
                                                                }


                                                            } else if (this.state.Materialselecionado[0].residuo_rejeito.unidade_principal.tipo === 'Peso' && parseFloat(this.state.Peso) > parseFloat(this.state.Materialselecionado[0].peso) ||
                                                                this.state.Materialselecionado[0].residuo_rejeito.unidade_principal.tipo !== 'Peso' && parseFloat(this.state.Volume) > parseFloat(this.state.Materialselecionado[0].volume)) {
                                                                this.setState({ validated: true })
                                                            } else {
                                                                let peso_kg;
                                                                let volume_lt;
                                                                if (this.state.Materialselecionado[0].residuo_rejeito.unidade_principal.tipo === 'Peso') {
                                                                    peso_kg = parseFloat(this.state.Peso) * parseFloat(this.state.Materialselecionado[0].residuo_rejeito.unidade_principal.equivalencia);
                                                                    volume_lt = parseFloat(this.state.Volume) * parseFloat(this.state.Materialselecionado[0].residuo_rejeito.unidade_secundaria.equivalencia);
                                                                } else {
                                                                    peso_kg = parseFloat(this.state.Peso) * parseFloat(this.state.Materialselecionado[0].residuo_rejeito.unidade_secundaria.equivalencia);
                                                                    volume_lt = parseFloat(this.state.Volume) * parseFloat(this.state.Materialselecionado[0].residuo_rejeito.unidade_principal.equivalencia);
                                                                }
                                                                let materiais = [...this.state.MateriaisInseridos];
                                                                let new_material = {
                                                                    "residuo_rejeito_id": this.state.MaterialSolicitacao,
                                                                    "nome": this.state.Materialselecionado[0].residuo_rejeito.nome,
                                                                    "peso": this.state.Peso,
                                                                    "volume": this.state.Volume,
                                                                    "peso_kg": peso_kg,
                                                                    "volume_lt": volume_lt,
                                                                    "movimentacao": this.state.Movimentacao,
                                                                    "tipo_unidade_principal": this.state.Materialselecionado[0].residuo_rejeito.unidade_principal.tipo,
                                                                    "unidade_principal": this.state.Materialselecionado[0].residuo_rejeito.unidade_principal.sigla,
                                                                    "unidade_secundaria": this.state.Materialselecionado[0].residuo_rejeito.unidade_secundaria.sigla,
                                                                    "valor": this.state.ValorFinanceiro,
                                                                    "destinacao_disposicao_id": this.state.DestinacaoId,
                                                                    "tratamento_id": this.state.Tratamento
                                                                };
                                                                materiais.push(new_material);
                                                                console.log(materiais);
                                                                this.setState({
                                                                    PesoTotal: parseFloat(this.state.PesoTotal) + peso_kg,
                                                                    VolumeTotal: parseFloat(this.state.VolumeTotal) + volume_lt,
                                                                    MateriaisInseridos: materiais,
                                                                    ReceitaTotal: receita.toFixed(2).toString(),
                                                                    DespesaTotal: despesa.toFixed(2).toString(),
                                                                    Saldo: saldo.toFixed(2).toString(),
                                                                    MaterialSolicitacao: '',
                                                                    Materialselecionado: {},
                                                                    Peso: '',
                                                                    Movimentacao: '',
                                                                    Outros: false,
                                                                    ValorFinanceiro: '',
                                                                    Volume: '',
                                                                    DestinacaoId: '',
                                                                    Tratamento: ''
                                                                })
                                                            }
                                                        }
                                                        }>Inserir</button>
                                                    </div>
                                                </>}
                                            </>}
                                        </>}
                                        <hr />

                                        {this.state.MateriaisInseridos.length > 0 &&
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Materiais selecionados:</label>

                                                <table id="tech-companies-1" className="table table-striped table-hover" >
                                                    <thead>
                                                        <tr>
                                                            <th>Material</th>
                                                            <th>Peso</th>
                                                            <th>Volume</th>
                                                            <th data-priority="3">Excluir</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.MateriaisInseridos.map((item) => (
                                                            <tr key={item.residuo_rejeito_id}>
                                                                <td>{item.nome}</td>
                                                                <td>{maskNumberBr(item.peso + '')}  {item.tipo_unidade_principal == undefined ?
                                                                    item.residuo_rejeito.unidade_principal.tipo === 'Peso' ? item.residuo_rejeito.unidade_principal.sigla : item.residuo_rejeito.unidade_secundaria.sigla :
                                                                    item.tipo_unidade_principal === 'Peso' ? item.unidade_principal : item.unidade_secundaria}</td>
                                                                <td>{maskNumberBr(item.volume + '')} {item.tipo_unidade_principal == undefined ?
                                                                    item.residuo_rejeito.unidade_principal.tipo !== 'Peso' ? item.residuo_rejeito.unidade_principal.sigla : item.residuo_rejeito.unidade_secundaria.sigla :
                                                                    item.tipo_unidade_principal !== 'Peso' ? item.unidade_principal : item.unidade_secundaria}</td>
                                                                <td>
                                                                    <button className="btn btn-danger btn-outline btn-circle m-r-5" style={{ background: 'white', border: '0px solid red' }}><FaTrash color='red' onClick={() => {

                                                                        let receita = this.state.ReceitaTotal
                                                                        let despesa = this.state.DespesaTotal
                                                                        let valor = item.valor;
                                                                        let saldo;
                                                                        if (item.movimentacao == 'Receita') {
                                                                            receita = parseFloat(parseFloat(this.state.ReceitaTotal).toFixed(2)) - parseFloat(parseFloat(item.valor).toFixed(2));
                                                                            despesa = parseFloat(parseFloat(this.state.DespesaTotal).toFixed(2));
                                                                            saldo = parseFloat(parseFloat(this.state.Saldo).toFixed(2)) - parseFloat(parseFloat(item.valor).toFixed(2));
                                                                        } else if (item.movimentacao == 'Despesa') {
                                                                            receita = parseFloat(parseFloat(this.state.ReceitaTotal).toFixed(2));
                                                                            despesa = parseFloat(parseFloat(this.state.DespesaTotal).toFixed(2)) - parseFloat(parseFloat(item.valor).toFixed(2));
                                                                            saldo = parseFloat(parseFloat(this.state.Saldo).toFixed(2)) + parseFloat(parseFloat(item.valor).toFixed(2));
                                                                        } else {
                                                                            despesa = parseFloat(parseFloat(this.state.DespesaTotal).toFixed(2));
                                                                            saldo = parseFloat(parseFloat(this.state.Saldo).toFixed(2));
                                                                            receita = parseFloat(parseFloat(this.state.ReceitaTotal).toFixed(2));
                                                                        }

                                                                        let materias_inseridos = this.state.MateriaisInseridos.filter((item2) => item2.residuo_rejeito_id != item.residuo_rejeito_id);
                                                                        let material = this.state.ResiduosRejeitos.filter(item3 => item3.id == item.residuo_rejeito_id);
                                                                        let peso_kg;
                                                                        let volume_lt;
                                                                        console.log(material);
                                                                        if (material[0].unidade_principal.tipo == 'Peso') {
                                                                            peso_kg = parseFloat(item.peso) * parseFloat(material[0].unidade_principal.equivalencia);
                                                                            volume_lt = parseFloat(item.volume) * parseFloat(material[0].unidade_secundaria.equivalencia);
                                                                        } else {
                                                                            peso_kg = parseFloat(item.peso) * parseFloat(material[0].unidade_secundaria.equivalencia);
                                                                            volume_lt = parseFloat(item.volume) * parseFloat(material[0].unidade_principal.equivalencia);
                                                                        }


                                                                        let devolucao = [...this.state.Devolucao];
                                                                        devolucao.push(item);

                                                                        this.setState({
                                                                            PesoTotal: parseFloat(this.state.PesoTotal) - parseFloat(peso_kg),
                                                                            VolumeTotal: parseFloat(this.state.VolumeTotal) - parseFloat(volume_lt),
                                                                            ReceitaTotal: parseFloat(receita).toFixed(2).toString(),
                                                                            DespesaTotal: parseFloat(despesa).toFixed(2).toString(),
                                                                            Saldo: parseFloat(saldo).toFixed(2).toString(),
                                                                            MateriaisInseridos: materias_inseridos,
                                                                            Devolucao: devolucao,
                                                                        })
                                                                    }} />
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>}

                                        <label className="col-sm-12 col-form-label" style={{ color: 'black', fontSize: '1.5rem' }}>Dados do despacho:</label>
                                        <div className="form-group row">
                                            <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Situação: </label>{this.state.Situacao}</div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-12"> <label className="col-sm-4 col-form-label">Data da criação: </label>{this.state.DataCriacao.replace(/(\d*)-(\d*)-(\d*).*/, '$3/$2/$1')} </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Agendado para: </label>
                                            <div className="col-sm-12">
                                                <input className="form-control" value={this.state.DataAgendamento} type="date" style={{ maxWidth: 168 }} min={this.state.DataAtual} onChange={(e) => {

                                                    if (e.target.value != '' && this.state.DataInicio == '' && e.target.value >= this.state.DataAtual) {
                                                        this.setState({ Situacao: 'Agendado', DataAgendamento: e.target.value });
                                                    } else if (e.target.value != '' && this.state.DataInicio == '' && e.target.value < this.state.DataAtual) {
                                                        this.setState({ Situacao: 'Agendado', DataAgendamento: this.state.DataAtual });
                                                    }
                                                    else if (e.target.value != '' && this.state.DataInicio != '' && e.target.value < this.state.DataAtual) {
                                                        this.setState({ DataAgendamento: this.state.DataAtual });
                                                    }
                                                    else if (e.target.value != '' && this.state.DataInicio != '' && e.target.value >= this.state.DataAtual) {
                                                        this.setState({ DataAgendamento: e.target.value });
                                                    }
                                                    else if (e.target.value == '' && this.state.DataInicio == '') {
                                                        this.setState({ Situacao: 'Rascunho', DataAgendamento: e.target.value });
                                                    } else if (e.target.value == '' && this.state.DataInicio != '') {
                                                        this.setState({ DataAgendamento: e.target.value });
                                                    }
                                                }} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Iniciado em:</label>
                                            <div className="col-sm-12">
                                                <input className="form-control" value={this.state.DataInicio} type="date" style={{ maxWidth: 168 }} min={this.state.DataCriacao} max={this.state.DataAtual} onChange={(e) => {

                                                    if (e.target.value != '' && e.target.value < this.state.DataCriacao) {
                                                        this.setState({ DataInicio: this.state.DataAtual, Situacao: 'Iniciado' });
                                                    }
                                                    else if (e.target.value != '' && e.target.value > this.state.DataAtual) {
                                                        this.setState({ DataInicio: this.state.DataAtual, Situacao: 'Iniciado' });
                                                    }
                                                    else if (e.target.value != '') {
                                                        this.setState({ Situacao: 'Iniciado', DataInicio: e.target.value });
                                                    }
                                                    else if (e.target.value == '' && this.state.DataAgendamento == '') {
                                                        this.setState({ Situacao: 'Rascunho', DataInicio: e.target.value });
                                                    }
                                                    else if (e.target.value == '' && this.state.DataAgendamento != '') {
                                                        this.setState({ Situacao: 'Agendado', DataInicio: e.target.value });
                                                    }
                                                }} />
                                            </div>
                                        </div>
                                        {this.state.Situacao != 'Rascunho' && <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Finalizado em:</label>
                                            <div className="col-sm-12">
                                                <input className="form-control" value={this.state.DataFinalizado} type="date" style={{ maxWidth: 168 }} min={this.state.DataCriacao} onChange={(e) => {
                                                    if (e.target.value != '' && e.target.value < this.state.DataCriacao) {
                                                        this.setState({ DataFinalizado: this.state.DataAtual })
                                                    } else {
                                                        this.setState({ DataFinalizado: e.target.value })
                                                    }
                                                }} />
                                            </div>
                                        </div>}

                                        <div className="form-group row">
                                            <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Peso total: </label>{maskNumberBr(this.state.PesoTotal)} Kg</div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Volume total: </label>{maskNumberBr(this.state.VolumeTotal)} L</div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Receita: </label> R$ {this.state.ReceitaTotal.toString().replace('.', ',')}</div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Despesas: </label> R$ {this.state.DespesaTotal.toString().replace('.', ',')}</div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Saldo: </label> R$ {this.state.Saldo.toString().replace('.', ',')}</div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Transportadora<b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <select value={this.state.TransportadoraId} className="form-control"
                                                    onChange={(e) => {
                                                        this.setState({ TransportadoraId: e.target.value });
                                                        console.log(e.target.value)
                                                    }}>
                                                    <option value={''}>Selecione uma opção</option>
                                                    {this.state.Transportadoras.map((item, id) => (<option key={id} value={item.id}>{item.nome_fantasia}</option>))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Veículo<b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <select value={this.state.VeiculoId} className="form-control"
                                                    onChange={(e) => { this.setState({ VeiculoId: e.target.value }); console.log(e.target.value) }}>
                                                    <option value={''}>Selecione uma opção</option>
                                                    {this.state.Veiculos.map((item, id) => (<option key={id} value={item.id}>{item.modelo.nome}</option>))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Motorista<b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <select value={this.state.MotoristaId} className="form-control"
                                                    onChange={(e) => { this.setState({ MotoristaId: e.target.value }) }}>
                                                    <option value={''}>Selecione uma opção</option>
                                                    {this.state.Motoristas.map((item, id) => (<option key={id} value={item.id}>{item.nome}</option>))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Observações </label>
                                            <div className="col-sm-12">
                                                <textarea className="form-control" placeholder="Observações" value={this.state.Descricao} type="text" onChange={(e) => { this.setState({ Descricao: e.target.value }) }} />
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Chave da NF-e</label>
                                            <div className="col-sm-12">
                                                <input className="form-control" placeholder="Chave de acesso da nota fiscal" value={this.state.ChaveNota} type="text" onChange={(e) => { this.setState({ ChaveNota: e.target.value }) }} />
                                            </div>
                                        </div>
                                        <div className="form-group  row">
                                            <div className=" col-sm-12">
                                                <div className="row">
                                                    <div className="col-md-8 col-12">
                                                        <label className="col-sm-12 col-form-label">Número da NF-e </label>
                                                        <div className="col-sm-12">
                                                            <input className="form-control" placeholder="Número da nota fiscal" type="text" onChange={(e) => this.setState({ NumeroNota: e.target.value })} value={this.state.NumeroNota} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-12">
                                                        <label className="col-sm-12 col-form-label">Série da NF-e </label>
                                                        <div className="col-sm-12">
                                                            <input className="form-control" placeholder="Série da nota fiscal" type="text" onChange={(e) => this.setState({ SerieNota: e.target.value })} value={this.state.SerieNota} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                                {this.state.loading_save == false && <div className="row mt-3">
                                    <div className="col-12 col-sm-4 d-flex justify-content-center justify-content-sm-start"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <Link to="/destinacao"> <span
                                            className="btn btn-secondary btn-lg waves-effect waves-light"><MdReply /> Voltar</span></Link>
                                    </div>
                                    <div className="col-12 col-sm-8 d-flex justify-content-center justify-content-sm-end"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <button type="button" onClick={() => {
                                            if (this.state.Destino != '' && this.state.EstoqueId != '' && this.state.TransportadoraId != ''
                                                && this.state.VeiculoId != '' && this.state.MotoristaId != '' && this.state.MateriaisInseridos.length > 0) {
                                                this.Pdf()
                                            } else {
                                                this.setState({ show_warning: true });
                                            }
                                        }} className="btn btn-danger btn-lg waves-effect waves-light" style={{ color: 'white' }} >Gerar MTR</button>
                                        <button type="button" onClick={() => this.salvar()} className="btn btn-success btn-lg waves-effect waves-light"
                                            style={{ backgorundColor: '#f2f2f2', marginLeft: '5px' }}>Salvar </button>
                                        {this.state.Situacao != 'Rascunho' && <button type="button" onClick={() => this.finalizar()} className="btn btn-info btn-lg waves-effect waves-light justify-content-sm-end"
                                            style={{ marginLeft: '5px' }}>Finalizar </button>}
                                    </div>
                                </div>}
                                {this.state.loading_save == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                    <div className="spinner-border text-primary" role="status"  >
                                    </div>
                                </div></div>}
                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                </div>
                            </div></div>}
                            <SweetAlert
                                warning
                                title={"Atenção"}
                                onConfirm={() => { this.setState({ show_warning: false }); }}
                                show={this.state.show_warning}
                                confirmBtnText='     OK     '
                                confirmBtnBsStyle="success"
                            >
                                Para gerar o MTR você deve preencher todos os campos que são obrigatórios.
                            </SweetAlert>


                            <SweetAlert
                                warning
                                title={"Atenção"}
                                onConfirm={() => { this.setState({ Materialselecionado: {} }, () => this.salvar()) }}
                                onCancel={() => { this.setState({ show_warning_inserir: false }); }}
                                show={this.state.show_warning_inserir}
                                confirmBtnText='Sim, desejo salvar '
                                cancelBtnText='Cancelar'
                                confirmBtnBsStyle="success"
                                cancelBtnBsStyle="danger"
                                showCancel={true}
                            >
                                Existe um material selecionado que não foi inserido, deseja salvar mesmo assim ?
                            </SweetAlert>
                        </div>

                    </div>
                </div>
            </div>
        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout })(EditarDestinacao);


