
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout, mudarDados, login } from '../../../actions/AppActions';
import { connect } from 'react-redux';

// users
import moment from 'moment';
import { URL } from '../../../variables';
import ReactPaginate from 'react-paginate';
import { MdArrowDropDown, MdReply } from 'react-icons/md';
import { maskNumber } from '../../../Auxiliar/Masks';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Accordion, AccordionDetails, AccordionSummary, Box, Tooltip } from '@mui/material';

class EditarTipoUsuario extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/tipos_usuario',
            loading_screen: false,
            nome: '',
            select_all: false,
            select_all_app: false,
            select_all_notifications: false,

            trigem_alert: false,
            item_permissoes: '',
            tipo_permissao: '',

            categorias_selected: {
                'configuracao_geral': false,
                'gestao_acesso': false,
                'personalizacao': false,
                'cadastro': false,
                'coleta_transporte': false,
                'triagem': false,
                'gestao_interna': false,
                'relacionamentos': false,
                'comunicacao': false,
                'relatorios': false,
                'compliance': false
            },

            permissoes: {
                'gestao': { leitura: true, escrita: true, exclusao: true, nome: 'Gestão de logs', permissao: 'Configuração Geral', categoria: 'configuracao_geral' },
                'dados_empresa': { leitura: true, escrita: true, exclusao: true, nome: 'Cadastro de Dados da Empresa', permissao: 'Configuração Geral', categoria: 'configuracao_geral' },

                'pessoas': { leitura: true, escrita: true, exclusao: true, nome: 'Gestão de pessoas', permissao: 'Gestão de Acesso', categoria: 'gestao_acesso' },
                'permissoes': { leitura: true, escrita: true, exclusao: true, nome: 'Gestão de permissões', permissao: 'Gestão de Acesso', categoria: 'gestao_acesso' },

                'unidades': { leitura: true, escrita: true, exclusao: true, nome: 'Cadastro de unidade de medida', permissao: 'Personalização', categoria: 'personalizacao' },
                'classes': { leitura: true, escrita: true, exclusao: true, nome: 'Cadastro de classes', permissao: 'Personalização', categoria: 'personalizacao' },
                'estados_fisicos': { leitura: true, escrita: true, exclusao: true, nome: 'Cadastro de estados físicos', permissao: 'Personalização', categoria: 'personalizacao' },
                'acondicionamentos': { leitura: true, escrita: true, exclusao: true, nome: 'Cadastro de acondicionamentos', permissao: 'Personalização', categoria: 'personalizacao' },
                'destinacao_disposicao': { leitura: true, escrita: true, exclusao: true, nome: 'Cadastro de Destinação/Disposição', permissao: 'Personalização', categoria: 'personalizacao' },
                'armazenamento_temporario': { leitura: true, escrita: true, exclusao: true, nome: 'Cadastro de Armazenamento temporário', permissao: 'Personalização', categoria: 'personalizacao' },
                'tratamento': { leitura: true, escrita: true, exclusao: true, nome: 'Cadastro de Tratamento', permissao: 'Personalização', categoria: 'personalizacao' },
                'periculosidade': { leitura: true, escrita: true, exclusao: true, nome: 'Cadastro de Periculosidade', permissao: 'Personalização', categoria: 'personalizacao' },
                'tipos_veiculos': { leitura: true, escrita: true, exclusao: true, nome: 'Cadastro de Tipo de Veículo', permissao: 'Personalização', categoria: 'personalizacao' },
                'marcas_veiculos': { leitura: true, escrita: true, exclusao: true, nome: 'Cadastro de Marca de Veículo', permissao: 'Personalização', categoria: 'personalizacao' },
                'modelos_veiculos': { leitura: true, escrita: true, exclusao: true, nome: 'Cadastro de Modelo de Veículo', permissao: 'Personalização', categoria: 'personalizacao' },
                'conservacoes_veiculos': { leitura: true, escrita: true, exclusao: true, nome: 'Cadastro de Conservação do Veículo', permissao: 'Personalização', categoria: 'personalizacao' },

                'compliance_destinos': { leitura: false, escrita: false, exclusao: false, nome: 'Destino', permissao: 'Compliance', categoria: 'compliance' },
                'compliance_motoristas': { leitura: false, escrita: false, exclusao: false, nome: 'Motorista', permissao: 'Compliance', categoria: 'compliance' },
                'compliance_pontos_coleta': { leitura: false, escrita: false, exclusao: false, nome: 'Ponto de Coleta', permissao: 'Compliance', categoria: 'compliance' },
                'compliance_transportadoras': { leitura: false, escrita: false, exclusao: false, nome: 'Transportadora', permissao: 'Compliance', categoria: 'compliance' },
                'compliance_veiculos': { leitura: false, escrita: false, exclusao: false, nome: 'Veículo', permissao: 'Compliance', categoria: 'compliance' },

                'residuos_rejeitos': { leitura: true, escrita: true, exclusao: true, nome: 'Cadastro de resíduos e rejeitos', permissao: 'Cadastros', categoria: 'cadastro' },
                'estoques': { leitura: true, escrita: true, exclusao: true, nome: 'Cadastro de estoques', permissao: 'Cadastros', categoria: 'cadastro' },
                'pontos_coleta': { leitura: true, escrita: true, exclusao: true, nome: 'Cadastro de pontos de coleta', permissao: 'Cadastros', categoria: 'cadastro' },
                'transportadoras': { leitura: true, escrita: true, exclusao: true, nome: 'Cadastro de transportadoras', permissao: 'Cadastros', categoria: 'cadastro' },
                'veiculos': { leitura: true, escrita: true, exclusao: true, nome: 'Cadastro de veículos', permissao: 'Cadastros', categoria: 'cadastro' },
                'motoristas': { leitura: true, escrita: true, exclusao: true, nome: 'Cadastro de motoristas', permissao: 'Cadastros', categoria: 'cadastro' },
                'destinos': { leitura: true, escrita: true, exclusao: true, nome: 'Cadastro de destinos', permissao: 'Cadastros', categoria: 'cadastro' },

                'registros_entrada_coleta': { leitura: true, escrita: true, exclusao: true, nome: 'Registro de entrada Coleta e Transporte', permissao: 'Coleta e Transporte', categoria: 'coleta_transporte' },
                'estoques_coleta': { leitura: true, escrita: true, exclusao: true, nome: 'Estoque de ponto de coleta', permissao: 'Coleta e Transporte', categoria: 'coleta_transporte' },
                'solicitacoes_coleta': { leitura: true, escrita: true, exclusao: true, nome: 'Solicitação de coleta', permissao: 'Coleta e Transporte', categoria: 'coleta_transporte' },
                'realizar_despacho': { leitura: true, escrita: true, exclusao: true, nome: 'Realizar despacho', permissao: 'Coleta e Transporte', categoria: 'coleta_transporte' },
                'receber_coleta': { leitura: true, escrita: true, exclusao: true, nome: 'Receber coleta', permissao: 'Coleta e Transporte', categoria: 'coleta_transporte' },
                'destinacao': { leitura: true, escrita: true, exclusao: true, nome: 'Destinação', permissao: 'Coleta e Transporte', categoria: 'coleta_transporte' },
                // 'solicitacao_simplificada': { leitura: true, escrita: true, exclusao: true, nome: 'Solicitação simplificada', categoria: '' },

                'pesagem': { leitura: false, escrita: false, exclusao: false, nome: 'Pesagem', permissao: 'Triagem', categoria: 'triagem' },
                'separacao': { leitura: false, escrita: false, exclusao: false, nome: 'Separação', permissao: 'Triagem', categoria: 'triagem' },
                'relatorios_sub': { leitura: false, escrita: false, exclusao: false, nome: 'Relatório de Sub-Materiais', permissao: 'Triagem', categoria: 'triagem' },

                'registros_entrada_interna': { leitura: true, escrita: true, exclusao: true, nome: 'Registro de entrada Gestão Interna', permissao: 'Gestão Interna', categoria: 'gestao_interna' },
                'estoques_interna': { leitura: true, escrita: true, exclusao: true, nome: 'Estoque Gestão Interna', permissao: 'Gestão Interna', categoria: 'gestao_interna' },
                'destinacao_interna': { leitura: true, escrita: true, exclusao: true, nome: 'Destinação Gestão Interna', permissao: 'Gestão Interna', categoria: 'gestao_interna' },
                'relacionamento_externo': { leitura: true, escrita: true, exclusao: true, nome: 'Relacionamentos Blueen Open', permissao: 'Gestão Interna', categoria: 'gestao_interna' },
                'horario_funcionamento': { leitura: true, escrita: true, exclusao: true, nome: 'Horários de Funcionamento', permissao: 'Gestão Interna', categoria: 'gestao_interna' },
                'agendamento': { leitura: true, escrita: true, exclusao: true, nome: 'Agendamentos', permissao: 'Gestão Interna', categoria: 'gestao_interna' },

                'relacionamento_interno': { leitura: false, escrita: false, exclusao: false, nome: 'Relacionamentos Internos', permissao: 'Relacionamentos', categoria: 'relacionamentos' },

                'comunicacao': { leitura: true, escrita: true, exclusao: true, nome: 'Comunicação', permissao: 'Comunicação', categoria: 'comunicacao' },

                'relatorios_coleta': { leitura: true, escrita: true, exclusao: true, nome: 'Relatórios Coleta e Transporte', permissao: 'Relatórios', categoria: 'relatorios' },
                'relatorios_interna': { leitura: true, escrita: true, exclusao: true, nome: 'Relatórios Gestão Interna', permissao: 'Relatórios', categoria: 'relatorios' },
            },

            permissoes_app: {
                'coleta_transporte.unidade_medida': { has_access: false, modulo: 'coleta_transporte.unidade_medida', nome: 'Unidade de medida' },
                'coleta_transporte.residuo_rejeito': { has_access: false, modulo: 'coleta_transporte.residuo_rejeito', nome: 'Resíduo/Rejeito' },
                'coleta_transporte.registrar_entradas': { has_access: false, modulo: 'coleta_transporte.registrar_entradas', nome: 'Registrar Entrada Coleta e Transporte' },
                'coleta_transporte.solicitar_coletas': { has_access: false, modulo: 'coleta_transporte.solicitar_coletas', nome: 'Solicitar Coletas' },
                'coleta_transporte.estoques': { has_access: false, modulo: 'coleta_transporte.estoques', nome: 'Estoques Coleta e Transporte' },

                'gestao_interna.registrar_entradas': { has_access: false, modulo: 'gestao_interna.registrar_entradas', nome: 'Registrar Entrada Gestão Interna' },
                'gestao_interna.estoques': { has_access: false, modulo: 'gestao_interna.estoques', nome: 'Estoques Gestão Interna' },
                'gestao_interna.destinacao': { has_access: false, modulo: 'gestao_interna.destinacao', nome: 'Destinação Gestão Interna' },
                'motorista.despacho': { has_access: false, modulo: 'motorista.despacho', nome: 'Coleta motorista' },

            },

            permissoes_notificacoes: {
                'destinacaointerna.ativar_notificacoes': { has_access: false, modulo: 'destinacaointerna.ativar_notificacoes', nome: 'Ativar - Destinação Interna / Blueen Open' },
            },

            loading_save: false,
            msgErro: ''
        };
    }



    salvar() {
        this.setState({ loading_save: true, msgErro: '' });
        console.log({
            nome: this.state.nome,
            permissoes: this.state.permissoes,
            permissoes_app: this.state.permissoes_app,

            tipo_id: this.props.match.params.id,
        });

        fetch(`${URL}api/update_tipo`, {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                nome: this.state.nome,
                permissoes: this.state.permissoes,
                permissoes_app: this.state.permissoes_app,
                permissoes_notificacoes: this.state.permissoes_notificacoes,

                tipo_id: this.props.match.params.id,
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();

                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    window.scrollTo(500, 0);

                    this.setState({ loading_save: false, msgErro: erro });
                }
                else {
                    // this.props.mudarDados({ user: resp.user });
                    let permissoes = {};
                    let permissoes_app = {};
                    let permissoes_notificacoes = {};


                    for (let i = 0; i < resp.user.tipo_usuario.permissoes.length; i++) {
                        permissoes[resp.user.tipo_usuario.permissoes[i].modulo] = resp.user.tipo_usuario.permissoes[i];
                    }
                    for (let i = 0; i < resp.user.tipo_usuario.permissoes_app.length; i++) {
                        permissoes_app[resp.user.tipo_usuario.permissoes_app[i].modulo] = resp.user.tipo_usuario.permissoes_app[i];
                    }

                    for (let i = 0; i < resp.user.tipo_usuario?.permissoes_notificacoes?.length; i++) {
                        permissoes_notificacoes[resp.user.tipo_usuario.permissoes_notificacoes[i].modulo] = resp.user.tipo_usuario.permissoes_notificacoes[i];
                    }

                    resp.user.tipo_usuario.permissoes = permissoes;
                    resp.user.tipo_usuario.permissoes_app = permissoes_app;
                    resp.user.tipo_usuario.permissoes_notificacoes = permissoes_notificacoes;

                    localStorage.setItem('user', JSON.stringify(resp.user));
                    this.props.login({ token: this.props.token, user: resp.user });

                    this.setState({ loading_save: false, redirect: true });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }


    componentDidMount() {

        this.get_tipo(this.props.match.params.id);
    }

    renderCategoria(categoria) {
        let { permissoes } = this.state;
        let objetoCategoria = Object.values(permissoes).find(item => item.categoria === categoria);
        return objetoCategoria.permissao;
    }

    renderCheckbox(categoria) {
        let { permissoes, categorias_selected, select_all } = this.state;

        let permissoesWeb = [];
        Object.keys(permissoes).forEach((chave) => {
            let permissao_categoria = permissoes[chave].categoria;

            if (permissao_categoria === categoria) {
                permissoesWeb.push(chave);
            }
        });

        categorias_selected[categoria] = permissoesWeb.map((item) => {
            let { leitura, escrita, exclusao } = permissoes[item];
            return leitura && escrita && exclusao;
        }).every((valor) => valor);

        //
        let all_selected = Object.values(permissoes).every((item) => {
            return item.escrita && item.leitura && item.exclusao;
        });
        select_all = all_selected;
        //

        this.setState({ categorias_selected, select_all, permissoes })
    }

    get_tipo(id) {

        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/get_tipo/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    if (Array.isArray(resp.permissoes)) {
                        resp.permissoes = {};
                    }
                    if (Array.isArray(resp.permissoes_app)) {
                        resp.permissoes_app = {};
                    }
                    if (Array.isArray(resp.permissoes_notificacoes)) {
                        resp.permissoes_notificacoes = {};
                    }

                    //* *Esse laço de repetição foram comentados pois as novas permissões não vinham corretamente com o novo padrão de 'categoria' e 'nome' para a separação das permissões por módulos */
                    // for (let i = 0; i < Object.values(this.state.permissoes).length; i++) {
                    //     if (resp.permissoes[Object.keys(this.state.permissoes)[i]] == undefined) {
                    //         resp.permissoes[Object.keys(this.state.permissoes)[i]] = Object.values(this.state.permissoes)[i];
                    //     }
                    // }
                    //* Nova forma de salvar os dados da requisição
                    let permissoes_web = { ...this.state.permissoes };
                    Object.keys(permissoes_web).forEach((chave) => {
                        if (resp.permissoes[chave] !== undefined) {
                            permissoes_web[chave].leitura = resp.permissoes[chave].leitura;
                            permissoes_web[chave].escrita = resp.permissoes[chave].escrita;
                            permissoes_web[chave].exclusao = resp.permissoes[chave].exclusao;
                        }
                    });

                    for (let i = 0; i < Object.values(this.state.permissoes_app).length; i++) {
                        if (resp.permissoes_app[Object.keys(this.state.permissoes_app)[i]] == undefined) {
                            resp.permissoes_app[Object.keys(this.state.permissoes_app)[i]] = Object.values(this.state.permissoes_app)[i];
                        }
                        else {
                            resp.permissoes_app[Object.keys(this.state.permissoes_app)[i]].nome = Object.values(this.state.permissoes_app)[i].nome;
                        }
                    }
                    for (let i = 0; i < Object.values(this.state.permissoes_notificacoes).length; i++) {
                        if (resp.permissoes_notificacoes[Object.keys(this.state.permissoes_notificacoes)[i]] == undefined) {
                            resp.permissoes_notificacoes[Object.keys(this.state.permissoes_notificacoes)[i]] = Object.values(this.state.permissoes_notificacoes)[i];
                        }
                        else {
                            resp.permissoes_notificacoes[Object.keys(this.state.permissoes_notificacoes)[i]].nome = Object.values(this.state.permissoes_notificacoes)[i].nome;
                        }
                    }


                    // delete resp.permissoes['pesagem'];
                    // delete resp.permissoes['separacao'];

                    this.setState({
                        loading_screen: false,
                        nome: resp.nome,
                        permissoes: permissoes_web,
                        permissoes_app: resp.permissoes_app,
                        permissoes_notificacoes: resp.permissoes_notificacoes,

                    });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }




    change_tipo(event) {
        this.setState({ tipo: event.target.value });
    }


    render() {
        const permissoesWeb = {};
        Object.keys(this.state.permissoes).forEach((chave) => {
            const categoria = this.state.permissoes[chave].categoria;
            if (!permissoesWeb[categoria]) {
                permissoesWeb[categoria] = [];
            }
            permissoesWeb[categoria].push(chave);
        });

        console.log('permissoes edit:', this.state.permissoes)

        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">


                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Editar tipo de usuário</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Edite um tipo de usuário do seu sistema</p>
                            <br />

                            {/* <hr /> */}
                            {this.state.loading_screen == false && <div>
                                {this.state.msgErro != '' && <div>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>


                                        <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                                    </div>
                                    <br />

                                </div>}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Nome <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <input className="form-control" placeholder="Nome do tipo de usuário" type="text" onChange={(e) => this.setState({ nome: e.target.value })} value={this.state.nome} />

                                            </div>
                                        </div>

                                        <section>
                                            <div className="form-group row">
                                                <div className='col-12'>
                                                    <hr />
                                                </div>

                                                <Box>
                                                    <div className='d-flex align-items-center bg-success'>
                                                        <h2 style={{ fontSize: 24, fontWeight: 'bold' }} className="mx-2 col-form-label text-white">Permissões Painel Web</h2>
                                                        <Tooltip title={this.state.select_all ? 'Desmarcar tudo' : 'Marcar tudo'}>
                                                            <input className="form-check-input mt-2 mx-2" type="checkbox" id={'select-all'} checked={this.state.select_all} onChange={(e) => {
                                                                let object_permissoes = Object.keys(this.state.permissoes);
                                                                let object_categorias = Object.keys(permissoesWeb);

                                                                let permissoes = this.state.permissoes;
                                                                object_permissoes.map((item) => {
                                                                    permissoes[item].escrita = e.target.checked;
                                                                    permissoes[item].leitura = e.target.checked;
                                                                    permissoes[item].exclusao = e.target.checked;
                                                                });

                                                                let categorias_selected = { ...this.state.categorias_selected };
                                                                object_categorias.map((categoria) => {
                                                                    categorias_selected[categoria] = e.target.checked
                                                                })

                                                                this.setState({
                                                                    permissoes,
                                                                    categorias_selected,
                                                                    select_all: e.target.checked,
                                                                })
                                                            }} />
                                                        </Tooltip>
                                                    </div>
                                                </Box>
                                            </div>
                                            {Object.keys(permissoesWeb).map((categoria, index) => (
                                                this.props.permissoes[categoria] != undefined && this.props.permissoes[categoria].acesso == true &&
                                                <Accordion defaultExpanded={true} key={index}>
                                                    <div className='d-flex align-items-center'>
                                                        <input className="form-check-input mx-3 mt-2" type="checkbox" checked={this.state.categorias_selected[categoria]} onChange={(e) => {
                                                            let { permissoes, select_all } = this.state;
                                                            let updatedPermissoes = { ...permissoes };

                                                            let { categorias_selected } = this.state;
                                                            let categorias = { ...categorias_selected };
                                                            categorias[categoria] = e.target.checked;

                                                            Object.keys(updatedPermissoes).forEach((chave) => {
                                                                if (updatedPermissoes[chave].categoria === categoria) {
                                                                    updatedPermissoes[chave].leitura = e.target.checked;
                                                                    updatedPermissoes[chave].escrita = e.target.checked;
                                                                    updatedPermissoes[chave].exclusao = e.target.checked;
                                                                }
                                                            });

                                                            let all_selected = Object.values(permissoes).every((item) => {
                                                                return item.escrita && item.leitura && item.exclusao;
                                                            });
                                                            select_all = all_selected;

                                                            this.setState({ permissoes: updatedPermissoes, categorias_selected: categorias, select_all });
                                                        }} />
                                                        <AccordionSummary
                                                            expandIcon={<MdArrowDropDown />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                            className='w-100'>
                                                            <h3 style={{ fontSize: 20, fontWeight: 'bold' }} className="col-form-label">{this.renderCategoria(categoria)}</h3>
                                                        </AccordionSummary>
                                                    </div>
                                                    <AccordionDetails>
                                                        {permissoesWeb[categoria].map((item, id) => (
                                                            <div key={id} className="form-group row">
                                                                <label className="col-sm-12 col-form-label">{this.state.permissoes[item].nome} <b style={{ color: 'red' }}>*</b></label>
                                                                <div className="col-sm-12">
                                                                    <div className="row " style={{ marginLeft: 0, marginRight: 0 }}>
                                                                        <div className="form-check col-12 col-md-4 col-lg-4">
                                                                            <input className="form-check-input" type="checkbox" id={'leitura-' + this.state.permissoes[item].nome} checked={this.state.permissoes[item].leitura} onChange={(e) => {
                                                                                //alert enable "triagem" mode
                                                                                if (this.state.permissoes[item].leitura == false && this.state.permissoes[item].escrita == false
                                                                                    && this.state.permissoes[item].exclusao == false && this.state.permissoes[item].nome == 'Pesagem'
                                                                                    || this.state.permissoes[item].leitura == false && this.state.permissoes[item].escrita == false
                                                                                    && this.state.permissoes[item].exclusao == false && this.state.permissoes[item].nome == 'Separação') {
                                                                                    this.setState({
                                                                                        trigem_alert: true,
                                                                                        item_permissoes: this.state.permissoes[item].nome == 'Pesagem' ? 'pesagem' : 'separacao',
                                                                                        tipo_permissao: 'leitura'
                                                                                    });
                                                                                }
                                                                                // disable  "solicitação de coleta" when disable reading  "registro de entrada"
                                                                                else if (this.state.permissoes[item].leitura == true && this.state.permissoes[item].escrita == true && this.state.permissoes[item].nome == 'Registro de entrada Coleta e Transporte') {
                                                                                    let permissoes = { ...this.state.permissoes };
                                                                                    permissoes[item].leitura = e.target.checked;
                                                                                    permissoes[item].escrita = e.target.checked;
                                                                                    permissoes[item].exclusao = e.target.checked;
                                                                                    permissoes['solicitacoes_coleta'].escrita = e.target.checked;
                                                                                    permissoes['solicitacoes_coleta'].exclusao = e.target.checked;
                                                                                    this.setState({ permissoes });
                                                                                }
                                                                                // // enable "registro de entrada" when enable reading "solicitação de coleta"
                                                                                // else if (this.state.permissoes[item].leitura == false && this.state.permissoes[item].nome === 'Solicitação de coleta') {
                                                                                //     let permissoes = { ...this.state.permissoes };
                                                                                //     permissoes[item].leitura = e.target.checked;
                                                                                //     permissoes['registros_entrada_coleta'].leitura = e.target.checked;
                                                                                //     this.setState({ permissoes });
                                                                                // } 
                                                                                else {
                                                                                    let permissoes = { ...this.state.permissoes };
                                                                                    if (this.state.permissoes[item].leitura == false) {
                                                                                        permissoes[item].leitura = e.target.checked;
                                                                                    } else {
                                                                                        permissoes[item].leitura = e.target.checked;
                                                                                        permissoes[item].escrita = e.target.checked;
                                                                                        permissoes[item].exclusao = e.target.checked;
                                                                                    }
                                                                                    this.setState({ permissoes });
                                                                                }
                                                                                this.renderCheckbox(categoria)
                                                                            }} />
                                                                            <label className="form-check-label" htmlFor={'leitura-' + this.state.permissoes[item].nome} checked={this.state.permissoes[item].leitura}>Leitura</label>
                                                                        </div>
                                                                        <div className="form-check col-12 col-md-4 col-lg-4">
                                                                            <input className="form-check-input" type="checkbox" id={'escrita-' + this.state.permissoes[item].nome} checked={this.state.permissoes[item].leitura} checked={this.state.permissoes[item].escrita} onChange={(e) => {
                                                                                //alert enable "triagem" mode
                                                                                if (this.state.permissoes[item].leitura == false && this.state.permissoes[item].escrita == false
                                                                                    && this.state.permissoes[item].exclusao == false && this.state.permissoes[item].nome == 'Pesagem'
                                                                                    || this.state.permissoes[item].leitura == false && this.state.permissoes[item].escrita == false
                                                                                    && this.state.permissoes[item].exclusao == false && this.state.permissoes[item].nome == 'Separação') {
                                                                                    this.setState({
                                                                                        trigem_alert: true,
                                                                                        item_permissoes: this.state.permissoes[item].nome == 'Pesagem' ? 'pesagem' : 'separacao',
                                                                                        tipo_permissao: 'escrita'
                                                                                    });
                                                                                }
                                                                                //disable "solicitacao de coleta" when disable write "registro de entrada"
                                                                                else if (this.state.permissoes[item].escrita == true && this.state.permissoes[item].nome == 'Registro de entrada Coleta e Transporte') {
                                                                                    let permissoes = { ...this.state.permissoes };
                                                                                    permissoes[item].escrita = e.target.checked;
                                                                                    permissoes[item].exclusao = e.target.checked;
                                                                                    permissoes['solicitacoes_coleta'].escrita = e.target.checked;
                                                                                    permissoes['solicitacoes_coleta'].exclusao = e.target.checked;
                                                                                    this.setState({ permissoes });
                                                                                }
                                                                                //enable "registro de entrada" when enable write to "solicitação de coleta"
                                                                                else if (this.state.permissoes[item].escrita == false && this.state.permissoes[item].nome == 'Solicitação de coleta') {
                                                                                    let permissoes = { ...this.state.permissoes };
                                                                                    permissoes[item].escrita = e.target.checked;
                                                                                    permissoes[item].leitura = e.target.checked;
                                                                                    permissoes['registros_entrada_coleta'].escrita = e.target.checked;
                                                                                    permissoes['registros_entrada_coleta'].leitura = e.target.checked;
                                                                                    permissoes['registros_entrada_coleta'].exclusao = e.target.checked;
                                                                                    this.setState({ permissoes });
                                                                                } else {
                                                                                    let permissoes = { ...this.state.permissoes };
                                                                                    if (this.state.permissoes[item].escrita == false) {
                                                                                        permissoes[item].leitura = e.target.checked;
                                                                                        permissoes[item].escrita = e.target.checked;
                                                                                    } else {
                                                                                        permissoes[item].escrita = e.target.checked;
                                                                                        permissoes[item].exclusao = e.target.checked;
                                                                                    }
                                                                                    this.setState({ permissoes });
                                                                                }
                                                                                this.renderCheckbox(categoria)
                                                                            }} />
                                                                            <label className="form-check-label" htmlFor={'escrita-' + this.state.permissoes[item].nome} checked={this.state.permissoes[item].leitura}>Escrita</label>
                                                                        </div>
                                                                        <div className="form-check col-12 col-md-4 col-lg-4">
                                                                            <input className="form-check-input" type="checkbox" id={'exclusao-' + this.state.permissoes[item].nome} checked={this.state.permissoes[item].leitura} checked={this.state.permissoes[item].exclusao} onChange={(e) => {
                                                                                //alert enable "triagem" mode
                                                                                if (this.state.permissoes[item].leitura == false && this.state.permissoes[item].escrita == false
                                                                                    && this.state.permissoes[item].exclusao == false && this.state.permissoes[item].nome == 'Pesagem'
                                                                                    || this.state.permissoes[item].leitura == false && this.state.permissoes[item].escrita == false
                                                                                    && this.state.permissoes[item].exclusao == false && this.state.permissoes[item].nome == 'Separação') {
                                                                                    this.setState({
                                                                                        trigem_alert: true,
                                                                                        item_permissoes: this.state.permissoes[item].nome == 'Pesagem' ? 'pesagem' : 'separacao',
                                                                                        tipo_permissao: 'exclusao'
                                                                                    });
                                                                                }
                                                                                //disable "solicitação de coleta" when disable exclusion to "registro de entrada"
                                                                                else if (this.state.permissoes[item].exclusao == true && this.state.permissoes[item].nome == 'Registro de entrada Coleta e Transporte') {
                                                                                    let permissoes = { ...this.state.permissoes };
                                                                                    permissoes[item].exclusao = e.target.checked;
                                                                                    permissoes['solicitacoes_coleta'].exclusao = e.target.checked;
                                                                                    permissoes['solicitacoes_coleta'].escrita = e.target.checked;
                                                                                    this.setState({ permissoes });
                                                                                }
                                                                                else if (this.state.permissoes[item].exclusao == false && this.state.permissoes[item].nome == 'Solicitação de coleta') {
                                                                                    let permissoes = { ...this.state.permissoes };
                                                                                    permissoes[item].exclusao = e.target.checked;
                                                                                    permissoes[item].escrita = e.target.checked;
                                                                                    permissoes[item].leitura = e.target.checked;
                                                                                    permissoes['registros_entrada_coleta'].exclusao = e.target.checked;
                                                                                    permissoes['registros_entrada_coleta'].leitura = e.target.checked;
                                                                                    permissoes['registros_entrada_coleta'].escrita = e.target.checked;
                                                                                    this.setState({ permissoes });
                                                                                }
                                                                                else {
                                                                                    let permissoes = { ...this.state.permissoes };
                                                                                    if (this.state.permissoes[item].exclusao == false) {
                                                                                        permissoes[item].leitura = e.target.checked;
                                                                                        permissoes[item].escrita = e.target.checked;
                                                                                        permissoes[item].exclusao = e.target.checked;
                                                                                    } else {
                                                                                        permissoes[item].exclusao = e.target.checked;
                                                                                    }
                                                                                    this.setState({ permissoes });
                                                                                }
                                                                                this.renderCheckbox(categoria)
                                                                            }} />
                                                                            <label className="form-check-label" htmlFor={'exclusao-' + this.state.permissoes[item].nome} checked={this.state.permissoes[item].leitura}>Exclusão</label>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        ))}
                                                    </AccordionDetails>
                                                </Accordion>))}
                                        </section>

                                        {this.props.permissoes['app'] != undefined && this.props.permissoes['app']['acesso'] == true && <section>
                                            <div className='form-group row'>
                                                <div className='col-12'>
                                                    <hr />
                                                </div>
                                                <Box>
                                                    <div className='d-flex align-items-center bg-success'>
                                                        <h2 style={{ fontSize: 24, fontWeight: 'bold' }} className="mx-2 col-form-label text-white">Permissões Aplicativo</h2>
                                                        <Tooltip title={this.state.select_all_app ? 'Desmarcar tudo' : 'Marcar tudo'}>
                                                            <input className="form-check-input mt-2 mx-2" type="checkbox" id={'select-all'} checked={this.state.select_all_app} onChange={(e) => {
                                                                let object_permissoes = Object.keys(this.state.permissoes_app);
                                                                let permissoes_app = this.state.permissoes_app;
                                                                object_permissoes.map((item) => {
                                                                    permissoes_app[item].has_access = e.target.checked;
                                                                });
                                                                this.setState({
                                                                    permissoes_app,
                                                                    select_all_app: e.target.checked,
                                                                })
                                                            }} />
                                                        </Tooltip>
                                                    </div>
                                                </Box>
                                            </div>
                                            <Accordion defaultExpanded={true}>
                                                <AccordionSummary
                                                    expandIcon={<MdArrowDropDown />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                    className='d-flex align-items-center'>
                                                    <h3 style={{ fontSize: 20, fontWeight: 'bold' }} className="col-form-label">Geral</h3>
                                                </AccordionSummary>
                                                <AccordionDetails className='form-group row'>
                                                    {Object.keys(this.state.permissoes_app).map((item, id) => (
                                                        <div key={id} className="col-sm-12 col-12 col-lg-6">
                                                            <label className="col-sm-12 col-form-label">{this.state.permissoes_app[item].nome} <b style={{ color: 'red' }}>*</b></label>

                                                            <div className="row " style={{ marginLeft: 0, marginRight: 0 }}>
                                                                <div className="form-check col-12 col-md-4 col-lg-4">
                                                                    <input className="form-check-input" type="checkbox" id={'leitura-' + this.state.permissoes_app[item].nome} checked={this.state.permissoes_app[item].has_access} onChange={(e) => {
                                                                        //alert enable "triagem" mode
                                                                        let permissoes_app = { ...this.state.permissoes_app };
                                                                        permissoes_app[item].has_access = e.target.checked;
                                                                        this.setState({ permissoes_app });
                                                                    }} />
                                                                    <label className="form-check-label" htmlFor={'leitura-' + this.state.permissoes_app[item].nome} checked={this.state.permissoes_app[item].has_access}>Leitura</label>
                                                                </div>

                                                            </div>

                                                        </div>))}
                                                </AccordionDetails>
                                            </Accordion>
                                        </section>}

                                        {this.props.permissoes['blueen_open'] != undefined &&
                                            this.props.permissoes['gestao_interna'] != undefined &&
                                            this.props.permissoes['blueen_open']['acesso'] == true &&
                                            this.props.permissoes['gestao_interna']['acesso'] == true &&
                                            <section>
                                                <div className="form-group row">
                                                    <div className='col-12'>
                                                        <hr />
                                                    </div>
                                                    <Box>
                                                        <div className='d-flex align-items-center bg-success'>
                                                            <h2 style={{ fontSize: 24, fontWeight: 'bold' }} className="mx-2 col-form-label text-white">Permissões das Notificações</h2>
                                                            <Tooltip title={this.state.select_all_notifications ? 'Desmarcar tudo' : 'Marcar tudo'}>
                                                                <input className="form-check-input mt-2 mx-2" type="checkbox" id={'select-all'} checked={this.state.select_all_notifications} onChange={(e) => {
                                                                    let object_permissoes = Object.keys(this.state.permissoes_notificacoes);
                                                                    let permissoes_notificacoes = this.state.permissoes_notificacoes;
                                                                    object_permissoes.map((item) => {
                                                                        permissoes_notificacoes[item].has_access = e.target.checked;
                                                                    });
                                                                    this.setState({
                                                                        permissoes_notificacoes,
                                                                        select_all_notifications: e.target.checked,
                                                                    })
                                                                }} />
                                                            </Tooltip>
                                                        </div>
                                                    </Box>
                                                </div>
                                                <Accordion defaultExpanded={true}>
                                                    <AccordionSummary
                                                        expandIcon={<MdArrowDropDown />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                        className='d-flex align-items-center'>
                                                        <h3 style={{ fontSize: 20, fontWeight: 'bold' }} className="col-form-label">Geral</h3>
                                                    </AccordionSummary>
                                                    <AccordionDetails className='form-group row'>
                                                        {Object.keys(this.state.permissoes_notificacoes).map((item, id) => (
                                                            <div key={id} className="col-sm-12 col-12 col-lg-6">
                                                                <label className="col-sm-12 col-form-label">{this.state.permissoes_notificacoes[item].nome} <b style={{ color: 'red' }}>*</b></label>

                                                                <div className="row " style={{ marginLeft: 0, marginRight: 0 }}>
                                                                    <div className="form-check col-12 col-md-4 col-lg-4">
                                                                        <input className="form-check-input" type="checkbox" id={this.state.permissoes_notificacoes[item].nome} checked={this.state.permissoes_notificacoes[item].has_access} onChange={(e) => {
                                                                            let permissoes_notificacoes = { ...this.state.permissoes_notificacoes };
                                                                            permissoes_notificacoes[item].has_access = e.target.checked;
                                                                            this.setState({ permissoes_notificacoes });
                                                                        }} />
                                                                        <label className="form-check-label" htmlFor={this.state.permissoes_notificacoes[item].nome} checked={this.state.permissoes_notificacoes[item].has_access}>{this.state.permissoes_notificacoes[item].has_access == true ? 'Ativo' : 'Inativo'}</label>
                                                                    </div>
                                                                </div>
                                                            </div>))}
                                                    </AccordionDetails>
                                                </Accordion>
                                            </section>
                                        }

                                    </div>

                                </div>
                                <hr />


                                {this.state.loading_save == false && <div className="row mt-3">
                                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <Link to="/tipos_usuario"> <span
                                            className="btn btn-secondary btn-lg waves-effect waves-light"><MdReply /> Voltar</span></Link>
                                    </div>
                                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <button type="button" onClick={() => this.salvar()} className="btn btn-success btn-lg waves-effect waves-light"
                                            style={{ backgorundColor: '#f2f2f2' }}>Salvar tipo de usuário</button>
                                    </div>
                                </div>}
                                {this.state.loading_save == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                    <div className="spinner-border text-primary" role="status"  >
                                    </div>
                                </div></div>}

                                {/* {this.state.andamento == true && <AvaliacaoAndamento get_processo={this.get_processo.bind(this)} {...this.props} />} */}

                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                </div>
                            </div></div>}

                            <SweetAlert
                                warning
                                title={"Atenção"}
                                onConfirm={() => {
                                    let tipo = this.state.tipo_permissao;
                                    let permissoes = { ...this.state.permissoes };
                                    if (tipo == 'leitura') {
                                        permissoes[this.state.item_permissoes].leitura = true;
                                    }
                                    if (tipo == 'escrita') {
                                        permissoes[this.state.item_permissoes].escrita = true;
                                    }
                                    if (tipo == 'exclusao') {
                                        permissoes[this.state.item_permissoes].exclusao = true;
                                    }

                                    this.setState({ permissoes: permissoes, trigem_alert: false });
                                }}
                                onCancel={() => {
                                    this.setState({ trigem_alert: false });
                                }}
                                show={this.state.trigem_alert}
                                confirmBtnText='Sim, desejo ativar'
                                cancelBtnText='Cancelar'
                                confirmBtnBsStyle="success"
                                cancelBtnBsStyle="danger"
                                showCancel={true}
                            >
                                Ao ativar essa permissão os despachos não serão finalizados até concluir todos os passos da triagem

                            </SweetAlert>
                        </div>

                    </div>
                </div>
            </div>
        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
        permissoes: state.AppReducer.permissoes,

    }
);

export default connect(mapsStateToProps, { logout, mudarDados, login })(EditarTipoUsuario);


